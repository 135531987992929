import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {ClrModal} from "@clr/angular";
import {DialogComponent} from "../../../components/dialogs/dialog/dialog.component";
import {PostService} from "../../../services/post.service";

@Component({
  selector: 'app-create-feed-dialog',
  templateUrl: './create-feed-dialog.component.html',
  styleUrls: ['./create-feed-dialog.component.scss']
})
export class CreateFeedDialogComponent implements OnInit {

    @ViewChild("modal")
    modal: DialogComponent;

    name: string = "";

    displayName: string = "";

    @Output("onFinish")
    onFinish: EventEmitter<string> = new EventEmitter<string>();

    @Output("onError")
    onError: EventEmitter<any> = new EventEmitter<any>();

    constructor(private postService: PostService) { }

    ngOnInit() {
    }

    open() {
        this.name = "";
        this.displayName = "";
        this.modal.open();
    }

    validate(): boolean {
        if(!this.name || !this.displayName)
            return false;
        else
            return true;
    }

    submit() {
        return this.postService.createFeed(this.name, this.displayName);
    }

    failed(error: any) {
        this.onError.emit(error);
    }
}
