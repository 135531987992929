import {Component, OnInit, ViewChild} from '@angular/core';
import {ContentComponent, ContentHost} from "../../components/content/content.component";
import {Channel} from "../../model/channel";
import {SelectChannelComponent} from "../../ui/radio/select-channel/select-channel.component";
import {ChannelService} from "../../services/channel.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
    selector: 'app-edit-channel-group-list',
    templateUrl: './edit-channel-group-list.component.html',
    styleUrls: ['./edit-channel-group-list.component.scss'],
    host: {'class': 'item-content'}
})
export class EditChannelGroupListComponent extends ContentHost implements OnInit {

    @ViewChild(SelectChannelComponent)
    selectChannelDialog: SelectChannelComponent;

    @ViewChild(ContentComponent)
    content: ContentComponent<Channel[]>;

    groupId: string;

    constructor(private route: ActivatedRoute, private router: Router,
                private channelService: ChannelService) {
        super();
    }

    ngOnInit() {
        this.route.parent.params.subscribe(params => {
            this.groupId = params['id'];
        });
    }

    get() {
        return this.channelService.getGroupById(this.groupId)
            .map(group => {
                if(group.channels == null) {
                    return []
                } else {
                    return group.channels;
                }
            });
    }

    update () {
        return this.channelService.updateChannelGroupChannels(this.groupId, this.content.item.map(item => item.channelId));
    }

    addChannel(channel) {
        this.content.item.push(channel);
        this.content.setDirty();
    }

    removeChannel(channel: Channel) {
        this.content.item = this.content.item.filter(value => value.channelId != channel.channelId);
        this.content.setDirty();
    }

    isDirty(): boolean {
        return this.content.isDirty();
    }
}
