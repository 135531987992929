import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {Station, StationApi} from "../../model/station";
import {ContentComponent} from "../../components/content/content.component";
import {AdminService, PagedResult} from "../../services/admin.service";
import {AuthService} from "../../services/auth/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Observable} from "rxjs/Observable";
import {ClrModal} from "@clr/angular";
import {AddApiComponent} from "../add-api/add-api.component";
import {ContentListComponent} from "../../components/content-list/content-list.component";
import {empty} from "rxjs/observable/empty";

@Component({
  selector: 'app-station-domain',
  templateUrl: './station-api.component.html',
  styleUrls: ['./station-api.component.scss']
})
export class StationApiComponent extends ContentListComponent<StationApi> implements OnInit {

    @ViewChild("addApiWizard") addApiWizard: AddApiComponent;

    name: string;

    stationId: string;

    constructor(private route: ActivatedRoute, private router: Router, private authService: AuthService,
                private adminService: AdminService) {
        super();
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.name = params['name'];
        });
    }


    addApi() {
        this.addApiWizard.open(this.stationId)
    }

    refreshCertificate(id: string) {
        this.adminService.refreshAPI(id)
            .subscribe(value => {
                this.refresh();
            }, error => {
            })
    }

    deleteCertificate(id: string) {
        this.adminService.deleteAPI(id)
            .subscribe(value => {
                this.refresh();
            }, error => {
            })
    }
}
