import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-orga-info',
  templateUrl: './orga-info.component.html',
  styleUrls: ['./orga-info.component.scss']
})
export class OrgaInfoComponent implements OnInit {

    page: string = 'details';

    constructor() { }

    ngOnInit() {
    }

}
