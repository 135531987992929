import {CreateImage, ImageDto} from "./image";
import {Content} from "./content";

export class CreateChannel {
    constructor() {
        this.content = new Content();
    }

    stationId: string;
    name: string;
    title:  {};
    subTitle: {};
    summary:  {};
    requiredAccessLevel: string;
    coverImage: CreateImage;
    content: Content;
    enabled: boolean;
}

export class ChannelGroup {
    groupId: string;
    stationId: string;
    name: string;
    title: string;
    imageId: string;
    content: Content;
    channels: [];
}

export class Channel {
    channelId: string;
    stationId: string;
    name: string;
    title:  {};
    subTitle:  {};
    summary:  {};
    channelGroups: [];
    requiredAccessLevel: string;
    coverImageId: string;
    content: Content;
    enabled: boolean;
}

export interface Outlet {
    id: string;
    enabled: boolean;
    output: boolean;
    online: boolean;
    type: string;
    streamType: string;
}

export interface Source extends Outlet {
    source: string;
    direct: boolean;
    metadataOutput: boolean;
    metadataFormat: string;
}

export interface Sink extends Outlet {
    metadataOutput: boolean;
    metadataFormat: string;
}

export interface Processor extends Outlet {
}

export interface Mux extends Outlet {
}

export interface Transcoder extends Outlet {
}

export interface StreamConfig {
    enabled: boolean;
    outlets: Map<String, Outlet>;
    channelId: string;
}

export interface StreamStatus {
    streamId: string,
    name: string,
    online: boolean,
    bitrate: number,
    encoding: string
}

