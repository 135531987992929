import {Component, OnInit, ViewChild} from '@angular/core';
import {TrackService} from "../../../services/disco/track.service";
import {Observable} from "rxjs";
import {PagedResult} from "../../../services/admin.service";
import {TrackReport, TrackWarning} from "../../../model/track";
import {ContentListComponent} from "../../../components/content-list/content-list.component";
import {Channel} from "../../../model/channel";

@Component({
  selector: 'app-track-report-list',
  templateUrl: './track-report-list.component.html',
  styleUrls: ['./track-report-list.component.scss']
})
export class TrackReportListComponent implements OnInit {

    includeAcked: boolean = false;

    constructor(private trackService: TrackService) { }

    @ViewChild(ContentListComponent)
    content: ContentListComponent<TrackReport>;

    ngOnInit() {
    }

    getReports(pageSize: number, offset: string): Observable<PagedResult<TrackReport>> {
        return this.trackService.getTrackReports(pageSize, offset, this.includeAcked);
    }

    openReport(report: TrackReport) {
        // TODO: implement
    }

    getTypeString(item: TrackReport): string {
        if(item.type == "BAD_COVER") {
            return "Bad artwork";
        } else if(item.type == "OFFENSIVE_CONTENT") {
            return "Offensive content";
        }
    }

    deleteReport(item: TrackReport) {
        this.trackService.deleteReport(item.reportId)
            .subscribe(value => {
                this.content.refresh();
            }, error => {
            });
    }

    ackReport(item: TrackReport) {
        if(!item.acked) {
            this.trackService.acknowledgeReport(item.reportId)
                .subscribe(value => {
                    item.acked = true;
                }, err => {
                });
        } else {
            this.trackService.unacknowledgeReport(item.reportId)
                .subscribe(value => {
                    item.acked = false;
                }, err => {
                });
        }
     }
}
