import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ChannelService} from "../../services/channel.service";

import {Observable, of} from "rxjs";
import {Outlet, Source, StreamConfig} from "../../model/channel";
import {FormBuilder, FormGroup} from "@angular/forms";
import {AddInputModalComponent, Input} from "../../ui/radio/add-input-modal/add-input-modal.component";
import 'rxjs/add/operator/catch';
import {ContentComponent, ContentHost} from "../../components/content/content.component";


@Component({
    selector: 'app-edit-streams',
    templateUrl: './edit-streams.component.html',
    styleUrls: ['./edit-streams.component.scss'],
    host: {'class': 'item-content'}
})
export class EditStreamsComponent extends ContentHost implements OnInit {

    @ViewChild(ContentComponent)
    content: ContentComponent<StreamConfig>;


    @ViewChild("addInputModal")
    addInputModal: AddInputModalComponent;

    name: string;

    channelId: string;

    options: any;

    form: FormGroup;

    status: Map<string, boolean> = new Map<string, boolean>();

    constructor(private route: ActivatedRoute, private router: Router, private channelService: ChannelService,
                private fb: FormBuilder) {
        super();
        this.form = fb.group({
            metadata: [],
            archive: []
        });
    }

    ngOnInit() {
        this.route.parent.params.subscribe(params => {
            this.name = params['name'];
        });
    }

    getStreamConfig(): Observable<StreamConfig> {
        return this.channelService.getChannel(this.name)
            .switchMap(channel => {
                return this.channelService.getStreamConfig(channel.channelId).do(cfg => {
                    // this.channelService.getStreamStatus(channel.channelId)
                    //     .subscribe(value => {
                    //         this.status.set(value.name, value.online);
                    //     })
                });
            }).catch(values => {
                return of(<StreamConfig>{
                    outlets: {},
                    enabled: true
                });
            });
    }

    updateStreamConfig(item: StreamConfig): Observable<any> {

        return this.channelService.getChannel(this.name)
            .switchMap(channel => {
                this.content.item.enabled = true;
                return this.channelService.saveStreamConfig(channel.channelId, this.content.item)
        });
    }

    getInputs(): Array<Outlet> {
        var inputs  = [];

        if(this.content.item == null || this.content.item.outlets == null)
            return inputs;

        for(let key in this.content.item.outlets) {
            let input = this.content.item.outlets[key];
            input.name = key;

            if(input.type == "SOURCE" || input.type == "SINK") {
                inputs.push(input);
            }
        }

        return inputs;
    }

    addInput(input: Input) {
        this.content.setDirty();

        this.content.item.outlets[input.name] = <Source> {
            id: null,
            direct: true,
            enabled: false,
            output: false,
            online: false,
            metadataOutput: false,
            type: "SOURCE",
            streamType: "AUDIO",
            source: input.config.source,
        };
    }

    removeInput(item: any) {
        this.content.setDirty();

        delete this.content.item.outlets[item.name];
    }

}
