import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-editor',
  templateUrl: './home-editor.component.html',
  styleUrls: ['./home-editor.component.scss']
})
export class HomeEditorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
