import {Component, OnInit, ViewChild} from '@angular/core';
import {ContentComponent, ContentHost} from "../../components/content/content.component";
import {CreateCampaign} from "../../model/campaign";
import {Channel, ChannelGroup} from "../../model/channel";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Observable, of} from "rxjs";
import {Callback} from "../../utility/callback";
import {CreateImage} from "../../model/image";
import {ChannelService} from "../../services/channel.service";
import {ActivatedRoute, Router} from "@angular/router";
import {SelectChannelComponent} from "../../ui/radio/select-channel/select-channel.component";

@Component({
    selector: 'app-edit-channel-group',
    templateUrl: './edit-channel-group.component.html',
    styleUrls: ['./edit-channel-group.component.scss'],
    host: {'class': 'item-content'}
})
export class EditChannelGroupComponent extends ContentHost implements OnInit {

    @ViewChild(ContentComponent)
    content: ContentComponent<ChannelGroup>;

    @ViewChild(SelectChannelComponent)
    selectChannelDialog: SelectChannelComponent;

    readonly uploadImageUrl = "/api/uploads/channel-group-image";

    processImageCallback: Callback<CreateImage, string> = new Callback(this, this.processImage);

    groupId: string;

    processImage(createImage: CreateImage): Observable<string> {
        return this.channelService.processGroupImage(this.content.item.groupId, createImage);
    }

    setCoverImage(value: any) {
        if(value != null) {
            this.content.item.imageId = value.imageId;
        } else {
            this.content.item.imageId = null;
        }
    }

    channels: Channel[] = [];

    form: FormGroup;

    constructor(private fb: FormBuilder, private route: ActivatedRoute, private router: Router,
                private channelService: ChannelService) {
        super();
        this.form = this.fb.group({
            name: ['', Validators.required ],
            title: ['', Validators.required ],
            text: [''],
            enabled: ['off']
        });
    }

    ngOnInit() {
        this.route.parent.params.subscribe(params => {
            this.groupId = params['id'];
        });
    }

    get() {
        return this.channelService.getGroupById(this.groupId)
    }

    update() {
        return this.channelService.updateChannelGroup(this.content.item);
    }

    addChannel(channel) {
        this.channels.push(channel);
    }

    removeChannel(channel: Channel) {
        this.channels = this.channels.filter(value => value.channelId != channel.channelId)
    }

    isDirty(): boolean {
        return this.content.isDirty();
    }
}
