
export interface AddAndroidAppParams {
    packageName: string;
    signature: string;
}

export interface AddiOSAppParams {
    bundleId: string;
    appStoreId: string;
    teamId: string;
}

export class AddApp {
    os: string;
    name: string;
    params: {};
    firebaseAppId: string;
    chromeCastId: string;
}

export class App {
    appId: string;
    os: string;
    name: string;
    appVersion: string;
    rsVersion: string;
}
