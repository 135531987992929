import {Component, Input, OnInit} from '@angular/core';

interface DataEntry {
    name: string;
    value: number;
}

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent implements OnInit {

    @Input()
    data: DataEntry[];

    @Input()
    title: string;

    constructor() { }

    ngOnInit() {
    }
}
