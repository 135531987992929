import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {ClrWizard} from "@clr/angular";
import {AdminService} from "../../services/admin.service";

@Component({
  selector: 'app-add-api',
  templateUrl: './add-api.component.html',
  styleUrls: ['./add-api.component.scss']
})
export class AddApiComponent {
    @ViewChild("wizard") wizardMedium: ClrWizard;

    mdOpen: boolean = false;

    edgeIP: string = "10.4.10.201";

    domain: string;

    loading: boolean = true;

    done: boolean = false;

    error: Error = null;

    certificateType: string = null;

    privateKey: string;

    certificate: string;

    stationId: string = null;

    @Output("onCreated")
    onCreated: EventEmitter<string> = new EventEmitter();

    constructor(private adminService: AdminService) {
    }

    open(stationId: string): void {
        this.mdOpen = true;
        this.stationId = stationId;
    }

    doCreate(): void {

        let auto: boolean;

        if(this.certificateType == "auto") {
            auto = true;
        } else {
            auto = false;
        }

        if(auto) {
           this.certificate = null;
           this.privateKey = null;
        }

        this.adminService.addAPI(this.stationId, this.domain, auto, this.certificate, this.privateKey)
            .subscribe( value => {
                this.done = true;
                this.loading = false;
                this.onCreated.next(value)
            }, err => {
                this.loading = false;
                this.error = err.message != null ? err.message : "Unkown error";
            });
    }

    doCancel(): void {
        this.reset();
    }

    onCommit(): void {
        this.domain = null;
        this.privateKey = null;
        this.certificate = null;
        this.certificateType = null;

        this.wizardMedium.reset();
        this.wizardMedium.close();
        this.reset();
    }

    goBack(): void {
        this.wizardMedium.previous()
        this.reset();
    }

    reset() {
        this.loading = true;
        this.done = false;
        this.error = null;
    }

}

