import {Component, Input, OnInit} from '@angular/core';
import moment = require("moment");

interface DataEntry {
    name: string;
    value: number;
}

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss']
})
export class LineChartComponent implements OnInit {

    @Input()
    data: any;

    @Input()
    title: string;

    constructor() { }

    ngOnInit() {
    }

    dateTickFormatting(val: any) {
        return moment(val).format("MM-DD-YYYY")
    }


}
