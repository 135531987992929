import { Component, OnInit } from '@angular/core';
import {AuthService} from "../services/auth/auth.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  constructor(private router: Router, private authService: AuthService) { }

  ngOnInit() {
      if(this.authService.isLoggedIn()) {
          let name = this.authService.getMe().username;
          this.router.navigateByUrl("/organisation/members/" + name)
      } else {

      }
  }
}
