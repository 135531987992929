import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {ClrModal} from "@clr/angular";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ChannelService} from "../../../services/channel.service";
import {StationService} from "../../../services/station/station.service";
import {Channel} from "../../../model/channel";
import {Schedule, ScheduleService} from "../../../services/schedule.service";
import { Subject, Observable, of, concat } from 'rxjs';
import { distinctUntilChanged, debounceTime, switchMap, tap, catchError, delay } from 'rxjs/operators'
import { DialogComponent } from 'app/components/dialogs/dialog/dialog.component';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-select-schedule',
  templateUrl: './select-schedule.component.html',
  styleUrls: ['./select-schedule.component.scss']
})
export class SelectScheduleComponent implements OnInit {

    @ViewChild("modal") modal: DialogComponent;

    schedules$: Observable<Schedule[]>;

    loading: boolean = false;

    scheduleInput$ = new Subject<string>();

    selectedScheduleId: string;

    query: string;

    constructor(private fb: FormBuilder, private scheduleService: ScheduleService, public stationService: StationService) {
    }

    @Output("onFinish")
    onFinish: EventEmitter<any> = new EventEmitter<any>();

    @Output("onError")
    onError: EventEmitter<any> = new EventEmitter<any>();

    channelId: string;

    week: number;

    year: number;

    ngOnInit() {
        this.loadSchedules();
    }

    private loadSchedules() {
        this.schedules$ = concat(
            of([]),
            this.scheduleInput$.pipe(
                debounceTime(200),
                distinctUntilChanged(),
                tap(() => this.loading = true),
                switchMap(input => this.scheduleService.getSchedules(10, null, input)
                    .map(res => res.items)
                    .pipe(
                    catchError(() => of([])),
                    tap(() => this.loading = false)
                ))
            )
        );
    }
    
    open(channelId: string, week: number, year: number) {
        this.channelId = channelId;
        this.week = week;
        this.year = year;
        this.selectedScheduleId = null;
        this.modal.open();
    }

    validate() {
        return this.selectedScheduleId != null;
    }

    close() {
        this.selectedScheduleId = null;
    }

    submit() {
        return this.scheduleService.importSchedule(this.channelId, this.selectedScheduleId, 
            this.year, this.week);
    }

    failed(error: any) {
        this.onError.emit(error);
    }
}
