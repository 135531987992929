import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../services/auth/auth.service";

@Component({
  selector: 'app-station-item',
  templateUrl: './station-item.component.html',
  styleUrls: ['./station-item.component.scss']
})
export class StationItemComponent implements OnInit {

    page: string = 'details';

    constructor(private route: ActivatedRoute, private router: Router, private authService: AuthService) { }

    ngOnInit() {
        this.route.queryParamMap.subscribe(params => {
            if(params.has('page')) {
                this.page = params.get('page')
            }
        });
    }

}
