import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ContentComponent} from "../../../../components/content/content.component";
import {AudioSlide, Slide, TextSlide} from "../../../../model/campaign";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Observable, of} from "rxjs";
import {Callback} from "../../../../utility/callback";
import {CreateImage} from "../../../../model/image";
import {CampaignService} from "../../../../services/campaign.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-audio-slide-editor',
  templateUrl: './audio-slide-editor.component.html',
  styleUrls: ['./audio-slide-editor.component.scss']
})
export class AudioSlideEditorComponent implements OnInit {

    @ViewChild(ContentComponent)
    content: ContentComponent<Slide>;

    @Input()
    slide:AudioSlide;

    form: FormGroup;

    campaignId: string;

    readonly imageUploadUrl = "/api/uploads/campaign-slide-image";

    processImageCallback: Callback<CreateImage, string> = new Callback(this, this.processImage);

    constructor(private fb: FormBuilder, private route: ActivatedRoute,
                private campaignService: CampaignService) {
        this.form = fb.group({
            title: ['', [Validators.required, Validators.minLength(1)]],
            skippable: [false],
            positiveButtonText: [''],
            negativeButtonText: [''],
            maxDuration: [30, [Validators.required, Validators.min(1)]],
        })
    }

    ngOnInit() {
        this.route.parent.params.subscribe(params => {
            this.campaignId = params['id'];
        });
    }

    ngAfterViewInit() {
        this.content.setItem(this.slide);
    }

    getSlide(): Observable<Slide> {
        return of(this.content.item);
    }

    processImage(createImage: CreateImage): Observable<string> {
        return this.campaignService.processSlideImage(this.campaignId, createImage);
    }

    setCoverImage(value: any) {
        this.content.setDirty();

        if(value != null) {
            this.content.item.coverImageId = value.imageId;
        } else {
            this.content.item.coverImageId = null;
        }
    }
}
