import {AfterViewInit, Component, ContentChildren, Input, OnInit, QueryList, ViewChildren} from '@angular/core';

@Component({
    selector: 'app-content-tab',
    template: '<div *ngIf="isVisible" style="display: flex; flex: 1;"><ng-content></ng-content></div>',
    styles: [':host { display: flex; }']
})
export class ContentTab {
    @Input("title")
    title: string;

    @Input("value")
    value: string;

    isVisible = false;
}

@Component({
  selector: 'app-content-tabs',
  templateUrl: './content-tabs.component.html',
  styleUrls: ['./content-tabs.component.scss']
})
export class ContentTabsComponent implements OnInit, AfterViewInit {

    @ContentChildren(ContentTab)
    tabs: QueryList<ContentTab>;

    active: ContentTab;

    constructor() { }

    ngOnInit() {

    }

    ngAfterViewInit() {
        if(this.tabs.length > 0) {
            this.active = this.tabs.first;
            this.setTab(this.active);
        }
    }

    setTab(tab: ContentTab) {
        if(this.active != null) {
            this.active.isVisible = false;
        }

        this.active = tab;

        this.tabs.forEach(item => {
            if(item.value == this.active.value) {
                item.isVisible = true;
            } else {
                item.isVisible = false;
            }
        });
    }
}
