import {CreateImage} from "./image";
import {Content} from "./content";
import {UserFilter} from "./user";

export class Link {
    type: string;
    destination: string;
}

export class CreateCampaign {

    name: string;
    title: {};
    summary: {};
    coverImage: CreateImage;
    startDate: Date;
    expireDate: Date;
    tos: {};
    requiresPhone: boolean;
    requiresEmail: boolean;
}

export class Campaign {

    constructor(campaignId: string, name: string, title: {}, summary: {} = null, coverImageId: string = null,
                startDate: Date = new Date(), expireDate: Date = new Date(),
                publishDate: Date = null, entry: Link = null) {
        this.campaignId = campaignId;
        this.title = title;
        this.name = name;
        this.summary = summary;
        this.coverImageId = coverImageId;
        this.startDate = new Date();
        this.expireDate = new Date();
        this.enabled = false;
        this.entry = entry;
        this.tos = {};
    }

    campaignId: string;
    name: string;
    title: {};
    summary: {};
    coverImageId: string;
    enabled: boolean;
    startDate: Date;
    expireDate: Date;
    entry: Link;
    requiresPhone: boolean;
    requiresEmail: boolean;
    tos: {};
    usersStarted: number;
    usersFinished: number;
}

export class CampaignSlideConstraint {
    wasSkipped: boolean;
}

export class CampaignResultFilter {
    starred: boolean;
    randomized: boolean;
    randomSeed: number;
    maxSubmissions: number;
    submittedAfter: Date;
    submittedBefore: Date;
    userFilter: UserFilter;
    slideConstraints: CampaignSlideConstraint[];

    constructor() {
        this.userFilter = new UserFilter();
    }
}

export class CampaignStatistics {
    totalViews: number;
    totalFinished: number;
    totalStarted: number;
    genderDistribution: any;
    birthDecades: any;
    osDistribution: any;
    choicesDistribution: any;
    finishedHistogram: any;
}

export class Slide {

    constructor(type: string) {
        this.type = type;
        this.title = {};
        this.positiveButtonText = {};
        this.negativeButtonText = {};
        this.skippable = false;
    }

    slideId: string;
    type: string;
    title: {};
    coverImageId: string;
    skippable: boolean;
    terminal: boolean;
    positiveButtonText: {};
    negativeButtonText: {};
}

export class InfoSlide extends Slide {
    content: Content;

    constructor() {
        super("Info");
        this.content = new Content();
    }
}

export interface Choice {
    text: {};
}

export class MultiChoiceSlide extends Slide {
    constructor() {
        super("MultiChoice");
        this.choices = [];
        this.multi = false;
    }

    choices: Choice[];
    multi: boolean;
}

export class TextSlide extends Slide {

    constructor() {
        super("Text");
        this.maxChars = 120;
    }
    maxChars: number;
}

export class AudioSlide extends Slide {

    constructor() {
        super("Audio");
        this.maxDuration = 30;
        this.maxSize = 10_000_000;
    }

    maxDuration: number;
    maxSize: number;
}

export class VideoSlide extends Slide {

    constructor() {
        super("Video");
        this.maxDuration = 30;
        this.maxSize = 100_000_000;
    }
    maxDuration: number;
    maxSize: number;
}

export class ImageSlide extends Slide {

    constructor() {
        super("Image");
    }
}

export class Condition {}

export class Action {}

export class CampaignResult {
    campaign: Campaign;
    user: any;
    slides: Slide[];
    started: Date;
    finished: Date;
    submissions: any;
    rating: number;
}

