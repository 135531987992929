import {Component, OnInit, ViewChild} from '@angular/core';
import {ClrLoadingState, ClrModal} from "@clr/angular";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {UserFilter} from "../../../model/user";
import {CampaignService} from "../../../services/campaign.service";
import {ChannelService} from "../../../services/channel.service";
import {PostService} from "../../../services/post.service";
import {Observable, Subscription} from "rxjs";
import {AuthService} from "../../../services/auth/auth.service";
import {DialogComponent} from "../../dialogs/dialog/dialog.component";
import {delay} from "rxjs/operators";

interface Notification {
    title: string,
    message: string
}


@Component({
  selector: 'app-promote-wizard',
  templateUrl: './promote-wizard.component.html',
  styleUrls: ['./promote-wizard.component.scss']
})
export class PromoteWizardComponent implements OnInit {

    @ViewChild("modal") modal: DialogComponent;

    notificationForm: FormGroup;

    userFilter: UserFilter = new UserFilter();

    type: string;

    item = {};

    id: string;

    isSending = false;

    submitButtonState: ClrLoadingState = ClrLoadingState.DEFAULT;

    promotionSubscription: Subscription;

    constructor(private fb: FormBuilder,
                public authService: AuthService,
                private channelService: ChannelService,
                private postService: PostService,
                private campaignService: CampaignService) {
        this.notificationForm = fb.group({
            title: ["", [Validators.required, Validators.minLength(1)]],
            message: ["", [Validators.required, Validators.minLength(1), Validators.maxLength(120)]],
            sandbox: [false]
        });
    }

    ngOnInit() {
    }

    open(type: string, id: string) {
        this.type = type;
        this.id = id;
        this.notificationForm.reset();
        this.modal.open();
    }

    close() {
        this.modal.close();
        this.notificationForm.reset();
    }

    validate() {
        return !Object.keys(this.notificationForm.controls)
            .map(controlName => this.notificationForm.controls[controlName])
            .filter(control => {
                control.markAsTouched();
                control.updateValueAndValidity();
                return !control.valid;
            }).length;
    }

    submit() {

        let title = this.notificationForm.get("title").value;
        let message = this.notificationForm.get("message").value;

        var promotion: Observable<any>;

        switch (this.type) {
            case "Channel":
                promotion = this.channelService.promoteChannel(this.id, title, message, this.userFilter);
                break;
            case "Campaign":
                promotion = this.campaignService.promoteCampaign(this.id, title, message, this.userFilter);
                break;
            case "Post":
                promotion = this.postService.promotePost(this.id, title, message, this.userFilter);
                break;
            default:
                console.error("Unsupported promotion");
        }

        return promotion;
    }
}
