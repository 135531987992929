import { AfterViewInit, Component, OnInit, ViewChild, OnChanges } from '@angular/core';
import { ContentComponent } from "../../../components/content/content.component";
import { ScheduleComponent, Slot } from "../../../radio/schedule/schedule.component";
import {BehaviorSubject, Observable, of, ReplaySubject, Subject} from "rxjs";
import * as moment from "moment";
import { WeekRange } from "../../../utility/week-range";
import { CreateShowSlot, Show, ShowSlot } from "../../../model/show";
import { AddSlotComponent } from "../../../radio/add-slot/add-slot.component";
import { SelectScheduleComponent } from "../select-schedule/select-schedule.component";
import { ScheduleService } from "../../../services/schedule.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ChannelService } from "../../../services/channel.service";
import { StationService } from "../../../services/station/station.service";
import {switchMap} from "rxjs/operators";
import {Channel} from "../../../model/channel";

interface WeekSchedule {
}

@Component({
    selector: 'app-program',
    templateUrl: './program.component.html',
    styleUrls: ['./program.component.scss'],
    host: { 'class': 'item-content' }
})
export class ProgramComponent implements OnInit, AfterViewInit {

    @ViewChild("scheduleContent")
    content: ContentComponent<WeekSchedule>;

    @ViewChild("slotContent")
    slotContent: ContentComponent<Slot>;

    @ViewChild(ScheduleComponent)
    schedule: ScheduleComponent;

    @ViewChild("addSlotModal")
    addSlotModal: AddSlotComponent;

    @ViewChild(SelectScheduleComponent)
    selectSchedule: SelectScheduleComponent;

    item: WeekSchedule;

    timeInterval: number = 30;

    week: number;

    year: number;

    years: number[] = [];

    weeks: number[] = [];

    date: Date;

    channelId: string;

    slots: Slot[] = [];

    shows: Show[] = [];

    selectedSlot: Slot;

    editingSlot: Slot;

    $channel = new ReplaySubject<Channel>(1);

    constructor(private channelService: ChannelService,
        private scheduleService: ScheduleService,
        private stationService: StationService,
        private route: ActivatedRoute,
        private router: Router) {
        for (let i = 2018; i < 2030; i++)
            this.years.push(i);

        for (let i = 1; i < 53; i++)
            this.weeks.push(i);
    }

    ngOnInit() {
        this.year = moment().year();
        this.week = moment().week();

        this.route.queryParams.subscribe(params => {
            if (params['week'])
                this.week = parseInt(params['week']);

            if (params['year'])
                this.year = parseInt(params['year']);

            this.updateDate();
        });

        this.route.parent.params.switchMap(value => {
            return this.channelService.getChannel(value['name'])
        }).subscribe(channel => {
            this.channelId = channel.channelId;
            this.$channel.next(channel);
            this.content.refresh();
        });

    }

    ngAfterViewInit() {
        this.schedule.setProvider(this);

        setTimeout(() => {
            this.schedule.setInterval(null, 7,30);
            this.schedule.updateSlots();
        });
    }

    updateRoute() {
        this.router.navigate([], { relativeTo: this.route, queryParams: { week: String(this.week), year: String(this.year) }, queryParamsHandling: "merge" })
    }

    onWeekChange(week) {
        this.week = parseInt(week);

        this.updateRoute();
        this.updateDate();

        this.updateSchedule();
    }

    onYearChange(year) {
        this.year = parseInt(year);
        this.weeks = this.getWeeksForYear(this.year);

        this.updateRoute();
        this.updateDate();

        this.updateSchedule();
    }

    setTimeInterval(i: number) {
        this.timeInterval = i;
        this.schedule.setInterval(this.date, 7, i);
    }

    closeSlot() {
        this.editingSlot = null;
    }

    updateDate() {
        this.date = moment().year(this.year).week(this.week).day('Monday').toDate();
    }

    updateSchedule() {
        this.content.refresh();
        //let date = moment().year(this.year).week(this.week).day('Monday').toDate();
        //this.schedule.setInterval(date, 7, this.timeInterval);
    }

    getSchedule(): Observable<WeekSchedule> {

        return this.$channel
            .pipe(
                switchMap(channel =>
                    this.scheduleService.getShowSlotsForWeek(this.channelId, this.year, this.week)
                        .do(x => {
                            this.slots = x.map(s => <Slot>{
                                id: s.slotId,
                                showId: s.showId,
                                range: WeekRange.of(s.from, s.to),
                                title: s.title[this.stationService.getDefaultLocale()],
                                subTitle: s.subTitle[this.stationService.getDefaultLocale()],
                                valid: true,
                                selected: false,
                                data: s
                            });
                            this.schedule.updateSlots();
                        })));
    }

    saveSchedule(): Observable<any> {
        return this.scheduleService.saveShowSlots(this.channelId, this.year, this.week,
            this.slots.map(s => <CreateShowSlot>{
                slotId: s.data.slotId,
                from: s.range.from.getSeconds(),
                to: s.range.to.getSeconds(),
                showId: s.data.showId,
                title: s.data.title,
                subTitle: s.data.subTitle,
                summary: s.data.summary,
                content: s.data.content,
                coverImage: null
            }), true);
    }

    loadPrev() {
        this.week--;
        if (this.week < 1) {
            this.year--;
            this.week = this.getLastWeekOfYear(this.year);
            this.weeks = this.getWeeksForYear(this.year);
        }

        this.updateRoute();
        this.updateSchedule();
    }

    loadNext() {
        this.week++;
        if (this.week > this.getLastWeekOfYear(this.year)) {
            this.year++;
            this.weeks = this.getWeeksForYear(this.year);
            this.week = 1;
        }

        this.updateRoute();
        this.updateSchedule();
    }

    public importImportScheduleDialog() {
        this.selectSchedule.open(this.channelId, this.week, this.year);
    }

    private getLastWeekOfYear(year: number): number {
        return moment("12-28-" + year).isoWeek();
    }

    private getWeeksForYear(year: number): number[] {
        let w = [];
        let wc = this.getLastWeekOfYear(year);
        for (let i = 1; i < (wc + 1); i++) {
            w.push(i);
        }
        return w;
    }

    public importSchedule(schedule: string) {
        this.scheduleService.importSchedule(this.channelId, schedule, this.year, this.week)
            .subscribe(x => {
                this.content.refresh();
            }, error => {
                this.content.showError("Failed to import schedule");
            })
    }
        
    public importScheduleFailed(error) {
        this.content.showError("Failed to import schedule");
    }

    public scheduleImported() {
        this.content.refresh();
    }

    /* Schedule Provider Implementation */

    public getSlots(): Slot[] {
        return this.slots;
    }

    public updateSlots() {
        this.schedule.updateSlots();
    }


    public checkRangeValid(range: WeekRange): boolean {
        return this.schedule.checkValid(range);
    }

    public unselectAll() {
        for (let slot of this.slots) {
            slot.selected = false;
        }
        this.selectedSlot = null;
    }

    public selectSlot(slot: Slot) {
        this.selectedSlot = slot;
    }

    public editSlot(slot: Slot) {
        this.router.navigate([slot.data.slotId], { relativeTo: this.route });
    }

    public createSlot(range: WeekRange) {
        this.addSlotModal.open(this, range, this.channelId, this.week, this.year);
    }

    public deleteSlot(slot: Slot) {
        if (slot == null || slot.data == null || slot.data.slotId == null) {
            this.slots = this.slots.filter(s => s != slot);
            this.schedule.updateSlots();
            return;
        }

        this.content.deleteDialog.open(slot.data.slotId, null, () => {
            this.scheduleService.deleteShowSlot(slot.data.slotId)
                .subscribe(x => {
                    this.slots = this.slots.filter(s => s != slot);
                    this.schedule.updateSlots();
                }, error => {
                    this.content.showError("Failed to delete slot");
                })
        });
    }
}
