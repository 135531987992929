import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";
import {Member, MemberRole} from "../model/member";
import {HttpClient, HttpParams} from "@angular/common/http";
import {PagedResult} from "./admin.service";
import {AuthService} from "./auth/auth.service";
import {CreateImage, CreateImageResponse} from "../model/image";

@Injectable({
  providedIn: 'root'
})
export class MemberService {

    constructor(private http: HttpClient, private authService: AuthService) {
    }

    public createMember(username: string, password: string, displayName, email: string, profileImage: CreateImage,
                        enabled: boolean) {
        var req = {
            username: username,
            displayName: displayName,
            email: email,
            profileImage: profileImage,
            password: password,
            enabled: enabled,
        };

        return this.http.post("/api/members", req);
    }

    public getMembers(pageSize: number, offset: string, query: string): Observable<PagedResult<Member>> {
        let params = new HttpParams();
        if(pageSize != null)
            params = params.set("pageSize", String(pageSize));
        if(offset != null)
            params = params.set("offset", offset);
        if(query != null)
            params = params.set("query", query);

        return this.http.get<PagedResult<Member>>("/api/members", { params: params});
    }

    public getMember(name: string): Observable<Member> {
        return this.http.get<Member>( "/api/members/" + name)
    }

    public updateMember(member: Member): Observable<any> {
        const updates = {
            username: member.username,
            displayName: member.displayName,
            email: member.email,
            enabled: member.enabled,
            password: undefined,
            profileImageId: member.profileImageId,
        };

        return this.http.put(environment.apiEndpoint + "/api/members/" + member.memberId, updates);
    }

    public processProfileImage(memberId: string, createImage: CreateImage): Observable<string> {
        return this.http.post<CreateImageResponse>("/api/members/" + memberId + "/profileImage", createImage)
            .map(res => res.imageId);
    }

    public updateMemberRoles(memberId: string, roles: MemberRole[]) {
        return this.http.put("/api/members/" + memberId + "/roles", roles);
    }

    public setMemberPassword(memberId: string, password: string): Observable<any> {
        return this.http.put("/api/members/" + memberId + "/password", {password: password});
    }

    public deleteMember(id: string): Observable<any> {
        return this.http.delete("/api/members/" + id);
    }
}

