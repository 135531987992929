import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs/Observable";
import {PagedResult} from "./admin.service";
import {CreateShow, Show} from "../model/show";
import {Channel} from "../model/channel";
import {environment} from "../../environments/environment";
import {CampaignContentEntry, ImagesContentEntry} from "../model/content";
import {CreateImage, CreateImageResponse} from "../model/image";

@Injectable()
export class ShowService {

    constructor(private http: HttpClient) { }

    public createShow(show: CreateShow) {
        return this.http.post("/api/shows", show);
    }

    public getShows(pageSize: number, offset: string, query: string): Observable<PagedResult<Show>> {
        let params = new HttpParams();
        if(pageSize != null)
            params = params.set("limit", String(pageSize));
        if(offset != null)
            params = params.set("offset", offset);
        if(query != null)
            params = params.set("query", query);

        return this.http.get<PagedResult<Show>>(environment.apiEndpoint +"/api/shows", {params: params});
    }

    public getShow(name: string): Observable<Show> {
        return this.http.get<Show>(environment.apiEndpoint +"/api/shows/name=" + name);
    }

    public updateShow(show: Show): Observable<any> {
        // TODO: Clean up hack please..

        let entries;
        if (show.content != null && show.content.entries != null) {
            entries = show.content.entries.map(value => {
                if (value.type == "Images") {
                    return {
                        type: "Images",
                        images: (<ImagesContentEntry>value).images.map(value => {
                            return {
                                //copyright: value.copyright,
                                //license: value.license,
                                uploadUrl: value.uploadUrl,
                                imageId: value.imageId,
                                license: value.license != null ? value.license.name : null,
                                copyright: value.copyright,
                            }
                        })
                    }
                } else if (value.type == "Campaign") {
                    return {
                        type: "Campaign",
                        campaignId:  (<CampaignContentEntry>value).campaignId
                    }
                } else {
                    return value;
                }
            });
        }

        return this.http.put(environment.apiEndpoint +"/api/shows/id=" + show.showId, {
            stationId: show.stationId,
            name: show.name,
            title: show.title,
            subTitle: show.subTitle,
            summary: show.summary,
            coverImageId: show.coverImageId,
            content: show.content != null ? {
                text: show.content.text,
                entries: entries
            } : null
        });
    }

    public processCoverImage(showId: string, createImage: CreateImage): Observable<string> {
        return this.http.post<CreateImageResponse>("/api/shows/id=" + showId + "/coverImage", createImage)
            .map(res => res.imageId);
    }
    public deleteShow(showId: string): Observable<any> {
        return this.http.delete(environment.apiEndpoint +"/api/shows/id=" + showId);
    }
}
