import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AuthService} from "../services/auth/auth.service";
import {Router} from "@angular/router";
import {ClrModal} from "@clr/angular";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    @ViewChild("errorDialog")
    errorDialog: ClrModal;

    orga: string;

    email: string;

    password: string;

    errorTitle: string;

    errorMessage: string;

    constructor(private router: Router, private authService: AuthService) { }

    ngOnInit() {
    }

    login() {
        this.authService.login(this.orga, this.email, this.password)
            .subscribe(res => {
                this.router.navigate(['/'])
            }, (error) => {
                if(error.status == 500) {
                    this.showError("Login failed", "Server error. Try again later or contact support");
                } else {
                    this.showError("Login failed", "Bad credentials");
                }
            });
    }

    showError(title: string, message: string) {
        this.errorTitle = title;
        this.errorMessage = message;
        this.errorDialog.open();
    }
}
