import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../../services/auth/auth.service";
import {MemberService} from "../../../services/member.service";
import {CreatePost} from "../../../model/post";
import {CreateMember, Member} from "../../../model/member";
import {Observable} from "rxjs";
import {ContentComponent} from "../../../components/content/content.component";

@Component({
    selector: 'app-create-member',
    templateUrl: './create-member.component.html',
    styleUrls: ['./create-member.component.scss'],
    host: {'class': 'item-content'}
})
export class CreateMemberComponent implements OnInit {

    @ViewChild(ContentComponent)
    content: ContentComponent<CreatePost>;

    item = new CreateMember();

    readonly imageUploadUrl = "/api/uploads/member-profile";

    form: FormGroup;

    constructor(private fb: FormBuilder, private route: ActivatedRoute,private router: Router,
                private authService: AuthService, private memberService: MemberService) {
        this.form = this.fb.group({
            username: ['', [Validators.required, Validators.min(2), Validators.maxLength(32) ]],
            displayName: ['', [Validators.required, Validators.min(2), Validators.maxLength(32) ]],
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.minLength(6), Validators.maxLength(32)]],
        });
    }

    ngOnInit() {
    }

    onCreated() {
        this.router.navigate(['..', 'list'], {relativeTo: this.route})
    }

    validate(item: CreateMember) {
        return true;
    }

    createMember(item: Member): Observable<any> {
        return this.memberService.createMember(this.item.username, this.item.password, this.item.displayName,
            this.item.email, this.item.profileImage, true);
    }
}
