import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {AddSlotComponent} from "../add-slot/add-slot.component";
import {ScheduleComponent, Slot} from "../schedule/schedule.component";
import {WeekTime} from "../../utility/week-time";
import {WeekRange} from "../../utility/week-range";
import {ScheduleProvider} from "../schedule/schedule-provider";
import {FormBuilder, FormGroup, NgModel, Validators} from "@angular/forms";
import {Schedule, ScheduleService} from "../../services/schedule.service";
import {RadioService} from "../../services/radio.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Channel} from "../../model/channel";
import {ChannelService} from "../../services/channel.service";
import {ScheduledSlot} from "../../model/scheduled-slot";
import * as moment from "moment";
import {Observable} from "rxjs/Observable";
import {ContentComponent} from "../../components/content/content.component";
import {CreateScheduleSlot, Show} from "../../model/show";
import {ShowService} from "../../services/show.service";
import {StationService} from "../../services/station/station.service";


@Component({
    selector: 'app-edit-schedule',
    templateUrl: './edit-schedule.component.html',
    styleUrls: ['./edit-schedule.component.scss'],
    host: {'class': 'item-content'}
})
export class EditScheduleComponent implements OnInit, AfterViewInit, ScheduleProvider {

    @ViewChild("addSlotModal") addSlotModal: AddSlotComponent;

    @ViewChild("scheduleComponent") scheduleComponent: ScheduleComponent;

    @ViewChild(ContentComponent)
    content: ContentComponent<Schedule>;

    form: FormGroup;

    scheduleId: string;

    date: Date;

    name: string;

    timeInterval: number = 30;

    slots: Slot[] = [];

    shows: Show[] = [];

    selectedSlot : Slot;

    constructor(private fb: FormBuilder,
                private route: ActivatedRoute, private router: Router, private channelService: ChannelService,
                private scheduleService: ScheduleService, private showService: ShowService, public stationService: StationService) {
        this.form = fb.group({
            name: ["", [Validators.required, Validators.minLength(2), Validators.maxLength(64)]]
        })
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.scheduleId = params['id'];
        })
    }

    ngAfterViewInit() {
        this.scheduleComponent.setProvider(this);

        setTimeout(() => {
            this.scheduleComponent.setInterval(null, 7,30);
            this.scheduleComponent.updateSlots();
        });
    }

    public setTimeInterval(i: number) {
        this.timeInterval = i;
        this.scheduleComponent.setInterval(this.date, 7, i);
    }


    public getSchedule(): Observable<Schedule> {
        return this.scheduleService.getSchedule(this.scheduleId)
            .do(schedule => {
                this.name = schedule.name;

                for(let s of schedule.slots) {
                    this.slots.push(<Slot> {
                        range: new WeekRange(WeekTime.fromSeconds(s.from), WeekTime.fromSeconds(s.to)),
                        title: s.showTitle[this.stationService.getDefaultLocale()],
                        valid: true,
                        data: s.showId
                    })
                }

                this.scheduleComponent.updateSlots();
            });
    }

    public updateSchedule(): Observable<any> {
        // Build slots
        let createSlots: CreateScheduleSlot[] = [];

        for(let slot of this.slots) {
            createSlots.push({
                from: slot.range.from.getSeconds(),
                to: slot.range.to.getSeconds(),
                showId: slot.data
            });
        }

        return this.scheduleService.updateSchedule(this.scheduleId, this.name, createSlots);
    }

    /* Schedule Provider Implementation */

    public getSlots(): Slot[] {
        return this.slots;
    }

    public updateSlots() {
        this.scheduleComponent.updateSlots();
    }

    public checkRangeValid(range: WeekRange): boolean {
        return this.scheduleComponent.checkValid(range);
    }


    onFinishCreateSlot(params: any) {
        if(params == null) return;

        this.slots.push({
            id: null,
            range: params.range,
            title: params.show.title,
            subTitle: "",
            selected: false,
            valid: true,
            data: params.show.showId});

        this.scheduleComponent.updateSlots();
        this.content.setDirty();
    }


    public unselectAll() {
        for(let slot of this.slots) {
            slot.selected = false;
        }
        this.selectedSlot = null;
    }

    editSlot (slot: Slot) {
        // TODO: implement
    }

    public selectSlot(slot: Slot) {
        this.selectedSlot = slot;
    }

    public createSlot(range: WeekRange) {
        this.addSlotModal.open(this, range, null, null, null);
    }

    public deleteSlot(slot: Slot) {
        this.slots = this.slots.filter(s => s != slot);
        this.scheduleComponent.updateSlots();
        this.content.setDirty();
    }
}
