import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {ClrModal} from "@clr/angular";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {concat, Observable, of, Subject} from "rxjs";
import {catchError, debounceTime, distinctUntilChanged, switchMap, tap} from "rxjs/operators";
import {Campaign} from "../../../model/campaign";
import {CampaignService} from "../../../services/campaign.service";

interface ContentType {
    type: string;
    name: string;
}

interface CreateContent {
    type: string;
    params: {}
}

@Component({
  selector: 'app-add-content',
  templateUrl: './add-content.component.html',
  styleUrls: ['./add-content.component.scss']
})
export class AddContentComponent implements OnInit {

    @ViewChild("modal") modal: ClrModal;

    modalOpen: boolean;

    contentTypes: ContentType[] = [
        {type: "Images", name: "Image Gallery"},
        {type: "YouTube", name: "YouTube Video"},
        // {type: "Audio", name: "Audio File"},
         {type: "Campaign", name: "Campaign"},
    ];

    form: FormGroup;

    imagesForm: FormGroup;

    youTubeForm: FormGroup;

    campaignForm: FormGroup;

    campaignInput$ = new Subject<string>();

    selectedCampaign: Campaign;

    loading: boolean = false;

    campaigns$: Observable<Campaign[]>;

    @Output("onFinish")
    onFinish: EventEmitter<CreateContent> = new EventEmitter<CreateContent>();

    constructor(private fb: FormBuilder, private campaignService: CampaignService) {
        this.form = fb.group({
            "contentType": [null, [Validators.required]]
        });

        this.imagesForm = fb.group({
        });

        this.youTubeForm = fb.group({
            "youTubeId": [null, [Validators.required]]
        });

        this.campaignForm = this.fb.group({
            campaign: [null, Validators.required]
        })

    }

    ngOnInit() {
        this.loadCampaigns();
    }

    open() {
        this.modal.open();
    }


    private loadCampaigns() {
        this.campaigns$ = concat(
            of([]),
            this.campaignInput$.pipe(
                debounceTime(200),
                distinctUntilChanged(),
                tap(() => this.loading = true),
                switchMap(input => this.campaignService.getCampaigns(10, null, input)
                    .map(res => res.items)
                    .pipe(
                        catchError(() => of([])),
                        tap(() => this.loading = false)
                    ))
            )
        );
    }


    public finish() {
        let contentType = this.form.get("contentType").value;

        switch (contentType) {
            case "Images":
                this.onFinish.emit({
                    type: "Images",
                    params: null
                });
                break;
            case "YouTube":
                this.onFinish.emit({
                    type: "YouTube",
                    params: { youTubeId: this.youTubeForm.get("youTubeId").value }
                });
                break;
            case "Campaign":
                this.onFinish.emit({
                    type: "Campaign",
                    params: {campaign: this.selectedCampaign }
                })
        }


        this.reset();
        this.close();
    }

    public reset() {
        this.form.reset();
    }

    public close() {
        this.modal.close();
        this.reset();
    }

}
