import {Component, Input, OnInit, ViewChild, ViewChildren} from '@angular/core';
import {AddContentComponent} from "../add-content/add-content.component";
import {Content} from "../../../model/content";
import {ContentComponent} from "../../../components/content/content.component";
import {LocalizedFieldDirective} from "../../../directives/localized.directive";
import {FieldDirective} from "../../../directives/field.directive";
import * as R from "ramda";
import {StationService} from "../../../services/station/station.service";

@Component({
  selector: 'app-text-content',
  templateUrl: './text-content.component.html',
  styleUrls: ['./text-content.component.scss']
})
export class TextContentComponent implements OnInit {

    @ViewChild(AddContentComponent)
    addContentDialog: AddContentComponent;

    constructor(public stationService: StationService) { }

    @ViewChildren(FieldDirective)
    fields;

    @ViewChildren(LocalizedFieldDirective)
    localizedFields;

    uploadImageUrl = "/api/uploads/content-image";

    @Input()
    parent: ContentComponent<any>;

    @Input()
    field: string;

    textField: string;

    @Input()
    item: Content;

    textModule = { toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'indent': '-1'}, { 'indent': '+1' }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'font': [] }],
            [{ 'align': [] }],
            ['link']
        ]};

    ngOnInit() {
        if(this.field != null) {
            this.textField = this.field + ".text";
        } else {
            this.textField = "text"
        }
    }

    ngAfterViewInit() {
        if(this.parent != null) {

            if(this.parent.localizedFields != null) {
                this.parent.localizedFields.reset([this.parent.localizedFields.toArray(), this.localizedFields.toArray()]);
            }

            if(this.parent.fields != null) {
                this.parent.fields.reset([this.parent.fields.toArray(), this.fields.toArray()]);
            }

            this.parent.onLoaded.subscribe(item => {

                if(this.field != null) {
                    this.item = <Content>R.path(this.field.split('.'))(item)
                } else {
                    this.item = item;
                }

                if(this.item == null) {
                    this.item = new Content();
                }

                if(this.item.entries == null) {
                    this.item.entries = [];
                }

                if(this.parent.langSelect != null) {
                    // TODO: implement when needed
                }
            })
        }
    }

    onContentCreated(value) {
        this.setDirty();

        switch(value.type) {
            case "Images":
                this.addContentEntry({
                    type: "Images",
                    images: []
                });
                break;
            case "Audio":
                this.addContentEntry({
                    type: "Audio"
                });
                break;
            case "YouTube":
                this.addContentEntry({
                    type: "YouTube",
                    youTubeId: value.params.youTubeId
                });
                break;
            case "Campaign":
                this.addContentEntry({
                    type: "Campaign",
                    campaignId: value.params.campaign.campaignId,
                    title: value.params.campaign.title,
                    summary: value.params.campaign.summary,
                    coverImageId: value.params.campaign.coverImageId
                });
            default:
                return;
        }
    }

    setDirty() {
        if(this.parent)
            this.parent.setDirty();
    }

    getYouTubeLink(id: string): string {
        return "https://www.youtube.com/embed/" + id + "?autoplay=0"
    }

    addContentEntry (contentEntry) {
        if(this.item == null || this.item.entries == null)
            return;

        this.item.entries.push(contentEntry);
    }

    removeContentEntry (contentEntry) {
        this.setDirty();

        if(this.item == null || this.item.entries == null)
            return;

        this.item.entries =
            this.item.entries.filter(value => value != contentEntry);
    }
}
