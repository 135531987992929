import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ClrModal} from "@clr/angular";
import {UserService} from "../../../services/user.service";
import {delay} from "rxjs/operators";
import {DialogComponent} from "../../../components/dialogs/dialog/dialog.component";

@Component({
  selector: 'app-debug-message-dialog',
  templateUrl: './debug-message-dialog.component.html',
  styleUrls: ['./debug-message-dialog.component.scss']
})
export class DebugMessageDialogComponent implements OnInit {

    form: FormGroup;

    @ViewChild("modal") modal: DialogComponent;

    userId: string;

    constructor(fb: FormBuilder, private userService: UserService) {
        this.form = fb.group({
            title: ["", [Validators.required, Validators.minLength(1)]],
            message: ["", [Validators.required, Validators.minLength(1)]],
            sandbox: [false]
        });
    }

    ngOnInit() {
    }

    open(userId: string) {
        this.userId = userId;
        this.form.reset();
        this.modal.open();
    }

    validate() {
        return !Object.keys(this.form.controls)
            .map(controlName => this.form.controls[controlName])
            .filter(control => {
                control.markAsTouched();
                control.updateValueAndValidity();
                return !control.valid;
            }).length;
    }

    submit() {
        let title = this.form.get("title").value;
        let message = this.form.get("message").value;
        let sandbox = this.form.get("sandbox").value;

        return this.userService.sendDebugMessage(this.userId, title, message, sandbox)
    }

    close() {
        this.form.reset();
    }
}
