import {Directive, ElementRef, forwardRef, HostListener, Renderer} from '@angular/core';
import {NG_VALUE_ACCESSOR} from "@angular/forms";
import {Time} from "@angular/common";

export const TIME_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TimeValueDirective),
    multi: true
};



@Directive({
    selector: '[useValueAsTime]',
    providers: [TIME_VALUE_ACCESSOR]
})
export class TimeValueDirective {

    @HostListener('input', ['$event.target.valueAsDate']) onChange = (_: any) => { };
    @HostListener('blur', []) onTouched = () => { };

    constructor(private _renderer: Renderer, private _elementRef: ElementRef) { }


    writeValue(value: Time): void {
        this._renderer.setElementProperty(this._elementRef.nativeElement, 'valueAsDate', value);
    }

    registerOnChange(fn: (_: any) => void): void { this.onChange = fn; }
    registerOnTouched(fn: () => void): void { this.onTouched = fn; }

    setDisabledState(isDisabled: boolean): void {
        this._renderer.setElementProperty(this._elementRef.nativeElement, 'disabled', isDisabled);
    }

}
