import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Subject} from "rxjs/Subject";

import 'rxjs/add/operator/do';
import 'rxjs/add/operator/shareReplay';
import {Station} from "../../model/station";
import {environment} from '../../../environments/environment';
import {Observable} from "rxjs/Observable";
import {Member, MemberMe} from "../../model/member";

interface LoginResponse {
    authToken: string;
    refreshToken: string;
    user: Member;
}

//TODO: CLEANUP!!!!

@Injectable()
export class AuthService {

    private static readonly LOGIN_URL: string = environment.apiEndpoint + '/api/login';

    private static readonly LOGOUT_URL: string = environment.apiEndpoint + '/api/logout';

    private static readonly AUTH_TOKEN_KEY: string = 'authToken';

    private static readonly REFRESH_TOKEN_KEY: string = 'refreshToken';

    private static readonly CURRENT_STATION_KEY: string = 'currentStation';

    private static readonly CURRENT_USER_KEY: string = 'currentUser';

    private me: MemberMe = null;

    private _isAdmin: boolean = false;

    _currentStationObservable: Subject<Station> = Subject.create();

    get currentStationObservable(): Subject<any> {
        return this.currentStationObservable;
    }

    constructor(private http: HttpClient) { }

    public getAccessToken(): string {
        return localStorage.getItem(AuthService.AUTH_TOKEN_KEY);
    }

    public getRefreshToken(): string {
        return localStorage.getItem(AuthService.AUTH_TOKEN_KEY);
    }

    public login(orga: string, username: string, password: string) {
        let request = {orgaName: orga, username: username, password: password};
        return this.http.post<LoginResponse>(AuthService.LOGIN_URL, request)
            .do(res => {
                this.setSession(res)
            })
            .mergeMap(value => this.http.get<MemberMe>("/api/me"))
            .do(res => {
                this.setMember(res)
            });
    }

    public refreshToken(): Observable<LoginResponse> {
        let token = localStorage.getItem(AuthService.REFRESH_TOKEN_KEY);
        if(token != null) {
            return this.http.post<LoginResponse>(environment.apiEndpoint + "/api/refresh", {refreshToken: token})
                .do(response => {
                    this.setSession(response)
                })
        } else {
            return Observable.throw(new Error("Failed to refresh token"));
        }
    }

    public logout() {
        // FIXME: must provide access token to /logout endpoint.
        // Since we call the /logout endpoint without any access token it has no effect at the moment
        // (and does indeed return a 400 response).
        this.me = null;
        this.clearSession();
        return this.http.post(AuthService.LOGOUT_URL, {});
    }

    public refresh(done: (ok: boolean) => void = null) {
        return this.http.get<MemberMe>("/api/me")
            .subscribe(me => {
                this.me = me;
                this.setMember(me);
                if(done != null) done(true);
            }, error => {
                this.logout();
                if(done != null) done(false);
            });
    }

    public getAuthToken() {
        return localStorage.getItem(AuthService.AUTH_TOKEN_KEY);
    }

    public getActiveOrga() {
        if(localStorage.getItem(AuthService.CURRENT_USER_KEY)) {
            let user = JSON.parse(localStorage.getItem(AuthService.CURRENT_USER_KEY));
            if(user) {
                return user.orgaId;
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    public getAvailableStations() {
        if (localStorage.getItem(AuthService.CURRENT_USER_KEY) == null) {
            return [];
        } else {
            try {
                let user = JSON.parse(localStorage.getItem(AuthService.CURRENT_USER_KEY));
                if (user)
                    return user.stations;
                else
                    return [];
            } catch (e) {
                this.clearSession();
                return [];
            }
        }
    }

    public getActiveStation() {
        if(localStorage.getItem(AuthService.CURRENT_STATION_KEY) != null) {
            try {
                return JSON.parse(localStorage.getItem(AuthService.CURRENT_STATION_KEY));
            } catch (e) {
                this.clearSession();
                return null;
            }
        } else {
            return null;
        }
    }

    public setActiveStation(station: Station) {
        localStorage.setItem(AuthService.CURRENT_STATION_KEY, JSON.stringify(station));
        this._currentStationObservable.next(station);
    }

    public isRoot(): boolean {
        return this.me != null ? this.me.root : false;
    }

    public isLoggedIn(): boolean {
        return this.getAuthToken() != null;
    }

    public isAdmin(): boolean {
        if(this.me == null) {
            return false;
        } else {
            if(this.isRoot())
                return true;

            return this._isAdmin;
        }
    }

    public getMe(): Member {
        if(this.me != null) {
            return this.me.profile;
        } else {
            return null;
        }
    }

    private setSession(res: LoginResponse) {
        localStorage.setItem(AuthService.AUTH_TOKEN_KEY, res.authToken);
        localStorage.setItem(AuthService.REFRESH_TOKEN_KEY, res.refreshToken);
    }

    public setMember(user: MemberMe) {
        this.me = user;

        for(let r of user.profile.roles) {
            if(r.scope == 'ORGA' && r.role == 'ADMIN')
                this._isAdmin = true;
        }


        localStorage.setItem(AuthService.CURRENT_USER_KEY, JSON.stringify(user.profile));
        // Select first station as default if not set
        //if(localStorage.getItem(AuthService.CURRENT_STATION_KEY) == null) {
        if(user.profile.stations.length > 0) {
            //localStorage.setItem(AuthService.ACTIVE_STATION_KEY, JSON.stringify(user.stations[0]));
            localStorage.setItem(AuthService.CURRENT_STATION_KEY, JSON.stringify(user.profile.stations[0]));
        }
        //}
    }

    public clearSession() {
        localStorage.removeItem(AuthService.AUTH_TOKEN_KEY);
        localStorage.removeItem(AuthService.CURRENT_USER_KEY);
        localStorage.removeItem(AuthService.CURRENT_STATION_KEY);
    }
}
