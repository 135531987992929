import {CreateImage} from "./image";
import {Content} from "./content";

export class CreateShow {
    stationId: string;
    name: string;
    title: {};
    summary: {};
    subTitle: {};
    coverImage: CreateImage;
}


export class Show {
    showId: string;
    stationId: string;
    name: string;
    title: {};
    subTitle: {};
    summary: {};
    coverImageId: string;
    content: Content;
}

export class CreateScheduleSlot {
    from: number;
    to: number;
    showId: string;
}

export class CreateShowSlot {
    slotId: string;
    showId: string;
    from: number;
    to: number;
    title: {};
    subTitle: {};
    summary: {};
    coverImage: CreateImage;
    content: Content;
}

export class ShowSlot {
    slotId: string;
    showId: string;
    from: Date;
    to: Date;
    title: {};
    subTitle: {};
    summary: {};
    coverImageId: string;
    content: Content;
}
