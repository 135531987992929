import {
    Component,
    ContentChild, Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
    TemplateRef
} from '@angular/core';
import {AfterViewInit} from "@angular/core/src/metadata/lifecycle_hooks";
import {WeekRange} from "../../utility/week-range";
import {WeekTime} from "../../utility/week-time";
import {ScheduleProvider} from "./schedule-provider";
import * as moment from "moment";
import {StationService} from "../../services/station/station.service";

export interface Slot {
    id: string;
    range: WeekRange;
    title: string;
    subTitle: string;
    selected: boolean;
    valid: boolean;
    data: any;
}

interface Interval {
    title: string,
    value: number
}

interface Day {
    title: string;
    value: number
}

interface Position {
    x: number;
    y: number;
}

interface Span {
    pos: Position;
    height: number;
    slot: Slot;
}

@Directive({
    selector: '[slotCustom]'
})
export class ScheduleCustomDirective {}

export interface SlotSpan extends Span {
    slot: Slot;
    primary: boolean;
}

@Component({
    selector: 'app-schedule',
    templateUrl: './schedule.component.html',
    styleUrls: ['./schedule.component.scss'],
    host: {
        '(document:keyup)': 'onKey($event)'
    }
})
export class ScheduleComponent implements OnInit, AfterViewInit {

    // TODO: HACK, calculate proper width..
    readonly DEFAULT_CELL_WIDTH = 200;

    readonly DEFAULT_CELL_HEIGHT = 30;

    days: Day[] = [
        { title: 'Mon', value: 0 },
        { title: 'Tue', value: 1 },
        { title: 'Wed', value: 2 },
        { title: 'Thu', value: 3 },
        { title: 'Fri', value: 4 },
        { title: 'Sat', value: 5 },
        { title: 'Sun', value: 6 }
    ];

    columns:Day [] = this.days;

    @Input()
    mode: string = 'week';


    timeInterval: number = 1800;

    intervals: Interval[] = null;

    _date: Date;

    @Input()
    set date(d: Date) {
        this.setInterval(d, this._numDays, this._interval);
    }

    @Input() 
    set interval(i: number) {
        this.setInterval(this._date, this._numDays, i);
    }

    _interval: number;

    @Input()
    set numDays(d: number) {
        this.setInterval(this._date, d, this._interval);
    }

    _numDays: number;

    @Input()
    inline = false;

    cellWidth: number;

    cellHeight: number = 40;

    spans: Span[];

    @ContentChild(ScheduleCustomDirective, {read: TemplateRef})
    customTemplate;


    // Selection

    selected: any;

    // Create

    createSpans: Span[] = [];

    createActive: boolean;

    createValid: boolean;

    createRange: WeekRange;

    provider: ScheduleProvider;

    week: number;

    constructor(private root: ElementRef, public stationService: StationService) {
    }

    ngOnInit() {
    }


    ngAfterViewInit() {
        this.cellHeight = this.getCellHeight();
        this.cellWidth = this.getCellWidth();
    }

    public setProvider(provider: ScheduleProvider) {
        this.provider = provider;
    }

    public updateSlots() {
        this.cellHeight = this.getCellHeight();
        this.cellWidth = this.getCellWidth();

        if(this.provider == null)
            return;

        let newSpans : SlotSpan[] = [];

        for(let slot of this.provider.getSlots()) {
            let spans = this.buildSpansForSlot(slot);
            for(let span of spans) {
                newSpans.push(span);
            }
        }

        this.spans = newSpans;
    }

    public setInterval(date: Date, days: number, interval: number) {
        this._date = date;
        this._numDays = days;
        this._interval = interval;

        if(this._numDays == null || this._interval == null)
            return;

        this.timeInterval = interval * 60;

        let newColumns = [];
        if(date != null) {
            for (let i = 0; i < days; i++) {
                let title = moment(date).add(i, 'days').format("ddd DD-MM-YYYY");
                newColumns.push(<Day>{title: title, value: i})
            }
        } else {
            newColumns = this.days;
        }

        this.columns = newColumns;

        this.buildIntervals(0, WeekTime.SECONDS_PER_DAY, this.timeInterval);
        this.updateSlots();
    }

    public setDate(date: Date) {
        this.date = date;
        if(this.columns != null && this.columns.length > 0)
            this.columns[0].title = date.toDateString();
        this.updateSlots();
    }

    buildIntervals(min: number, max: number, interval: number) {
        
        let intervals = [];

        this.cellHeight = this.getCellHeight();
        this.cellWidth = this.getCellWidth();

        for(let i=min; i < max; i+= interval) {
            intervals.push({ title: this.getIntervalTitle(i), value: i});
        }

        this.intervals = intervals;

    }

    private buildSlotSpans() {
        if(this.provider == null)
            return;

        this.cellHeight = this.getCellHeight();
        this.cellWidth = this.getCellWidth();

        let newSpans : SlotSpan[] = [];

        for(let slot of this.provider.getSlots()) {
            let spans = this.buildSpansForSlot(slot);
            for(let span of spans) {
                newSpans.push(span);
            }
        }

        this.spans = newSpans;
    }

    private buildSpansForSlot(slot: Slot) {
        let spans: SlotSpan[] = [];
        let primary: boolean = true;

        // Iterate over week plus 1 day for overlaps between weeks
        // TODO: allow overlaps over weeks with more than one day
        for(let currentDay = 0; currentDay < 14; currentDay++) {
            let currentDayValue = currentDay * WeekTime.SECONDS_PER_DAY;

            // Skip days without spans

            if((slot.range.from.getSeconds() >= (currentDayValue + WeekTime.SECONDS_PER_DAY)) ||
                (slot.range.to.getSeconds() <= currentDayValue))
                continue;

            if(this.mode == 'day' && !((moment(this.date).isoWeekday() -1) == (currentDay)))
                continue;

            // Limit spans to minimum and maximum of day
            let spanFrom = Math.max(slot.range.from.getSeconds(), currentDayValue);
            let spanTo = Math.min(slot.range.to.getSeconds(), currentDayValue + this.getSecondsPerDay(1));

            let span = {
                pos: this.getSpanPosition(spanFrom),
                height: this.getSpanHeight(spanTo - spanFrom),
                slot: slot,
                primary: primary
            };

            spans.push(span);
            primary = false;
        }

        return spans;
    }

    private buildSpans(range: WeekRange): Span[] {
        let spans: Span[] = [];

        this.cellHeight = this.getCellHeight();
        this.cellWidth = this.getCellWidth();

        for(let currentDay = 0; currentDay < 7; currentDay++) {
            let currentDayValue = currentDay * WeekTime.SECONDS_PER_DAY;

            // Skip days without spans
            if((range.from.getSeconds() > (currentDayValue + WeekTime.SECONDS_PER_DAY)) || (range.to.getSeconds() < currentDayValue))
                continue;

            // Limit spans to minimum and maximum of day
            let spanFrom = Math.max(range.from.getSeconds(), currentDayValue);
            let spanTo = Math.min(range.to.getSeconds(), currentDayValue + this.getSecondsPerDay(1));

            let span = {
                pos: this.getSpanPosition(spanFrom),
                height: this.getSpanHeight(spanTo - spanFrom),
                slot: null
            };

            spans.push(span);
        }

        return spans;
    }

    public checkValid(range: WeekRange) {
        if(this.provider.getSlots() == null)
            return;


        if(range == null)
            return false;


        let from = range.from.getSeconds();
        let to = range.to.getSeconds();

        // Wrap range values for overlap test

        from = from % WeekTime.SECONDS_PER_WEEK;
        to = to % WeekTime.SECONDS_PER_WEEK;

        // Check for overlapping
        for(let slot of this.provider.getSlots()) {

            if(slot.range.from.getSeconds() < to && slot.range.from.getSeconds() > from) {
                return false;
            } else if(slot.range.to.getSeconds() < to && slot.range.to.getSeconds() > from) {
                return false;
            } else if(slot.range.from.getSeconds() < from && slot.range.to.getSeconds() > to && !range.to.nextWeek) {
                return false;
            }

            // Range is overlapping to next week
            if( to < from )  {
                if(slot.range.from.getSeconds() < to || slot.range.to.getSeconds() < to)
                    return false;
            }
        }

        return true;
    }

    // Input callbacks

    onMouseUp () {
        if(this.createActive) this.finishCreate();
    }

    onMouseLeave() {
        if(this.createActive)
            this.cancelCreate();
    }

    onMouseMove(e: MouseEvent) {
        if(this.createActive) {

        }
    }

    onKey(e: KeyboardEvent) {
        if(e.keyCode == 46) {
            // Delete
            if (this.selected != null) {
                this.deleteSlot(this.selected);
                this.selectSlot(null);
            }
        } else if(e.keyCode == 27) {
            this.unselectAll();
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize($event) {
        this.updateSlots();
    }

    // Select

    selectSlot(slot: Slot) {
        if(this.provider == null)
            return;

        this.unselectAll();

        if(slot != null) {
            slot.selected = true;
            this.selected = slot;
            this.provider.selectSlot(slot);
        }
    }

    unselectAll() {
        this.provider.unselectAll();
    }

    // Edit

    editSlot(slot: Slot) {
        if(this.provider == null)
            return;

        if(slot != null) {
            this.provider.editSlot(slot);
        }
    }

    // Create

    beginCreate(e: MouseEvent, day: number, value: number) {
        this.unselectAll();

        this.createValid = true;
        this.createActive = true;

        let from = this.getSecondsPerDay(day) + value;
        let to = from + this.timeInterval;

        this.createRange = new WeekRange(WeekTime.fromSeconds(from), WeekTime.fromSeconds(to));
        this.createSpans = this.buildSpans(this.createRange);
    }

    moveCreate(e: MouseEvent, day: number, value: number) {
        if(this.createActive) {
            let newValue = day * WeekTime.SECONDS_PER_DAY + value;

            if(newValue < this.createRange.from.getSeconds()) {
                return;
            } else {
                this.createRange.to = WeekTime.fromSeconds(newValue + this.timeInterval);
            }

            this.createValid = this.checkValid(this.createRange);
            this.createSpans = this.buildSpans(this.createRange);
        }
    }

    cancelCreate() {
        this.unselectAll();
        this.createActive = false;
        this.createSpans = [];
    }

    finishCreate() {
        this.unselectAll();

        this.createActive = false;
        this.createSpans = [];

        if(!this.createValid)
            return;

        if(this.provider == null)
            return;

        this.provider.createSlot(this.createRange);
    }

    // Delete

    deleteSlot(slot: Slot) {
        if(this.provider == null)
            return;

        this.provider.deleteSlot(slot);
    }

    /**
     * Generates title for interval
     * @param {number} value Seconds
     * @returns {string} Generated title
     */
    private getIntervalTitle(value: number): string {
        // TODO: support for seconds

        let sec = value % 60;
        let min = Math.floor(value / 60) % 60;
        let hours = Math.floor(value / 3600);

        let out: string = "";
        if(hours < 10)
            out += "0" + hours + ":";
        else
            out += hours + ":";

        if(min < 10)
            out += "0" + min;
        else
            out += min;

        return out;
    }

    /**
     * Returns amount of seconds for amount of days
     * @param {number} day Amount of days
     * @returns {number} Amount of seconds
     */
    private getSecondsPerDay(day: number) {
        return day * 1440 * 60;
    }

    /**
     * Returns position of a span for a value
     * @param {number} value
     * @returns {Position}
     */
    private getSpanPosition(value: number): Position {
        let week = value % (10080 * 60);
        let secInDay = week % this.getSecondsPerDay(1);
        let day = Math.floor(week / this.getSecondsPerDay(1));

        let x = day * this.cellWidth + 60;

        if(this.mode == 'day') {
            x = 60;
        }

        let y = Math.floor((this.cellHeight  / this.timeInterval) * secInDay);

        return {x, y};
    }

    /**
     * Returns the with of a column in pixel
     * @returns {number} Width of column in pixel
     */
    private getCellWidth(): number {
        if(this.root.nativeElement.querySelector(".value-column") != null)
            return this.root.nativeElement.querySelector(".value-column").getBoundingClientRect().width;
        else
            return this.DEFAULT_CELL_WIDTH;
    }

    /**
     * Returns the height of a cell representing one full interval in pixel
     * @returns {number} Height of interval
     */
    private getCellHeight(): number {
        if(this.root.nativeElement.querySelector(".value-row") != null)
            return this.root.nativeElement.querySelector(".value-row").getBoundingClientRect().height;
        else
            return this.DEFAULT_CELL_HEIGHT;
    }

    /**
     * Returns the height for a span in pixels
     * @param {number} value Value to calculate span height for
     * @returns {number}
     */
    private getSpanHeight(value: number): number {
        return Math.round(this.cellHeight / this.timeInterval * value);
    }
}
