import {AfterViewInit, ContentChild, ContentChildren, Directive, Input, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {LocalizedFieldDirective} from "./localized.directive";


@Directive({
  selector: '[rsForm]'
})
export class FormDirective implements OnInit, AfterViewInit {

    @Input("formGroup")
    formGroup: FormGroup;

    @ContentChildren(LocalizedFieldDirective)
    localizedFields;

    item: any;

    constructor() { }

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.localizedFields.forEach(field => {
        });
    }
}
