import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ClrModal} from "@clr/angular";

@Component({
  selector: 'app-play-audio-dialog',
  templateUrl: './play-audio-dialog.component.html',
  styleUrls: ['./play-audio-dialog.component.scss']
})
export class PlayAudioDialogComponent implements OnInit {

    @ViewChild("modal")
    modal: ClrModal;

    @ViewChild("audio")
    audio: ElementRef<HTMLAudioElement>;

    audioSrc: string;

    filename: string;

    constructor() { }

    ngOnInit() {
    }

    open(src: string, filename) {
        this.audioSrc = src;
        this.filename = filename;
        this.audio.nativeElement.load();
        this.modal.open();
    }

    close() {
        this.modal.close();
    }

}
