import {
    HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaderResponse, HttpInterceptor, HttpProgressEvent, HttpRequest,
    HttpResponse, HttpSentEvent, HttpUserEvent
} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {AuthService} from "./auth.service";
import {Router} from "@angular/router";

import {throwError as observableThrowError,  Observable ,  BehaviorSubject } from 'rxjs';
import {take, filter, catchError, switchMap, finalize} from 'rxjs/operators';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    private isRefreshingToken: Boolean = false;

    private waitSubject: BehaviorSubject<string> = BehaviorSubject.create();

    intercept(req: HttpRequest<any>,
              next: HttpHandler): Observable<HttpEvent<any>> {

        let authToken = localStorage.getItem("authToken");

        if (authToken) {
            const cloned = req.clone({
                headers: req.headers.set("Authorization",
                    "Bearer " + authToken)
            });

            return next.handle(cloned).pipe(
                catchError(err => {
                    if (err instanceof HttpErrorResponse) {
                        switch(err.status) {
                            case 401:
                                localStorage.removeItem("authToken");
                                return this.handleTokenRefresh(req, next);
                            default:
                                return observableThrowError(err);
                        }
                    } else {
                        return observableThrowError(err);
                    }
                }));
        } else {
            return next.handle(req);
        }
    }

    constructor(private router: Router, private authService: AuthService) {
    }

    private addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
        return req.clone({ setHeaders: { Authorization: 'Bearer' + token}})
    }

    private handleTokenRefresh(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if(!this.isRefreshingToken) {
            this.isRefreshingToken = true;

            this.waitSubject.next(null);

            return this.authService.refreshToken().pipe(
                switchMap(value => {
                    let token = this.authService.getAccessToken();

                    if(token != null) {
                        this.waitSubject.next(token);
                        return next.handle(this.addToken(req, token))
                    } else {
                        return this.logoutUser();
                    }
                }),
                catchError(error => {
                    return this.logoutUser()
                }),
                finalize(() => {
                    this.isRefreshingToken = false
                }));
        } else {
            return this.waitSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(token => {
                    return next.handle(this.addToken(req, token))
                })
            );
        }
    }

    private logoutUser() {
        this.authService.clearSession();
        this.router.navigate(['login'])
        return observableThrowError("")
    }
}
