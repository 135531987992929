import {Component, OnInit, ViewChild} from '@angular/core';
import {ContentComponent} from "../../../components/content/content.component";
import {Station} from "../../../model/station";
import {AddLanguageComponent} from "../../common/add-language/add-language.component";
import {FormGroup} from "@angular/forms";

@Component({
    selector: 'app-station-docs',
    templateUrl: './station-docs.component.html',
    styleUrls: ['./station-docs.component.scss'],
    host: {'class': 'item-content'}
})
export class StationDocsComponent implements OnInit {

    @ViewChild(ContentComponent)
    content: ContentComponent<Station>;

    @ViewChild(AddLanguageComponent)
    addLanguageModal: AddLanguageComponent;

    form: FormGroup;

    constructor() { }

    ngOnInit() {
    }

}
