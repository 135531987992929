import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from '@angular/platform-browser';
import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ClarityModule} from '@clr/angular';
import { AppComponent } from './app.component';
import { ROUTING } from "./app.routing";
import {LayoutComponent} from "./layout/layout.component";
import {DashboardComponent} from "./dashboard/dashboard.component";
import  {HeaderComponent } from "./header/header.component";
import { GeneralComponent } from './general/general.component';
import { RadioComponent } from './radio/radio.component';
import { UserListComponent } from './user-list/user-list.component';
import { MemberListComponent } from './general/member-list/member-list.component';
import { StationListComponent } from './general/station-list/station-list.component';
import { MemberDetailsComponent } from './general/member-details/member-details.component';
import { LoginComponent } from './login/login.component';
import { ChannelListComponent } from './radio/channel-list/channel-list.component';
import { ImageUploadComponent } from './commons/image-upload/image-upload.component';
import {AuthService} from "./services/auth/auth.service";
import {AuthGuard} from "./services/auth/auth.guard";
import {AuthInterceptor} from "./services/auth/auth-interceptor";
import {StationInterceptor} from "./services/auth/station-interceptor";
import { OrgaDetailsComponent } from './ui/orga/orga-details/orga-details.component';
import { AddMemberComponent } from './general/add-member/add-member.component';
import {AdminService} from "./services/admin.service";
import { LowercaseDirective } from './directives/lowercase.directive';
import { AddStationComponent } from './general/add-station/add-station.component';
import { StationDetailsComponent } from './general/station-details/station-details.component';
import {RadioService} from "./services/radio.service";
import { ChannelDetailsComponent } from './radio/channel-details/channel-details.component';
import { ShowListComponent } from './radio/show-list/show-list.component';
import { ShowDetailsComponent } from './radio/show-details/show-details.component';
import { ProfileComponent } from './profile/profile.component';
import { CreateScheduleComponent } from './radio/create-schedule/create-schedule.component';
import { AddSlotComponent } from './radio/add-slot/add-slot.component';
import {ScheduleService} from "./services/schedule.service";
import { TimeValueDirective } from './directives/time-value.directive';
import { SideBarComponent } from './side-bar/side-bar.component';
import {ScheduleComponent, ScheduleCustomDirective} from './radio/schedule/schedule.component';
import { WeekTimeComponent } from './components/week-time/week-time.component';
import { WeekTimeRangeComponent } from './components/week-time-range/week-time-range.component';
import { EditScheduleComponent } from './radio/edit-schedule/edit-schedule.component';
import { DeleteDialogComponent } from './components/delete-dialog/delete-dialog.component';
import { AddStaticStreamComponent } from './radio/add-static-stream/add-static-stream.component';
import {
    ContentHeaderButton,
    ContentHeaderComponent,
    ContentHeaderFilter
} from './components/content-header/content-header.component';
import {
    ContentFilter, ContentFilterCheckbox, ContentFilterSelect,
    ContentItemRow,
    ContentListComponent, ContentTableHeader
} from './components/content-list/content-list.component';
import { ContentComponent } from './components/content/content.component';
import { ChannelItemComponent } from './radio/channel-item/channel-item.component';
import { StationItemComponent } from './general/station-item/station-item.component';
import { MemberItemComponent } from './general/member-item/member-item.component';
import {ChannelService} from "./services/channel.service";
import { ColorPickerModule } from 'ngx-color-picker';
import {ShowService} from "./services/show.service";
import { ShowItemComponent } from './radio/show-item/show-item.component';
import { AddScheduleComponent } from './radio/add-schedule/add-schedule.component';
import { EditStreamsComponent } from './radio/edit-streams/edit-streams.component';
import { StationApiComponent } from './general/station-api/station-api.component';
import { AddApiComponent } from './general/add-api/add-api.component';
import { MultipleImageUploadComponent } from './commons/multiple-image-upload/multiple-image-upload.component';
import {PostService} from "./services/post.service";
import { RadioInfoComponent } from './ui/radio/radio-info/radio-info.component';
import { OrgaInfoComponent } from './ui/orga/orga-info/orga-info.component';
import { ContentFilterComponent } from './components/content-filter/content-filter.component';
import { RadioGroupsComponent } from './ui/radio/radio-groups/radio-groups.component';
import { RadioSectionsComponent } from './ui/radio/radio-sections/radio-sections.component';
import {SortablejsModule} from "angular-sortablejs";
import { SelectChannelComponent } from './ui/radio/select-channel/select-channel.component';
import { AddSectionComponent } from './ui/radio/add-section/add-section.component';
import { AddRoleComponent } from './general/add-role/add-role.component';
import { SetPasswordComponent } from './general/set-password/set-password.component';
import { EditSectionComponent } from './ui/radio/edit-section/edit-section.component';
import {UserService} from "./services/user.service";
import { AddInputModalComponent } from './ui/radio/add-input-modal/add-input-modal.component';
import { CreateTrackComponent } from './ui/disco/create-track/create-track.component';
import { AddArtistComponent } from './ui/disco/add-artist/add-artist.component';
import {
    ContentFieldDirective,
    ContentItemBodyDirective, ContentItemNewDirective,
    ContentItemsComponent, ContentItemTitleDirective,

} from './components/content-items/content-items.component';
import {QuillModule} from "ngx-quill";
import { TrackListComponent } from './ui/disco/track-list/track-list.component';
import { TrackDetailsComponent } from './ui/disco/track-details/track-details.component';
import {TrackItemComponent} from "./ui/disco/track-item/track-item.component";
import { TrackWarningListComponent } from './ui/disco/track-warning-list/track-warning-list.component';
import { TrackReportListComponent } from './ui/disco/track-report-list/track-report-list.component';
import { AddMetatagComponent } from './ui/disco/add-metatag/add-metatag.component';
import { AddReleaseComponent } from './ui/disco/add-release/add-release.component';
import {ContentTab, ContentTabsComponent} from './components/content-tabs/content-tabs.component';
import { TracksComponent } from './ui/disco/tracks/tracks.component';
import { PostsComponent } from './ui/publisher/posts/posts.component';
import { ChannelsComponent } from './radio/channels/channels.component';
import { StationsComponent } from './ui/orga/stations/stations.component';
import { MembersComponent } from './ui/orga/members/members.component';
import { ShowsComponent } from './ui/radio/shows/shows.component';
import { PostItemComponent } from './ui/publisher/post-item/post-item.component';
import {CreatePostComponent} from "./ui/publisher/create-post/create-post.component";
import {PostDetailsComponent} from "./ui/publisher/post-details/post-details.component";
import {FeedListComponent} from "./ui/publisher/feed-list/feed-list.component";
import {FeedDetailsComponent} from "./ui/publisher/feed-details/feed-details.component";
import { AddImageComponent } from './ui/common/add-image/add-image.component';
import { UsersComponent } from './ui/social/users/users.component';
import {PostsListComponent} from "./ui/publisher/posts-list/posts-list.component";
import {AddUserComponent} from "./ui/social/add-user/add-user.component";
import {EditUserComponent} from "./ui/social/edit-user/edit-user.component";
import {UserItemComponent} from "./ui/social/user-item/user-item.component";
import { UserDetailsComponent } from './ui/social/user-details/user-details.component';
import {NgxChartsModule} from "@swimlane/ngx-charts";
import { MemberRolesComponent } from './ui/orga/member-roles/member-roles.component';
import { AddLanguageComponent } from './ui/common/add-language/add-language.component';
import { ToggleContainerComponent } from './ui/common/forms/toggle-container/toggle-container.component';
import { LangSelectComponent } from './ui/common/lang-select/lang-select.component';
import { StationDocsComponent } from './ui/orga/station-docs/station-docs.component';
import {LocalizedDirective, LocalizedFieldDirective} from './directives/localized.directive';
import { FieldDirective } from './directives/field.directive';
import { FormDirective } from './directives/form.directive';
import {StationService} from "./services/station/station.service";
import {ScheduleListComponent} from "./ui/radio/schedule-list/schedule-list.component";
import {StationDocumentComponent} from "./ui/orga/station-document/station-document.component";
import {StationTosComponent} from "./ui/orga/station-tos/station-tos.component";
import { StationEmailSettingsComponent } from './ui/orga/station-email-settings/station-email-settings.component';
import { StationEmailTemplateComponent } from './ui/orga/station-email-template/station-email-template.component';
import { AddPostComponent } from './ui/publisher/add-post/add-post.component';
import { AddContentComponent } from './ui/common/add-content/add-content.component';
import { SingleImageUploadComponent } from './commons/single-image-upload/single-image-upload.component';
import { SafePipe } from './pipes/safe.pipe';
import { ResolveImagePipe } from './pipes/resolve-image.pipe';
import { CreateMemberComponent } from './ui/orga/create-member/create-member.component';
import { TextContentComponent } from './ui/common/text-content/text-content.component';
import {CreateChannelComponent} from "./ui/radio/create-channel/create-channel.component";
import { CreateShowComponent } from './ui/radio/create-show/create-show.component';
import { ProgramComponent } from './ui/radio/program/program.component';
import { SelectScheduleComponent } from './ui/radio/select-schedule/select-schedule.component';
import {NgSelectModule} from "@ng-select/ng-select";
import { SchedulesComponent } from './ui/radio/schedules/schedules.component';
import {EditSlotComponent} from "./ui/radio/edit-slot/edit-slot.component";
import { CreateStationComponent } from './ui/orga/create-station/create-station.component';
import { StationAppListComponent } from './ui/orga/station-app-list/station-app-list.component';
import { AddAppComponent } from './ui/orga/add-app/add-app.component';
import { CampaignListComponent } from './ui/publisher/campaign-list/campaign-list.component';
import {CampaignService} from "./services/campaign.service";
import { CampaignsComponent } from './ui/publisher/campaigns/campaigns.component';
import { CampaignItemComponent } from './ui/publisher/campaign-item/campaign-item.component';
import { CampaignDetailsComponent } from './ui/publisher/campaign-details/campaign-details.component';
import { CreateCampaignComponent } from './ui/publisher/create-campaign/create-campaign.component';
import { SimpleCampaignEditorComponent } from './ui/publisher/simple-campaign-editor/simple-campaign-editor.component';
import { AddSlideComponent } from './ui/publisher/add-slide/add-slide.component';
import { InfoSlideEditorComponent } from './ui/publisher/slide-editors/info-slide-editor/info-slide-editor.component';
import { MultiChoiceEditorComponent } from './ui/publisher/slide-editors/multi-choice-editor/multi-choice-editor.component';
import { TextSlideEditorComponent } from './ui/publisher/slide-editors/text-slide-editor/text-slide-editor.component';
import { ImageSlideEditorComponent } from './ui/publisher/slide-editors/image-slide-editor/image-slide-editor.component';
import { AudioSlideEditorComponent } from './ui/publisher/slide-editors/audio-slide-editor/audio-slide-editor.component';
import { SelectCampaignComponent } from './ui/publisher/select-campaign/select-campaign.component';
import { CampaignStatisticsComponent } from './ui/publisher/campaign-results/campaign-results.component';
import { NumberCardComponent } from './components/charts/number-card/number-card.component';
import { CampaignSubmissionListComponent } from './ui/publisher/campaign-submission-list/campaign-submission-list.component';
import { PromoteComponent } from './ui/common/promote/promote.component';
import { UserFilterComponent } from './components/user-filter/user-filter.component';
import {NgMultiSelectDropDownModule} from "ng-multiselect-dropdown";
import { UserFilterDialogComponent } from './ui/social/user-filter-dialog/user-filter-dialog.component';
import { NumericRangeComponent } from './components/numeric-range/numeric-range.component';
import { PromoteWizardComponent } from './components/wizards/promote-wizard/promote-wizard.component';
import { DebugMessageDialogComponent } from './ui/social/debug-message-dialog/debug-message-dialog.component';
import { LangPipe } from './pipes/lang.pipe';
import { ShowImageDialogComponent } from './components/dialogs/show-image-dialog/show-image-dialog.component';
import { PlayAudioDialogComponent } from './components/dialogs/play-audio-dialog/play-audio-dialog.component';
import { CampaignSubmissionDetailsComponent } from './ui/publisher/campaign-submission-details/campaign-submission-details.component';
import { CreateFeedDialogComponent } from './ui/publisher/create-feed-dialog/create-feed-dialog.component';
import { PieChartComponent } from './components/charts/pie-chart/pie-chart.component';
import { BarChartComponent } from './components/charts/bar-chart/bar-chart.component';
import { LineChartComponent } from './components/charts/line-chart/line-chart.component';
import { UserStatisticsComponent } from './ui/social/user-statistics/user-statistics.component';
import { DialogComponent } from './components/dialogs/dialog/dialog.component';
import { ShowTextDialogComponent } from './components/dialogs/show-text-dialog/show-text-dialog.component';
import { ChannelGroupListComponent } from './radio/channel-group-list/channel-group-list.component';
import { EditChannelGroupComponent } from './radio/edit-channel-group/edit-channel-group.component';
import { CreateChannelGroupDialogComponent } from './radio/create-channel-group-dialog/create-channel-group-dialog.component';
import { SelectDialogComponent } from './components/select-dialog/select-dialog.component';
import { SimpleDialogComponent } from './components/dialogs/simple-dialog/simple-dialog.component';
import { HomeEditorComponent } from './ui/publisher/home-editor/home-editor.component';
import { FilterCampaignSubmissionDialogComponent } from './ui/publisher/filter-campaign-submission-dialog/filter-campaign-submission-dialog.component';
import {ClipboardModule} from "ngx-clipboard";
import { ChannelGroupItemComponent } from './radio/channel-group-item/channel-group-item.component';
import { EditChannelGroupListComponent } from './radio/edit-channel-group-list/edit-channel-group-list.component';

@NgModule({
    declarations: [
        AppComponent,
        LayoutComponent,
        HeaderComponent,
        DashboardComponent,
        GeneralComponent,
        RadioComponent,
        UserListComponent,
        MemberListComponent,
        StationListComponent,
        MemberDetailsComponent,
        LoginComponent,
        ChannelListComponent,
        CreateChannelComponent,
        ImageUploadComponent,
        OrgaDetailsComponent,
        AddMemberComponent,
        LowercaseDirective,
        AddStationComponent,
        StationDetailsComponent,
        ChannelDetailsComponent,
        ScheduleListComponent,
        ShowListComponent,
        ShowDetailsComponent,
        ProfileComponent,
        CreateScheduleComponent,
        AddSlotComponent,
        TimeValueDirective,
        EditSlotComponent,
        SideBarComponent,
        ScheduleComponent,
        WeekTimeComponent,
        WeekTimeRangeComponent,
        EditScheduleComponent,
        DeleteDialogComponent,
        AddStaticStreamComponent,
        ContentHeaderComponent,
        ContentHeaderButton,
        ContentHeaderFilter,
        ContentListComponent,
        ContentComponent,
        ChannelItemComponent,
        StationItemComponent,
        MemberItemComponent,
        ShowItemComponent,
        AddScheduleComponent,
        EditStreamsComponent,
        StationApiComponent,
        AddApiComponent,
        PostsListComponent,
        MultipleImageUploadComponent,
        RadioInfoComponent,
        OrgaInfoComponent,
        FeedListComponent,
        FeedDetailsComponent,
        ContentFilterComponent,
        AddUserComponent,
        RadioGroupsComponent,
        RadioSectionsComponent,
        SelectChannelComponent,
        AddSectionComponent,
        AddRoleComponent,
        SetPasswordComponent,
        EditSectionComponent,
        EditUserComponent,
        UserItemComponent,
        AddInputModalComponent,
        CreateTrackComponent,
        AddArtistComponent,
        ScheduleCustomDirective,
        ContentItemsComponent,
        ContentItemTitleDirective,
        ContentItemBodyDirective,
        ContentItemNewDirective,
        ContentFieldDirective,
        ContentItemRow,
        ContentTableHeader,
        ContentFilter,
        ContentTab,
        ContentFilterSelect,
        ContentFilterCheckbox,
        TrackListComponent,
        TrackItemComponent,
        TrackDetailsComponent,
        TrackWarningListComponent,
        TrackReportListComponent,
        AddMetatagComponent,
        AddReleaseComponent,
        ContentTabsComponent,
        TracksComponent,
        PostsComponent,
        ChannelsComponent,
        StationsComponent,
        MembersComponent,
        ShowsComponent,
        CreatePostComponent,
        PostDetailsComponent,
        PostItemComponent,
        AddImageComponent,
        UsersComponent,
        UserDetailsComponent,
        MemberRolesComponent,
        AddLanguageComponent,
        ToggleContainerComponent,
        LangSelectComponent,
        LocalizedFieldDirective,
        StationDocsComponent,
        StationTosComponent,
        LocalizedDirective,
        FieldDirective,
        FormDirective,
        StationDocumentComponent,
        StationEmailSettingsComponent,
        StationEmailTemplateComponent,
        AddPostComponent,
        AddContentComponent,
        SingleImageUploadComponent,
        SafePipe,
        ResolveImagePipe,
        CreateMemberComponent,
        TextContentComponent,
        CreateShowComponent,
        ProgramComponent,
        SelectScheduleComponent,
        SchedulesComponent,
        CreateStationComponent,
        StationAppListComponent,
        AddAppComponent,
        CampaignListComponent,
        CampaignsComponent,
        CampaignItemComponent,
        CampaignDetailsComponent,
        CreateCampaignComponent,
        SimpleCampaignEditorComponent,
        AddSlideComponent,
        InfoSlideEditorComponent,
        MultiChoiceEditorComponent,
        TextSlideEditorComponent,
        ImageSlideEditorComponent,
        AudioSlideEditorComponent,
        SelectCampaignComponent,
        CampaignStatisticsComponent,
        NumberCardComponent,
        CampaignSubmissionListComponent,
        PromoteComponent,
        UserFilterComponent,
        UserFilterDialogComponent,
        NumericRangeComponent,
        PromoteWizardComponent,
        DebugMessageDialogComponent,
        LangPipe,
        ShowImageDialogComponent,
        PlayAudioDialogComponent,
        CampaignSubmissionDetailsComponent,
        CreateFeedDialogComponent,
        PieChartComponent,
        BarChartComponent,
        LineChartComponent,
        UserStatisticsComponent,
        DialogComponent,
        ShowTextDialogComponent,
        ChannelGroupListComponent,
        EditChannelGroupComponent,
        CreateChannelGroupDialogComponent,
        SelectDialogComponent,
        SimpleDialogComponent,
        HomeEditorComponent,
        FilterCampaignSubmissionDialogComponent,
        ChannelGroupItemComponent,
        EditChannelGroupListComponent,
    ],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        ReactiveFormsModule,
        HttpClientModule,
        FormsModule,
        ClarityModule,
        ColorPickerModule,
        QuillModule,
        NgxChartsModule,
        NgSelectModule,
        NgMultiSelectDropDownModule.forRoot(),
        SortablejsModule.forRoot({ animation: 150}),
        ClipboardModule,
        ROUTING
    ],
    providers: [
        AuthService,
        AuthGuard,
        AdminService,
        UserService,
        RadioService,
        StationService,
        ScheduleService,
        ChannelService,
        ShowService,
        PostService,
        CampaignService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: StationInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent],
    entryComponents: [
        InfoSlideEditorComponent,
        MultiChoiceEditorComponent,
        TextSlideEditorComponent,
        AudioSlideEditorComponent,
        ImageSlideEditorComponent
    ],
    schemas:      [ NO_ERRORS_SCHEMA ]
})
export class AppModule {
}
