import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Subject} from "rxjs";
import {ClrModal} from "@clr/angular";

@Component({
  selector: 'app-simple-dialog',
  templateUrl: './simple-dialog.component.html',
  styleUrls: ['./simple-dialog.component.scss']
})
export class SimpleDialogComponent implements OnInit {

    @ViewChild("modal") modal: ClrModal;

    @Input()
    title: string;

    @Input()
    text: string;

    @Input()
    okButton: string = "OK";

    @Input()
    cancelButton: string = "Cancel";

    subject: Subject<boolean>;

    constructor() { }

    ngOnInit() {
    }

    public open(subject: Subject<boolean>) {
        this.subject = subject;
        this.modal.open();
    }

    public close(value: boolean) {
        if(this.subject)
            this.subject.next(value);
        this.modal.close();
    }
}
