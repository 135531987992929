import {Component, OnInit, ViewChild} from '@angular/core';
import {ContentComponent} from "../../../components/content/content.component";
import {CreatePost} from "../../../model/post";
import {Campaign, CreateCampaign} from "../../../model/campaign";
import {SingleImageUploadComponent} from "../../../commons/single-image-upload/single-image-upload.component";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PostService} from "../../../services/post.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Observable} from "rxjs";
import {CampaignService} from "../../../services/campaign.service";

@Component({
    selector: 'app-create-campaign',
    templateUrl: './create-campaign.component.html',
    styleUrls: ['./create-campaign.component.scss'],
    host: {'class': 'item-content'}
})
export class CreateCampaignComponent implements OnInit {

    @ViewChild(ContentComponent)
    content: ContentComponent<CreateCampaign>;

    @ViewChild("coverImage")
    coverImage: SingleImageUploadComponent;

    form: FormGroup;

    readonly uploadImageUrl = "/api/uploads/campaign-image";

    item = new CreateCampaign();


    textModule = { toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'indent': '-1'}, { 'indent': '+1' }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'font': [] }],
            [{ 'align': [] }],
            ['link']
        ]};

    constructor(private fb: FormBuilder, private campaignService: CampaignService, public router: Router,
                private route: ActivatedRoute) {
        this.form = this.fb.group({
            title: ['', [Validators.required, Validators.minLength(1)]],
            name: ['', [Validators.required, Validators.minLength(1)]],
            summary: [''],
            requiresPhone: [false],
            requiresEmail: [false],
            startDate: [null],
            expireDate: [null],
            tos: [{}]
        })
    }

    ngOnInit() {
    }

    validate(campaign: Campaign) {
        return true;
    }

    createCampaign(): Observable<any> {
        return this.campaignService.createCampaign(this.item)
            .map(item => item.campaignId);
    }

    onCreated(id: string) {
        this.router.navigate(['..', 'edit', id], {relativeTo: this.route})
    }
}
