import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Feed} from "../../../model/feed";
import {PostService} from "../../../services/post.service";
import {DeleteDialogComponent} from "../../../components/delete-dialog/delete-dialog.component";
import {Post} from "../../../model/post";
import {ContentListComponent} from "../../../components/content-list/content-list.component";
import {Observable} from "rxjs";
import {PagedResult} from "../../../services/admin.service";
import {ChannelService} from "../../../services/channel.service";
import {StationService} from "../../../services/station/station.service";
import {AddPostComponent} from "../add-post/add-post.component";

@Component({
  selector: 'app-posts-list',
  templateUrl: './posts-list.component.html',
  styleUrls: ['./posts-list.component.scss']
})
export class PostsListComponent implements OnInit {

    @ViewChild("deleteDialog") deleteDialog: DeleteDialogComponent;

    @ViewChild(ContentListComponent)
    content: ContentListComponent<Post>;

    feeds: {value: string, name: string, id: string}[];
    
    status: {value: string, name: string}[] = [
        {value: "all", name: "All"},
        {value: "published", name: "Published"},
        {value: "notPublished", name: "Not Published"},
        {value: "disabled", name: "Disabled"},
    ];

    sortOrder: {value: string, name: string}[] = [
        {value: "asc", name: "Ascending"},
        {value: "desc", name: "Descending"},       
    ]

    query: string = null;

    selectedFeed: string = "all";

    selectedType: string = "all";

    selectedSortOrder: string = "desc";

    selectedStatus: string = "all";


    activeOnly: boolean = true;

    notPublished: boolean = true;

    constructor(private router: Router, private route: ActivatedRoute, private postsService: PostService,
                public stationService: StationService) {    
    }

    ngOnInit() {
        this.postsService.getFeeds()
            .subscribe(feeds => {
                this.feeds = feeds.map(value => {
                    return {value: value.name, name: value.displayName, id: value.feedId};
                });
                this.feeds.unshift({value: "all", name: "All", id: ""})
            });
    }

    public addPost() {
        this.router.navigate(["../create-post"], {relativeTo: this.route});
    }

    public search(query: string) {
        this.query = query;
        this.content.refresh();
    }

    public openPost(post: Post) {
        this.router.navigate(["../edit/", post.shortId], {relativeTo: this.route});
    }

    getPosts(pageSize: number, offset: string): Observable<PagedResult<Post>> {
        var descending: boolean = null;
        var disabled: boolean = null;
        var published: boolean = null;

        if(this.selectedSortOrder == "asc") 
            descending = false;

        if (this.selectedStatus == "published") {
            published = true;
        } else if (this.selectedStatus == "notPublished") {
            published = false;
        } else if (this.selectedStatus == "disabled") {
            disabled = true;
        }

        return this.postsService.getPosts(pageSize, offset, this.query, this.selectedFeed, disabled, published, descending)

    }

    public deletePost(post: Post) {
        this.deleteDialog.open(post.postId, null, () => {
            this.postsService.deletePost(post.postId)
                .subscribe(value => {
                    this.content.removeItems(p => p.postId == post.postId)
                });
        });
    }

    public getFeedName(feedId: string): string {
        return this.feeds.find(el => el.id == feedId).name;
    }
}
