import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {ClrModal} from "@clr/angular";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ChannelSection} from "../../../model/channel-section";
import {UUID} from "angular2-uuid";
import {LangSelectComponent} from "../../common/lang-select/lang-select.component";
import {StationService} from "../../../services/station/station.service";

@Component({
  selector: 'app-edit-section',
  templateUrl: './edit-section.component.html',
  styleUrls: ['./edit-section.component.scss']
})
export class EditSectionComponent implements OnInit {

    @ViewChild("modal")
    modal: ClrModal;

    @ViewChild(LangSelectComponent)
    langSelect: LangSelectComponent;

    section: ChannelSection;

    backgroundImageUrl: string = null;

    imageUploadUrl = "/api/upload-image";

    constructor(private stationService: StationService) {
    }

    @Output("onUpdate")
    onUpdate: EventEmitter<any> = new EventEmitter<ChannelSection>();

    @Output("onError")
    onError: EventEmitter<any> = new EventEmitter<any>();

    ngOnInit() {
    }

    validate(): boolean {
        return true;
    }

    open(section: ChannelSection) {
        this.section = section;
        this.modal.open();
    }

    finish() {
        if(this.validate()) {
            this.onUpdate.emit(this.section);
            let section = null;
            this.close();
        }
    }

    public close() {
        this.modal.close();
    }

    uploadImageError(error) {
        // TODO
    }

    imageUploadSucceeded(event) {
        // TODO
    }
}
