import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ClrLoadingState, ClrModal} from "@clr/angular";
import {Observable, Subscription} from "rxjs";
import {animate, state, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'rs-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
    animations: [
        trigger('overlayVisible', [
            state('true', style({
                opacity: 1,
                display: 'block'
            })),
            state('false', style({
                opacity: 0,
                display: 'none'
            })),
            transition("true <=> false", [
                animate("300ms")
            ])
        ]),
    ]
})
export class DialogComponent implements OnInit {

    @ViewChild("modal") modal: ClrModal;

    submitButtonState: ClrLoadingState = ClrLoadingState.DEFAULT;

    @Input()
    title: string;

    @Input()
    okButton: string = "OK";

    isLoading: boolean = false;

    isSubmitting: boolean = false;

    overlayVisible: boolean = false;

    @Input() instance: any;

    @Input() size: string;

    @Input() load: () => Observable<any>;

    @Input() submit: () => Observable<any>;

    @Input() validate: () => boolean;

    @Input() failed: (error: any) => void;

    @Output() onSubmitted = new EventEmitter();

    @Output() onLoaded = new EventEmitter();

    loadSubscription: Subscription;

    submitSubscription: Subscription;

    constructor() { }

    ngOnInit() {
    }

    open() {
        this.overlayVisible = false;

        if(this.submitSubscription != null)
            this.submitSubscription.unsubscribe();

        if(this.loadSubscription != null)
            this.loadSubscription.unsubscribe();

        this.modal.open();

        if(this.load != null) {
            this.isLoading = true;

            this.loadSubscription = this.load.apply(this.instance)
                .subscribe(res => {
                    this.isLoading = false;
                    this.onLoaded.emit(res);
                }, error => {
                    console.log(error);
                    this.isLoading = false;
                    if(this.failed)
                        this.failed(error);
                });
        }
    }

    close() {
        if(this.submitSubscription != null)
            this.submitSubscription.unsubscribe();

        if(this.loadSubscription != null)
            this.loadSubscription.unsubscribe();

        this.modal.close();
    }

    finish() {
        if(this.validate != null) {
            if(!this.validate.apply(this.instance)) {
                return;
            }
        }

        if(this.submit != null) {
            this.isSubmitting = true;
            this.overlayVisible = true;

            this.submitButtonState = ClrLoadingState.LOADING;

            this.submitSubscription = this.submit.apply(this.instance)
                .subscribe(res => {
                    this.submitButtonState = ClrLoadingState.SUCCESS;
                    this.isSubmitting = false;
                    this.onSubmitted.emit(res);
                    this.close();
                }, error => {
                    if(this.failed)
                        this.failed(error);

                    this.isSubmitting = false;
                    this.close();
                });
        }
    }

}
