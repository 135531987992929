import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {ClrModal} from "@clr/angular";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ChannelSection} from "../../../model/channel-section";
import {UUID} from "angular2-uuid";
import {StationService} from "../../../services/station/station.service";
import {LangSelectComponent} from "../../common/lang-select/lang-select.component";

export interface CreateSection {
    title: string;
    type: string;
    rows: number;
}

@Component({
  selector: 'app-add-section',
  templateUrl: './add-section.component.html',
  styleUrls: ['./add-section.component.scss']
})
export class AddSectionComponent implements OnInit {

    @ViewChild("modal")
    modal: ClrModal;

    @ViewChild(LangSelectComponent)
    langSelect: LangSelectComponent;

    section: ChannelSection;

    backgroundImageUrl: string = null;

    imageUploadUrl = "/api/upload-image";

    constructor(private stationService: StationService) {
    }

    @Output("onCreated")
    onCreated: EventEmitter<ChannelSection> = new EventEmitter<ChannelSection>();

    @Output("onError")
    onError: EventEmitter<any> = new EventEmitter<any>();

    ngOnInit() {
    }

    open() {
        this.section = new ChannelSection();
        this.modal.open();
    }

    public close() {
        this.reset();
        this.modal.close();
    }

    public validate(): boolean {
        return true;
    }

    public create() {
        if(this.validate()) {
            this.onCreated.emit(this.section);
            this.close();
        }
    }

    uploadImageError(event) {
        alert(event);
    }

    imageUploadSucceeded(url) {
        this.backgroundImageUrl = url;
    }

    reset() {
        this.section = new ChannelSection();
    }
}
