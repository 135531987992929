import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {PagedResult} from "./admin.service";
import {Observable} from "rxjs/Observable";
import {environment} from "../../environments/environment";


export interface Stream {
    id: string,
    name: string,
    source: string
}

@Injectable()
export class RadioService {

    constructor(private http: HttpClient) { }



    // Streams

    public createStaticStream(channelId: string, name: string, source: string): Observable<any> {
        return this.http.post(environment.apiEndpoint +"/api/streams/static", {
            channelId: channelId, name: name, source: source, encoding: "AAC", bitrate: 44100
        });
    }

    public getStaticStreams(channelId: string): Observable<Stream[]> {
        return this.http.get<Stream[]>(environment.apiEndpoint +"/api/channels/" + channelId + "/streams/static");
    }

    public deleteStaticStream(streamId: string): Observable<any> {
        return this.http.delete(environment.apiEndpoint +"/api/streams/static/" + streamId);
    }
}
