import {Component, OnInit, ViewChild} from '@angular/core';
import {ContentComponent, ContentHost} from "../../../components/content/content.component";
import {SingleImageUploadComponent} from "../../../commons/single-image-upload/single-image-upload.component";
import {Campaign} from "../../../model/campaign";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {CampaignService} from "../../../services/campaign.service";
import {CreateImage} from "../../../model/image";
import {Observable} from "rxjs";
import {Callback} from "../../../utility/callback";
import {PromoteWizardComponent} from "../../../components/wizards/promote-wizard/promote-wizard.component";

@Component({
    selector: 'app-campaign-details',
    templateUrl: './campaign-details.component.html',
    styleUrls: ['./campaign-details.component.scss'],
    host: {'class': 'item-content'}
})
export class CampaignDetailsComponent extends ContentHost implements OnInit {

    @ViewChild(ContentComponent)
    content: ContentComponent<Campaign>;

    @ViewChild("coverImage")
    coverImage: SingleImageUploadComponent;

    @ViewChild("promoteWizard")
    promoteWizard: PromoteWizardComponent;


    form: FormGroup;

    id: string;

    readonly imageUploadUrl = "/api/uploads/campaign-cover";

    processImageCallback: Callback<CreateImage, string> = new Callback(this, this.processImage);

    textModule = { toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'indent': '-1'}, { 'indent': '+1' }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'font': [] }],
            [{ 'align': [] }],
            ['link']
        ]};

    constructor(private fb: FormBuilder, private route: ActivatedRoute,
                private router: Router, private campaignService: CampaignService) {
        super();
        this.form = this.fb.group({
            name: [],
            title: ['', [Validators.required]],
            summary: [''],
            startDate: [null],
            expireDate: [null],
            emailRequired: [false],
            phoneRequired: [false],
            tos: ['']
        })
    }

    ngOnInit() {
        this.route.parent.params.subscribe(params => {
            this.id = params['id'];
        });
    }

    validate() {
        return true;
    }

    setCoverImage(value: any) {
        this.content.setDirty();

        if(value != null) {
            this.content.item.coverImageId = value.imageId;
        } else {
            this.content.item.coverImageId = null;
        }
    }

    getCampaign(): Observable<Campaign> {
        return this.campaignService.getCampaign(this.id)
    }

    updateCampaign(): Observable<any> {
        return this.campaignService.updateCampaign(this.content.item)
    }

    processImage(createImage: CreateImage): Observable<string> {
        return this.campaignService.processCoverImage(this.content.item.campaignId, createImage);
    }

    deleteCampaign(): Observable<any> {
        return this.campaignService.deleteCampaign(this.content.item.campaignId);
    }

    public enable() {
        this.campaignService.enableCampaign(this.id)
            .subscribe(res => {
                this.content.refresh();
            }, error => {
                this.content.showError("Failed to enable campaign");
            });
    }

    public promote() {
        this.promoteWizard.open("Campaign", this.content.item.campaignId);
    }

    public onCampaignDeleted() {

    }
}
