import {Component, OnInit, ViewChild} from '@angular/core';
import {ContentComponent} from "../../../components/content/content.component";
import {User} from "../../../model/user";
import {Observable} from "rxjs/Observable";
import {UserService} from "../../../services/user.service";
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {SetPasswordComponent} from "../../../general/set-password/set-password.component";

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {

    @ViewChild(ContentComponent)
    content: ContentComponent<User>;

    @ViewChild("setPasswordModal")
    setPasswordModal: SetPasswordComponent;

    form: FormGroup;

    userId: string;

    constructor(private route: ActivatedRoute, private fb: FormBuilder,
                private userService: UserService, private router: Router) {
        this.form = this.fb.group({
            name: [''],
            email: ['', [Validators.required]],
            enabled: [false]
        });
    }

    ngOnInit() {

        this.route.parent.params.subscribe(params => {
            this.userId = params['id'];
        });

        this.setPasswordModal.onPasswordChange.subscribe(params => {
            this.userService.setPassword(this.userId, params)
                .subscribe(value => {
                    // Password updated
                }, error => {
                    // Failed to update password
                    this.content.showError("Failed to set password")
                })

        })
    }

    onUserUpdated(item: User) {
        if(item == null)
            return;

        this.userId = item.userId;

        this.form.setValue({
            name: item.username ? item.username : "",
            email: item.email,
            enabled: !item.disabled
        })
    }

    getUser(): Observable<any> {
        return this.route.params
            .switchMap((params) => this.userService.getUser(params["id"]));

    }

    enabled(value: boolean) {
        this.userService.enableUser(this.userId, value)
            .subscribe();
    }

    deleteUser(): Observable<any> {
        return this.route.params
            .switchMap((params) => this.userService.deleteUser(params["id"]))
    }

    onUserDeleted() {
        this.router.navigate(['../'], {relativeTo: this.route});
    }

    updateUser(): Observable<any> {
        this.content.item.username = this.form.get("name").value;
        this.content.item.email = this.form.get("email").value;

        return this.userService.updateUser(this.content.item.userId, this.content.item);
    }

    setPassword() {
        this.setPasswordModal.open()
    }
}
