import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {AdminService, PagedResult} from "../../services/admin.service";
import {AddStationComponent} from "../add-station/add-station.component";
import {AuthService} from "../../services/auth/auth.service";
import {Router} from "@angular/router";
import {Station} from "../../model/station";
import {ContentListComponent} from "../../components/content-list/content-list.component";
import {Observable} from "rxjs/Observable";
import {DeleteDialogComponent} from "../../components/delete-dialog/delete-dialog.component";


@Component({
  selector: 'app-station-list',
  templateUrl: './station-list.component.html',
  styleUrls: ['./station-list.component.scss']
})
export class StationListComponent implements OnInit {

    @ViewChild("addStationModal") addStationModal: AddStationComponent;

    @ViewChild("deleteDialog") deleteDialog: DeleteDialogComponent;

    @ViewChild(ContentListComponent)
    content: ContentListComponent<Station>;

    query: string;

    constructor(private router: Router, private adminService: AdminService, private authService: AuthService) {
    }

    ngOnInit() {
        this.addStationModal.onStationCreated.subscribe((station) => {
            this.authService.refresh();
            this.content.refresh()
        });
    }

    addStation() {
        this.router.navigate(["/organisation/create-station"]);
    }

    openStation(station: Station) {
        this.router.navigate(["/organisation/stations/edit/" + station.name]);
    }

    deleteStation(station: Station)  {
        this.deleteDialog.open(station.stationId, station.name, () => {
            this.adminService.deleteStation(station.stationId)
                .subscribe(value => {
                    this.content.removeItems(e => e.stationId == station.stationId)
                });
        });
    }

    search(query) {
        this.query = query;
        this.content.refresh();
    }

    getStations(pageSize: number, offset: string): Observable<PagedResult<Station>> {
        return this.adminService.getStations(pageSize, offset, this.query);
    }
}
