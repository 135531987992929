import {Component, OnInit, ViewChild} from '@angular/core';
import {AddSlotComponent} from "../add-slot/add-slot.component";
import {ScheduleComponent, Slot} from "../schedule/schedule.component";
import {AfterViewInit} from "@angular/core/src/metadata/lifecycle_hooks";
import {WeekRange} from "../../utility/week-range";
import {ScheduleProvider} from "../schedule/schedule-provider";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ScheduleService} from "../../services/schedule.service";
import {RadioService} from "../../services/radio.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Channel} from "../../model/channel";
import {ChannelService} from "../../services/channel.service";
import {CreateScheduleSlot, Show} from "../../model/show";
import {ShowService} from "../../services/show.service";
import {ContentComponent} from "../../components/content/content.component";
import {Observable} from "rxjs/Observable";
import {StationService} from "../../services/station/station.service";


interface CreateSlot {
    showId: string;
    from: number;
    to: number;
}

interface CreateSchedule {
    slots: CreateSlot[],
    name: string;
}

@Component({
    selector: 'app-create-schedule',
    templateUrl: './create-schedule.component.html',
    styleUrls: ['./create-schedule.component.scss'],
    host: {'class': 'item-content'}
})
export class CreateScheduleComponent implements OnInit, AfterViewInit, ScheduleProvider {

    form: FormGroup;

    @ViewChild(ContentComponent)
    content: ContentComponent<CreateSchedule>;

    @ViewChild("addSlotModal") addSlotModal: AddSlotComponent;

    @ViewChild("scheduleComponent") scheduleComponent: ScheduleComponent;

    slots: Slot[] = [];

    selectedSlot : Slot;

    timeInterval: number = 30;

    name: string;

    constructor(private fb: FormBuilder, private route: ActivatedRoute, private router: Router, private showService: ShowService,
                private radioService: RadioService, private channelService: ChannelService, private scheduleService: ScheduleService,
                private stationService: StationService) {
    }

    ngOnInit() {
    }

    newSchedule() {
        this.content.item = { name: "", slots: []}
    }

    validate(): boolean {
        if(!this.name) {
            this.content.showError("Name required!");
            return false;
        } else if (this.slots.length < 1) {
            this.content.showError("No entries in schedule");
            return false;
        } else {
            return true;
        }
    }

    createSchedule(): Observable<any> {
        // Build slots
        let createSlots: CreateScheduleSlot[] = [];

        for(let slot of this.slots) {
            createSlots.push({
                from: slot.range.from.getSeconds(),
                to: slot.range.to.getSeconds(),
                showId: slot.data.showId
            });
        }

        return this.scheduleService.createSchedule(this.name, createSlots);
    }

    onScheduleCreated() {
        this.router.navigate(["../schedules"], {relativeTo: this.route});
    }

    ngAfterViewInit() {
        this.scheduleComponent.setProvider(this);


        setTimeout(() => {
            this.setTimeInterval(60);
            this.scheduleComponent.updateSlots();
        });
    }

    onFinishCreateSlot(params: any) {
        if(params == null) return;

        this.slots.push({
            id: null,
            range: params.range,
            data: { showId: params.show.showId },
            title: params.show.title,
            subTitle: "",
            selected: false,
            valid: true
        });

        this.scheduleComponent.updateSlots();
    }

    public setTimeInterval(minutes: number) {
        this.timeInterval = minutes;
        this.scheduleComponent.setInterval(null, 7, minutes);
    }

    /* Schedule Provider Implementation */

    public getSlots(): Slot[] {
        return this.slots;
    }

    public updateSlots() {
        this.scheduleComponent.updateSlots();
    }


    public checkRangeValid(range: WeekRange): boolean {
        return this.scheduleComponent.checkValid(range);
    }

    public unselectAll() {
        for(let slot of this.slots) {
            slot.selected = false;
        }
        this.selectedSlot = null;
    }

    editSlot (slot: Slot) {

    }

    public selectSlot(slot: Slot) {
        this.selectedSlot = slot;
    }

    public createSlot(range: WeekRange) {
        this.addSlotModal.open(this, range, null, null, null);
    }

    public deleteSlot(slot: Slot) {
        this.slots = this.slots.filter(s => s != slot);
        this.scheduleComponent.updateSlots();
    }
}
