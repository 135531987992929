import {Component, OnInit, ViewChild} from '@angular/core';
import {ContentComponent} from "../../../components/content/content.component";
import {User} from "../../../model/user";
import {SetPasswordComponent} from "../../../general/set-password/set-password.component";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {UserService} from "../../../services/user.service";
import {Observable, of} from "rxjs";
import {AuthService} from "../../../services/auth/auth.service";
import {DebugMessageDialogComponent} from "../debug-message-dialog/debug-message-dialog.component";

@Component({
    selector: 'app-user-details',
    templateUrl: './user-details.component.html',
    styleUrls: ['./user-details.component.scss'],
    host: {'class': 'item-content'}
})
export class UserDetailsComponent implements OnInit {

    @ViewChild(ContentComponent)
    content: ContentComponent<User>;

    @ViewChild("setPasswordModal")
    setPasswordModal: SetPasswordComponent;

    @ViewChild("debugMessageDialog")
    public debugMessageDialog: DebugMessageDialogComponent;

    form: FormGroup;

    userId: string;

    constructor(private route: ActivatedRoute,
                public authService: AuthService,
                private fb: FormBuilder,
                private userService: UserService, private router: Router) {
        this.form = this.fb.group({
            displayName: [''],
            username: [''],
            email: ['', [Validators.required]],
            enabled: [false],
            internal: [false]
        });
    }

    ngOnInit() {
        this.route.parent.params.subscribe(params => {
            this.userId = params['id'];
        });

        this.setPasswordModal.onPasswordChange.subscribe(params => {
            this.userService.setPassword(this.userId, params)
                .subscribe(value => {
                    // Password updated
                }, error => {
                    // Failed to update password
                    this.content.showError("Failed to set password")
                })

        })
    }

    onUserUpdated(item: User) {
        if(item == null)
            return;

        this.userId = item.userId;

        this.form.setValue({
            name: item.username ? item.username : "",
            email: item.email,
            enabled: !item.disabled,
            internal: item.internal
        })
    }

    getUser(): Observable<any> {
        return this.userService.getUser(this.userId);

    }

    enabled(value: boolean) {
        this.userService.enableUser(this.userId, value)
            .subscribe();
    }

    deleteUser(): Observable<any> {
        return this.route.params
            .switchMap((params) => this.userService.deleteUser(params["id"]))
    }

    onUserDeleted() {
        this.router.navigate(['../'], {relativeTo: this.route});
    }

    updateUser(): Observable<any> {
        //this.content.item.username = this.form.get("name").value;
        //this.content.item.email = this.form.get("email").value;
        return this.userService.updateUser(this.userId, {
            internal: this.form.get("internal").value
        });
    }

    setPassword() {
        this.setPasswordModal.open()
    }

    invalidateUser() {
        this.userService.invalidateUserEmail(this.userId)
            .subscribe();
    }

    sendDebugMessage() {

    }
}
