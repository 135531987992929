import { Injectable } from '@angular/core';
import {Channel, ChannelGroup, CreateChannel, StreamConfig, StreamStatus} from "../model/channel";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs/Observable";
import {PagedResult} from "./admin.service";
import * as moment from "moment";
import {ScheduledSlot} from "../model/scheduled-slot";
import {InputStream, OutputStream} from "../model/stream";
import {environment} from "../../environments/environment";
import {ChannelSection} from "../model/channel-section";
import {CreateImage, CreateImageResponse} from "../model/image";
import {CampaignContentEntry, ImagesContentEntry} from "../model/content";
import {UserFilter} from "../model/user";
import {group} from "@angular/animations";

export interface UpdateSection {
    title: string,
    type: string,
    rows: number,
    channelIds: string[]
}

@Injectable()
export class ChannelService {

  constructor(private http: HttpClient) { }

    public createChannel(channel: CreateChannel) {
        return this.http.post("/api/channels", channel);
    }

    public getChannels(pageSize: number, offset: string = null, query: string = null, includeDisabled: boolean,
                       includeOrgaWide: boolean = false) : Observable<PagedResult<Channel>> {
        let params = new HttpParams();
        if(pageSize != null)
            params = params.set("pageSize", String(pageSize));
        if(offset != null)
            params = params.set("offset", offset);
        if(query != null)
            params = params.set("query", query);
        if(includeDisabled != null) {
            params = params.set("includeDisabled", String(includeDisabled))
        }
        if(includeOrgaWide != null) {
            params = params.set("includeOrgaWide", String(includeOrgaWide))
        }

        return this.http.get<PagedResult<Channel>>(environment.apiEndpoint +"/api/channels", {params: params});
    }

    public deleteChannel(id: string): Observable<any> {
        return this.http.delete(environment.apiEndpoint + "/api/channels/id=" + id);
    }

    public getChannel(name: string): Observable<Channel> {
        return this.http.get<Channel>(environment.apiEndpoint +"/api/channels/name=" + name);
    }

    public updateChannel(channel: Channel): Observable<any> {
        let entries;
        if (channel.content != null && channel.content.entries != null) {
            entries = channel.content.entries.map(value => {
                if (value.type == "Images") {
                    return {
                        type: "Images",
                        images: (<ImagesContentEntry>value).images.map(value => {
                            return {
                                //copyright: value.copyright,
                                //license: value.license,
                                uploadUrl: value.uploadUrl,
                                imageId: value.imageId,
                                license: value.license != null ? value.license.name : null,
                                copyright: value.copyright,
                            }
                        })
                    }
                } else if (value.type == "Campaign") {
                    return {
                        type: "Campaign",
                        campaignId:  (<CampaignContentEntry>value).campaignId
                    }
                } else {
                    return value;
                }
            });
        }

        return this.http.put(environment.apiEndpoint +"/api/channels/id=" + channel.channelId, {
            stationId: channel.stationId,
            name: channel.name,
            title: channel.title,
            subTitle: channel.subTitle,
            summary: channel.summary,
            requiredAccessLevel: channel.requiredAccessLevel,
            coverImageId: channel.coverImageId,
            content: channel.content != null ? {
                text: channel.content.text,
                entries: entries
            } : null,
            enabled: channel.enabled
        });
    }

    public processCoverImage(postId: string, createImage: CreateImage): Observable<string> {
        return this.http.post<CreateImageResponse>("/api/channels/id=" + postId + "/coverImage", createImage)
            .map(res => res.imageId);
    }

    public enableChannel(name: string, value: boolean): Observable<any> {
      return this.http.put(environment.apiEndpoint +"/api/channels/" + name, {disabled: !value})
    }


    public getScheduledSlots(channelId: string, date: Date): Observable<ScheduledSlot[]>{
      let params = new HttpParams();
      if(date != null) {
          params = params.set("date", moment(date).format("YYYY-MM-DD"))
      }

       return this.http.get<ScheduledSlot[]>(environment.apiEndpoint +"/api/channels/" + channelId + '/scheduled-slots', {params: params})
    }

    public getStreamConfig(channelId: string): Observable<StreamConfig> {
      return this.http.get<StreamConfig>(environment.apiEndpoint + "/api/channels/" + channelId + "/stream-config");
    }

    public saveStreamConfig(channelId: string, config: StreamConfig): Observable<StreamConfig> {
      return this.http.put<StreamConfig>(environment.apiEndpoint + "/api/channels/" + channelId + "/stream-config", config);
    }

    public getStreamStatus(channelId: string): Observable<StreamStatus> {
      return Observable.create((o) => {
          let eventSource = new EventSource(environment.apiEndpoint + "/api/channels/" + channelId + "/status", {
              withCredentials: true
          });

          eventSource.onmessage =  message => {
              o.next(JSON.parse(message.data))
          };

          eventSource.onerror = error => {
              o.error(error)
          };
      });
    }

    // groups

    public processGroupImage(postId: string, createImage: CreateImage): Observable<string> {
        return this.http.post<CreateImageResponse>("/api/channel-groups/id=" + postId + "/image", createImage)
            .map(res => res.imageId);
    }

    public createGroup(name: string): Observable<ChannelGroup> {
      return this.http.post<ChannelGroup>("/api/channels-groups", { name: name });
    }

    public deleteGroup(groupId: string): Observable<any> {
      return this.http.delete("/api/channel-groups/id=" + groupId);
    }

    public getGroups(pageSize: number, offset: string = null, query: string = null) {
        let params = new HttpParams();
        if(pageSize != null)
            params = params.set("pageSize", String(pageSize));
        if(offset != null)
            params = params.set("offset", offset);
        if(query != null)
            params = params.set("query", query);

        return this.http.get<PagedResult<ChannelGroup>>("/api/channel-groups", {params: params});
    }

    public getGroupById(groupId: string) {
        return this.http.get<ChannelGroup>("/api/channel-groups/id=" + groupId);
    }

    public updateChannelGroup(group: ChannelGroup): Observable<any> {
      // TODO: Clean up hack please..
        let entries;
        if (group.content != null && group.content.entries != null) {
            entries = group.content.entries.map(value => {
                if (value.type == "Images") {
                    return {
                        type: "Images",
                        images: (<ImagesContentEntry>value).images.map(value => {
                            return {
                                //copyright: value.copyright,
                                //license: value.license,
                                uploadUrl: value.uploadUrl,
                                imageId: value.imageId,
                                license: value.license != null ? value.license.name : null,
                                copyright: value.copyright,
                            }
                        })
                    }
                } else if (value.type == "Campaign") {
                    return {
                        type: "Campaign",
                        campaignId:  (<CampaignContentEntry>value).campaignId
                    }
                } else {
                    return value;
                }
            });
        }

        return this.http.put<any>("/api/channel-groups/id=" + group.groupId, {
            name: group.name,
            title: group.title,
            imageId: group.imageId,
            content: group.content != null ? {
                text: group.content.text,
                entries: entries
            } : null
        });
    }

    public updateChannelGroupChannels(groupId: string, channels: string[]): Observable<any> {
        return this.http.put<any>("/api/channel-groups/id=" + groupId + "/channels", { channels: channels });
    }

    // sections

    public getSections(): Observable<ChannelSection[]> {
        return this.http.get<ChannelSection[]>(environment.apiEndpoint + "/api/sections");
    }

    public updateSections(update: UpdateSection[]): Observable<any> {
        return this.http.post(environment.apiEndpoint + "/api/sections", {sections: update})
    }

    // promotion

    public promoteChannel(channelId: string, title: string, message: string, filter: UserFilter): Observable<any> {
        return this.http.post("/api/channels/id=" + channelId + "/promote", {
            title: title, message: message, userFilter: filter
        });
    }
}
