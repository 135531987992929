import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AddMemberComponent} from "../add-member/add-member.component";
import {AdminService, PagedResult} from "../../services/admin.service";

import 'rxjs/add/operator/map';
import {ContentListComponent} from "../../components/content-list/content-list.component";
import {Member} from "../../model/member";
import {Station} from "../../model/station";
import {Observable} from "rxjs/Observable";
import {DeleteDialogComponent} from "../../components/delete-dialog/delete-dialog.component";
import {MemberService} from "../../services/member.service";

@Component({
  selector: 'app-member-list',
  templateUrl: './member-list.component.html',
  styleUrls: ['./member-list.component.scss']
})
export class MemberListComponent implements OnInit {

    @ViewChild(ContentListComponent)
    content: ContentListComponent<Member>;

    @ViewChild("addMemberModal") addMemberModal: AddMemberComponent;

    @ViewChild("deleteDialog") deleteDialog: DeleteDialogComponent;

    query: string;

    constructor(private router: Router, private route: ActivatedRoute, private memberService: MemberService) {
    }

    ngOnInit() {
        this.addMemberModal.onMemberCreated.subscribe(value => {
            this.content.refresh();
        });
    }

    addMember () {
        this.router.navigate(["../create-member"], {relativeTo: this.route});
    }

    openMember (member: Member) {
        this.router.navigate(['/organisation/members/edit/' + member.username])
    }

    deleteMember(item: Member) {
        this.deleteDialog.open(item.memberId, item.username, () => {
            this.memberService.deleteMember(item.memberId)
                .subscribe(value => {
                    this.content.removeItems(e => e.id == item.memberId)
                });
        });
    }

    search(query: string) {
        this.query = query;
        this.content.refresh();
    }

    getMembers(pageSize: number, offset: string): Observable<PagedResult<Member>> {
        return this.memberService.getMembers(pageSize, offset, this.query);
    }
}
