import {Component, OnInit, ViewChild, AfterViewInit} from '@angular/core';
import {ContentListComponent} from "../../../components/content-list/content-list.component";
import {Feed} from "../../../model/feed";
import {PostService} from "../../../services/post.service";
import {DeleteDialogComponent} from "../../../components/delete-dialog/delete-dialog.component";
import {Channel} from "../../../model/channel";
import {PagedResult} from "../../../services/admin.service";
import {Observable} from "rxjs";
import {CreateFeedDialogComponent} from "../create-feed-dialog/create-feed-dialog.component";
import {error} from "selenium-webdriver";
import { AuthService } from 'app/services/auth/auth.service';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-feed-list',
  templateUrl: './feed-list.component.html',
  styleUrls: ['./feed-list.component.scss']
})
export class FeedListComponent implements OnInit {

    @ViewChild("deleteDialog") deleteDialog: DeleteDialogComponent;

    @ViewChild("addDialog") addDialog: CreateFeedDialogComponent;

    @ViewChild(ContentListComponent)
    content: ContentListComponent<Feed>;

    public showAddDialog: Function = null;

    constructor(private authService: AuthService, private postsService: PostService) {
        if(this.authService.isAdmin())
            this.showAddDialog = this._showAddDialog;

    }

    ngOnInit() {
    }

   
    private _showAddDialog() {
        this.addDialog.open();
    }

    public deleteFeed(feed: Feed) {
        this.deleteDialog.open(feed.feedId, feed.displayName, () => {
            this.postsService.deleteFeed(feed.feedId)
                .subscribe(value => {
                    this.content.removeItems(f => f.feedId == feed.feedId)
                });
        });
    }


    getFeeds(pageSize: number, offset: string): Observable<PagedResult<Feed>> {
        return this.postsService.getFeeds()
            .map(value => new PagedResult(value, null));
    }
}
