import {
    ContentChild,
    ContentChildren,
    Directive, ElementRef, Host, HostListener,
    Input,
    OnInit, Optional, QueryList, Self,
} from '@angular/core';

import {LangSelectComponent} from "../ui/common/lang-select/lang-select.component";
import {FormGroup} from "@angular/forms";
import {QuillEditorComponent} from "ngx-quill";
import {StationService} from "../services/station/station.service";
import * as R from "ramda";
import {ContentComponent} from "../components/content/content.component";

@Directive({
    selector: '[rsLocalizedField]'
})
export class LocalizedFieldDirective {

    @Input()
    item: any;

    content: ContentComponent<any>;

    @Input()
    field: string;

    initialized: boolean = false;

    @Input()
    formControlName: string;

    @Input()
    langSelect: LangSelectComponent;

    selectedLocale: string;

    valueReady = false;

    constructor(private el: ElementRef, @Self() @Optional() private quill: QuillEditorComponent,
                private stationService: StationService) {
        this.selectedLocale = stationService.getDefaultLocale();
    }

    private setField(path, obj, value) {
        var schema = obj;  // a moving reference to internal objects within obj
        var pList = path.split('.');
        var len = pList.length;
        for(var i = 0; i < len-1; i++) {
            var elem = pList[i];
            if( !schema[elem] ) schema[elem] = {};
            schema = schema[elem];
        }

        schema[pList[len-1]] = value;
    }

    getNestedField(item: any, dir: string): any{
        return R.path(dir.split('.'))(item);
    }

    @HostListener('change', ['$event']) ngOnChanges(event) {
        if(this.quill != null)
            return;

        if(this.item != null && this.selectedLocale != null) {

            if(this.content != null) {
                if (event.item != undefined) {
                    // quill init event
                    return
                }
                this.content.setDirty();
            }

            if(this.item[this.field] == undefined) {
                this.item[this.field] = {};
            }

            if(this.initialized)
                this.item[this.field][this.selectedLocale] = this.el.nativeElement.value;
            else
                this.initialized = true;
        }
    }

    @HostListener('onContentChanged', ['$event']) ngOnContentChanged(event) {
        if(this.item != null && this.selectedLocale != null) {

            if(event.source == 'user') {
                this.valueReady = true;
            }

            if(!this.valueReady && event.source == 'api') {
                this.valueReady = true;
                return;
            }

            if(this.content != null && this.valueReady) {
                this.content.setDirty();
            }

            if (this.getNestedField(this.item, this.field) == undefined) {
                // Need to create empty string map
                this.setField(this.field, this.item, {});
            }

            if (this.quill.format == 'html') {
                this.setField(this.field + "." + this.selectedLocale, this.item, event.html);
                //this.item[this.field][this.selectedLocale] = event.html;
            } else if (this.quill.format == 'text') {
                this.setField(this.field + "." + this.selectedLocale, this.item, event.text);
                this.item[this.field][this.selectedLocale] = event.text;
            }
        }
    }
}

@Directive({
  selector: '[rsLocalized]'
})
export class LocalizedDirective implements OnInit {

    @Input()
    langSelect: LangSelectComponent;

    @Input()
    formGroup: FormGroup;

    @Input()
    item: any;

    @ContentChildren(LocalizedFieldDirective)
    fields !:QueryList<LocalizedFieldDirective>;

    constructor() {
    }

    ngOnInit() {
        // this.langSelect.onChange.subscribe(locale => {
        //     this.fields.forEach(item => {
        //         debugger
        //         let update = {};
        //         update[item.formControlName] = this.item[item.fieldName][locale] || "";
        //         debugger
        //         this.formGroup.setValue(update);
        //     });
        // });
    }
}


