import {Observable} from "rxjs/Observable";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Injectable} from "@angular/core";


@Injectable()
export class StationInterceptor implements HttpInterceptor {

    constructor() { }

    intercept(req: HttpRequest<any>,
              next: HttpHandler): Observable<HttpEvent<any>> {
        // TODO: header settings does not work
        if(localStorage.getItem("currentStation") == null) {
            return next.handle(req)
        } else {
            let activeStation = JSON.parse(localStorage.getItem("currentStation"));
            const cloned = req.clone({
                headers: req.headers.set("X-STATION", activeStation.name)
            });
            return next.handle(cloned);
        }
    }
}
