import {Component, OnInit, ViewChild} from '@angular/core';
import {ChannelService} from "../../../services/channel.service";
import {NgModel} from "@angular/forms";
import {ClrModal} from "@clr/angular";
import {Subject} from "rxjs/Subject";
import {RadioService} from "../../../services/radio.service";
import {HttpErrorResponse} from "@angular/common/http";
import {AdminService} from "../../../services/admin.service";

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {

    @ViewChild("#addUserModal") modal: ClrModal;

    modalOpen: boolean = false;

    name: string;

    email: string;

    password: string;

    // Input fields

    @ViewChild("emailInput")
    emailInput: NgModel;

    @ViewChild("passwordInput")
    passwordInput: NgModel;

    @ViewChild("nameInput")
    nameInput: NgModel;

    // Observables

    readonly onCreated: Subject<any> = new Subject();

    readonly onError: Subject<any> = new Subject();

    constructor(private radioService: RadioService, private adminService: AdminService) { }

    ngOnInit() {
    }

    addChannel() {
        this.adminService.addUser(this.email, this.password, this.name)
            .subscribe((user) => {
                this.onCreated.next(user);
                this.close();
            },(error: HttpErrorResponse) => {
                if(error.status == 409) {
                    if(error.error.errorCode == 'emailExists') {
                        this.emailInput.control.setErrors({emailExists: true});
                    }
                }
                this.onError.next(error);
                this.close();
            });
    }

    close () {
        this.modalOpen = false;
        this.reset();
    }

    reset() {
        this.name = "";
        this.email = "";
        this.password = "";

        this.nameInput.reset();
        this.emailInput.reset();
        this.passwordInput.reset();
    }

}
