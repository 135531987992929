import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {ClrModal} from "@clr/angular";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AddArtist} from "../add-artist/add-artist.component";

export interface AddRelease {
    title: string
}

@Component({
  selector: 'app-add-release',
  templateUrl: './add-release.component.html',
  styleUrls: ['./add-release.component.scss']
})
export class AddReleaseComponent implements OnInit {


    @ViewChild("modal") modal: ClrModal;

    modalOpen: boolean;

    form: FormGroup;

    @Output("onFinish")
    onFinish: EventEmitter<AddRelease> = new EventEmitter<AddRelease>();

    constructor(private fb: FormBuilder) {
        this.form = fb.group({
            "title": [null, [Validators.required]],
            "artist": [null, [Validators.required]]
        });
    }


    ngOnInit() {
    }

    open() {
        this.modal.open();
    }


    public finish() {
        this.onFinish.emit(<AddRelease>{
            title: this.form.get("title").value,
        });

        this.reset();
        this.close();
    }

    public reset() {
        this.form.reset()
    }
    public close() {
        this.modal.close();
        this.reset();
    }
}
