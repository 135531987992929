import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {AuthService} from "../auth/auth.service";
import {
    CreateStation,
    Station,
    StationDocument,
    StationEmailSettings,
    StationEmailTemplate,
    StationTOS
} from "../../model/station";
import {HttpClient} from "@angular/common/http";
import {CreateImage, CreateImageResponse} from "../../model/image";
import {AddApp} from "../../model/app";


@Injectable({
    providedIn: 'root'
})
export class StationService {

    public availableLanguages = [
        { locale: 'bg', name: 'Bulgarian' },
        { locale: 'cs', name: 'Czech'},
        { locale: 'da', name: 'Danish'},
        { locale: 'de', name: 'German'},
        { locale: 'el', name: 'Greek'},
        { locale: 'en', name: 'English'},
        { locale: 'es', name: 'Spanish'},
        { locale: 'et', name: 'Estonian'},
        { locale: 'fi', name: 'Finnish'},
        { locale: 'fr', name: 'French'},
        { locale: 'hr', name: 'Croatian'},
        { locale: 'hu', name: 'Hungarian'},
        { locale: 'it', name: 'Italian'},
        { locale: 'lt', name: 'Lithuanian'},
        { locale: 'lv', name: 'Latvian'},
        { locale: 'nl', name: 'Dutch'},
        { locale: 'no', name: 'Norwegian'},
        { locale: 'pl', name: 'Polish'},
        { locale: 'pt', name: 'Portuguese'},
        { locale: 'ro', name: 'Romanian'},
        { locale: 'ru', name: 'Russian'},
        { locale: 'sk', name: 'Slovak'},
        { locale: 'sl', name: 'Slovenian'},
        { locale: 'sv', name: 'Swedish'},
        { locale: 'tr', name: 'Turkish'},
        { locale: 'zh', name: 'Chinese'}
    ];

    constructor(private http: HttpClient, private authService: AuthService) {
    }

    public createStation(station: CreateStation): Observable<any> {
        return this.http.post("/api/stations", {
            name: station.name,
            displayName: station.displayName,
            domainName: station.domainName,
            defaultLocale: station.defaultLocale,
            locales: station.locales
        })
    }

    public getDefaultLocale(): string {
        let station = this.authService.getActiveStation();

        if(station != null) {
            return station.defaultLocale;
        }
    }

    public getAvailableStations(): Station[] {
        return this.authService.getAvailableStations();
    }

    public processCoverImage(stationId: string, createImage: CreateImage): Observable<string> {
        return this.http.post<CreateImageResponse>("/api/stations/id=" + stationId + "/coverImage", createImage)
            .map(res => res.imageId);
    }

    public getSupportedLocales(): string [] {
        let station = this.authService.getActiveStation();

        if(station != null && station.locales != null) {
            return station.locales.map(l => l.locale)
        }
    }

    public getStationTOS(name: string): Observable<StationTOS> {
        return this.http.get<StationTOS>("/api/stations/" + name + "/tos");
    }

    public updateStationTOS(name: string, text: {}): Observable<any> {
        return this.http.put("/api/stations/" + name + "/tos", { text: text})
    }

    public updateStationTOSVersion(name: string, version: string): Observable<any> {
        return this.http.put("/api/stations/" + name + "/tos-version", { version: version})
    }

    public getStationDocument(name: string, type: string): Observable<StationDocument> {
        return this.http.get<StationDocument>("/api/stations/" + name + "/documents/" + type);
    }

    public updateStationDocument(name: string, type: string, contents: {}): Observable<any> {
        return this.http.put("/api/stations/" + name + "/documents/" + type, contents);
    }

    public getEmailSettings(name: string): Observable<StationEmailSettings> {
        return this.http.get<StationEmailSettings>("/api/stations/" + name + "/email-settings");
    }

    public updateEmailSettings(name: string, settings: StationEmailSettings): Observable<any> {
        return this.http.put("/api/stations/" + name + "/email-settings", settings);
    }

    public getEmailTemplate(name: string, type: string): Observable<StationEmailTemplate> {
        return this.http.get<StationEmailTemplate>("/api/stations/" + name + "/email-templates/" + type);
    }

    public updateEmailTemplate(name: string, type: string, template: StationEmailTemplate): Observable<any> {
        return this.http.put<StationEmailTemplate>("/api/stations/" + name + "/email-templates/" + type, template);
    }

    public sendTestEMail(stationName: string, to: string, type: string, locale: string): Observable<any> {
        return this.http.post("/api/stations/" + stationName + "/test-email", {to: to, type: type, locale: locale});
    }


    // Apps
    public addApp(app: AddApp): Observable<any> {
        return null;
    }
}
