import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {ContentListComponent} from "../../../components/content-list/content-list.component";
import {PagedResult} from "../../../services/admin.service";
import {Observable} from "rxjs";
import {CampaignService} from "../../../services/campaign.service";
import {ActivatedRoute, Router} from "@angular/router";
import {CampaignResult, CampaignResultFilter, Slide} from "../../../model/campaign";
import {ShowImageDialogComponent} from "../../../components/dialogs/show-image-dialog/show-image-dialog.component";
import {AuthService} from "../../../services/auth/auth.service";
import {PlayAudioDialogComponent} from "../../../components/dialogs/play-audio-dialog/play-audio-dialog.component";
import {ShowTextDialogComponent} from "../../../components/dialogs/show-text-dialog/show-text-dialog.component";
import {FilterCampaignSubmissionDialogComponent} from "../filter-campaign-submission-dialog/filter-campaign-submission-dialog.component";
import {StationService} from "../../../services/station/station.service";
import {ClipboardService} from "ngx-clipboard";
import {injectTemplateRef} from "@angular/core/src/render3/view_engine_compatibility";

@Component({
  selector: 'app-campaign-submission-list',
  templateUrl: './campaign-submission-list.component.html',
  styleUrls: ['./campaign-submission-list.component.scss']
})
export class CampaignSubmissionListComponent implements OnInit {


    @ViewChild(ContentListComponent)
    content: ContentListComponent<CampaignResult>;

    @ViewChild("showImageDialog")
    showImageDialog: ShowImageDialogComponent;

    @ViewChild("playAudioDialog")
    playAudioDialog: PlayAudioDialogComponent;

    @ViewChild("showTextDialog")
    showTextDialog: ShowTextDialogComponent;

    @ViewChild("submissionFilterDialog")
    submissionFilterDialog: FilterCampaignSubmissionDialogComponent;

    campaignId: string;

    resultFilter: CampaignResultFilter = null;

    featuredOnly: boolean = false;

    slides: Slide[];

    constructor(public authService: AuthService,
                private route: ActivatedRoute,
                private router: Router,
                private campaignService: CampaignService,
                private stationService: StationService,
                private clipboardService: ClipboardService) { }

    ngOnInit() {
        this.route.parent.params.subscribe(params => {
            this.campaignId = params['id'];
        });
    }

    openSubmission(result) {
        this.router.navigate([result.user.userId], {relativeTo: this.route})
    }

    getSubmissions(pageSize: number, offset: string): Observable<PagedResult<CampaignResult>> {

        return this.campaignService.getCampaignSlides(this.campaignId)
            .switchMap(slides => {
                // Map default locale
                this.slides = slides.map(s => {
                    s.title = s.title[this.stationService.getDefaultLocale()];
                    return s;
                });

                if(this.resultFilter == null) {
                    return this.campaignService.getResults(this.campaignId, pageSize, offset, this.featuredOnly);
                } else {
                    return this.campaignService.searchResults(this.campaignId, pageSize, offset, this.resultFilter);
                }
            })

    }

    deleteResult(result) {
        this.content.delete({campaignId: result.campaign.campaignId, userId: result.user.userId}, result.user.username,
            (id) => this.campaignService.deleteResult(id.campaignId, id.userId));
    }

    toggleRating(result) {
        if (result.rating == 0)
            result.rating = 1;
        else
            result.rating = 0;

        this.campaignService.rateResult(this.campaignId, result.user.userId, result.rating)
            .subscribe(result => {
            }, error => {
                this.content.showError("Rating failed, please try again");

                if (result.rating == 0)
                    result.rating = 1;
                else
                    result.rating = 0;

            });
    }

    openFilter() {
        this.submissionFilterDialog.open(this.slides);
    }

    setFilter(filter: CampaignResultFilter) {
        if(filter != null) {
            filter.randomSeed = Math.floor(Math.random() * 10000000);
        }

        this.resultFilter = filter;
        this.content.next = null;
        this.content.refresh();
    }

    clearFilter() {
        this.resultFilter = null;
        this.content.refresh();
    }

    exportResults() {
        if(this.resultFilter != null) {
            let emails = this.content.items
                .filter(item => item.user != null)
                .map(item => item.user.email);

            this.clipboardService.copyFromContent(emails.join(","))
        }
    }
}
