import {Component, OnInit, ViewChild} from '@angular/core';
import {PagedResult} from "../../../services/admin.service";
import {Observable} from "rxjs";
import {Track} from "../../../model/track";
import {DeleteDialogComponent} from "../../../components/delete-dialog/delete-dialog.component";
import {ContentListComponent} from "../../../components/content-list/content-list.component";
import {TrackService} from "../../../services/disco/track.service";
import {Router} from "@angular/router";
import {AuthService} from "../../../services/auth/auth.service";

@Component({
  selector: 'app-track-list',
  templateUrl: './track-list.component.html',
  styleUrls: ['./track-list.component.scss']
})
export class TrackListComponent implements OnInit {

    @ViewChild("deleteDialog") deleteDialog: DeleteDialogComponent;

    @ViewChild(ContentListComponent)
    content: ContentListComponent<Track>;

    query: string;

    includeDisabled: boolean;

    constructor(private router: Router, public authService: AuthService,
                private trackService: TrackService) { }

    ngOnInit() {
    }

    public addTrack() {

    }

    public openTrack(track: Track) {
        this.router.navigate([ "/disco/tracks/" + track.trackId])
    }

    public getTracks(pageSize: number, offset: string): Observable<Track[]> {
         return this.trackService.getTracks(pageSize, offset, this.query);
    }

    searchTracks(query: string) {
        this.query = query;
        this.content.refresh();
    }

    reindexTracks() {
        this.trackService.reindexAllTracks()
            .subscribe();
    }
}
