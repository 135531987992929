import {Component, OnInit, ViewChild} from '@angular/core';
import {ContentComponent} from "../../../components/content/content.component";
import {Channel} from "../../../model/channel";
import {Campaign, CampaignStatistics} from "../../../model/campaign";
import {Observable, of} from "rxjs";
import {CampaignService} from "../../../services/campaign.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
    selector: 'app-campaign-results',
    templateUrl: './campaign-results.component.html',
    styleUrls: ['./campaign-results.component.scss'],
    host: {'class': 'item-content'}

})
export class CampaignStatisticsComponent implements OnInit {

    @ViewChild(ContentComponent)
    statistics: ContentComponent<CampaignStatistics>;

    notPublished: boolean;

    campaignId: string;

    campaignCounters: any[];

    constructor(private route: ActivatedRoute,
                private router: Router,
                private campaignService: CampaignService) { }

    ngOnInit() {
        this.route.parent.params.subscribe(params => {
            this.campaignId = params['id'];
        });
    }

    getStatistics(): Observable<CampaignStatistics> {
        return this.campaignService.getCampaign(this.campaignId)
            .flatMap(campaign => {
              if (!campaign.enabled) {
                  this.notPublished = false;
                  return of(null);
              } else {
                  return this.campaignService.getCampaignStatistics(this.campaignId)
                      .do(stats => this.setCounters(stats))
              }
            });
    }

    setCounters (stats : CampaignStatistics) {
        this.campaignCounters = [
            {name: "Total", value: stats.totalFinished},
        ];
    }
}
