import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AddImageComponent} from "../../ui/common/add-image/add-image.component";
import {CreateImage, ImageDto} from "../../model/image";
import {Observable} from "rxjs";
import {Callback} from "../../utility/callback";

@Component({
  selector: 'app-single-image-upload',
  templateUrl: './single-image-upload.component.html',
  styleUrls: ['./single-image-upload.component.scss']
})
export class SingleImageUploadComponent implements OnInit {

    constructor() { }

    @ViewChild("addImageModal")
    addImageModal: AddImageComponent;

    @ViewChild("imageInput")
    fileInput: ElementRef;

    imageData: any;

    @Input("image")
    imageUrl: string;

    @Input("imageId")
    imageId: string;

    // Size contraints

    @Input("valid") valid: boolean = true;

    @Input("onlyImage") onlyImage: boolean = false;

    @Input("maxWidth") allowedWidth: number = 0;

    @Input("maxHeight") allowedHeight: number = 0;

    @Input("minHeight") minHeight: number = 0;

    @Input("minWidth") minWidth; number = 0;

    @Input("maxHeight") maxHeight: number = 0;

    @Input("maxWidth") maxWidth: number = 0;

    // Upload & process urls
    @Input("uploadUrl") uploadUrl: string;

    // Upload & process urls
    @Input("processImage") processImage: Callback<CreateImage, string>;

    // Output events
    @Output("uploadFailed")
    uploadFailed: EventEmitter<string> = new EventEmitter<string>();

    @Output("onFinish")
    onFinish: EventEmitter<any> = new EventEmitter<any>();

    ngOnInit() {
        this.addImageModal.onFinish.subscribe(value => {

            this.imageData = value.imageData;
            this.valid = this.imageData != null;

            this.onFinish.emit(<CreateImage> {
                imageId: value.imageId,
                license: value.license,
                copyright: value.copyright,
                uploadUrl: value.uploadUrl,
                source: null
            });
        });
    }

    addImage() {
        this.addImageModal.open();
    }

    clear() {
        this.imageData = null;
        this.imageId = null;
        this.onFinish.emit(null);
    }
}
