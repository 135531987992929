import {WeekTime} from "./week-time";

export class WeekRange {

    public static DEFAULT = new WeekRange(new WeekTime(0, 0, 0, 0, false),
        new WeekTime(0, 1, 0, 0, false));

    public from: WeekTime;

    public to: WeekTime;

    constructor(from: WeekTime, to: WeekTime) {
        this.from = from;
        this.to = to;
    }

    public static of(fromDate: Date, toDate: Date): WeekRange {


        let from = WeekTime.fromDate(fromDate, false);
        let to = WeekTime.fromDate(toDate, false);

        if(to.getSeconds() <= from.getSeconds()) {
            to.nextWeek = true;
        }

        return new WeekRange(from, to);
    }
}
