import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {PagedResult} from "./admin.service";
import {
    Campaign,
    CampaignResult,
    CampaignResultFilter,
    CampaignStatistics,
    CreateCampaign,
    InfoSlide,
    Slide
} from "../model/campaign";
import {CreateImage, CreateImageResponse} from "../model/image";
import {CampaignContentEntry, ImagesContentEntry} from "../model/content";
import {UserFilter} from "../model/user";

@Injectable({
  providedIn: 'root'
})
export class CampaignService {

    constructor(private http: HttpClient) { }

    public getCampaigns(pageSize: number, offset: string = null,
                        query: string = null, enabled: boolean = null): Observable<PagedResult<Campaign>> {
        let params = new HttpParams();
        if(pageSize != null)
            params = params.set("pageSize", String(pageSize));
        if(offset != null)
            params = params.set("offset", offset);
        if(query != null)
            params = params.set("query", query);

        if(enabled != null) {
            params = params.set("enabled", String(enabled));
        }

        return this.http.get<PagedResult<Campaign>>("/api/campaigns", { params: params});
    }

    public deleteCampaign(campaignId: string): Observable<any> {
        return this.http.delete("/api/campaigns/id=" + campaignId)
    }

    public getCampaign(campaignId: string): Observable<Campaign> {
        return this.http.get<Campaign>("/api/campaigns/id=" + campaignId);
    }

    public getCampaignSlide(campaignId: string, slideId: string): Observable<Slide> {
        return this.http.get<Slide>("/api/campaigns/id=" + campaignId + "/slideId=" + slideId);
    }

    public getCampaignStatistics(campaignId: string): Observable<CampaignStatistics> {
        return this.http.get<CampaignStatistics>("/api/campaigns/id=" + campaignId + "/results/statistics");
    }

    public getCampaignSlides(campaignId: string): Observable<Slide[]> {
        return this.http.get<Slide[]>("/api/campaigns/id=" + campaignId + "/slides");
    }

    public updateCampaignSlides(campaignId: string, slides: Slide[]): Observable<any> {

        // TODO: cleanup hack
        for (let slide of slides) {
            if (slide.type == 'Info') {
                let info = <InfoSlide>slide;

                if(info != null) {
                    if (info.content != null && info.content.entries != null) {
                        info.content.entries = info.content.entries.map(value => {
                            if (value.type == "Images") {
                                return {
                                    type: "Images",
                                    images: (<ImagesContentEntry>value).images.map(value => {
                                        return {
                                            uploadUrl: value.uploadUrl,
                                            imageId: value.imageId,
                                            license: value.license != null ? value.license.name : null,
                                            copyright: value.copyright,
                                        }
                                    })
                                }
                            } else if (value.type == "Campaign") {
                                return {
                                    type: "Campaign",
                                    campaignId: (<CampaignContentEntry>value).campaignId
                                }
                            } else {
                                return value;
                            }
                        });
                    }
                }
            }
        }

        return this.http.put("/api/campaigns/id=" + campaignId + "/slides", slides);
    }

    public updateCampaign(item: Campaign): Observable<any> {

        return this.http.put("/api/campaigns/id=" + item.campaignId, {
            title: item.title,
            summary: item.summary,
            coverImageId: item.coverImageId,
            startDate: item.startDate,
            expireDate: item.expireDate,
            requiresEmail: item.requiresEmail,
            requiresPhone: item.requiresPhone,
            tos: item.tos
        });
    }

    public enableCampaign(campaignId: string): Observable<any> {
        return this.http.post("/api/campaigns/id=" + campaignId + "/enable", {});
    }

    public resetCampaign(campaignId: string): Observable<any> {
        return this.http.put("/api/campaigns/id=" + campaignId + "/reset", {});
    }

    public createCampaign(item: CreateCampaign): Observable<Campaign> {
        return this.http.post<Campaign>("/api/campaigns", item);
    }

    public processCoverImage(campaignId: string, createImage: CreateImage): Observable<string> {
        return this.http.post<CreateImageResponse>("/api/campaigns/id=" + campaignId + "/coverImage", createImage)
            .map(res => res.imageId);
    }

    public processSlideImage(campaignId: string, createImage: CreateImage): Observable<string> {
        return this.http.post<CreateImageResponse>("/api/campaigns/id=" + campaignId + "/slides/image", createImage)
            .map(res => res.imageId);
    }

    public deleteResult(campaignId: string, userId: string): Observable<any> {
        return this.http.delete("/api/campaigns/id=" + campaignId + "/results/userId=" + userId);
    }

    public rateResult(campaignId: string, userId: string, value: number): Observable<any> {
        return this.http.post("/api/campaigns/id=" + campaignId + "/results/userId=" + userId + "/rate", {
            rating: value
        });
    }

    public getResult(campaignId: string, userId: string): Observable<CampaignResult> {
        return this.http.get<CampaignResult>("/api/campaigns/id=" + campaignId + "/results/userId=" + userId);
    }

    public getResults(campaignId: string, pageSize: number = null, offset:string = null, featuredOnly: boolean = false): Observable<PagedResult<CampaignResult>>{
        let params = new HttpParams();
        if(pageSize != null)
            params = params.set("pageSize", String(pageSize));
        if(offset != null)
            params = params.set("offset", offset);

        if(featuredOnly) {
            params = params.set("minRating", String(1));
        }

        return this.http.get<PagedResult<CampaignResult>>("/api/campaigns/id=" + campaignId + "/results", { params: params });
    }

    public searchResults(campaignId: string, pageSize: number = null, offset: string = null, filter: CampaignResultFilter): Observable<PagedResult<CampaignResult>> {
        let params = new HttpParams();
        if(pageSize != null)
            params = params.set("pageSize", String(pageSize));
        if(offset != null)
            params = params.set("offset", offset);

        return this.http.post<PagedResult<CampaignResult>>("/api/campaigns/id=" + campaignId + "/results/search", filter);
    }


    public promoteCampaign(campaignId: string, title: string, message: string, filter: UserFilter): Observable<any> {
        return this.http.post("/api/campaigns/id=" + campaignId + "/promote", {
            title: title, message: message, userFilter: filter
        });
    }
}

