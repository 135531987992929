import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {ScheduleService} from "../../services/schedule.service";
import {ClrModal} from "@clr/angular";

@Component({
  selector: 'app-add-schedule',
  templateUrl: './add-schedule.component.html',
  styleUrls: ['./add-schedule.component.scss']
})
export class AddScheduleComponent implements OnInit {

    @ViewChild("addScheduleModal") modal: ClrModal;

    modalOpen: boolean;

    channelId: string;

    schedule: any = {};

    @Output("onCreate")
    onCreate: EventEmitter<any> = new EventEmitter<any>();

    constructor(private scheduleService: ScheduleService) {
    }

    ngOnInit() {
    }

    public open(channelId: string) {
        this.schedule.channelId = channelId;
        this.modalOpen = true;
    }

    public close() {
        this.modalOpen = false;
    }

    create() {
        this.close();
        this.onCreate.emit(this.schedule);
    }
}
