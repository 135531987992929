import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../services/auth/auth.service";
import {ScheduleService} from "../../services/schedule.service";
import {AddStaticStreamComponent} from "../add-static-stream/add-static-stream.component";
import {Observable} from "rxjs/Observable";
import {Channel} from "../../model/channel";
import {ChannelService} from "../../services/channel.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DeleteDialogComponent} from "../../components/delete-dialog/delete-dialog.component";
import {ContentComponent, ContentHost} from "../../components/content/content.component";
import {CreateImage} from "../../model/image";
import {Callback} from "../../utility/callback";
import {Post} from "../../model/post";
import {SingleImageUploadComponent} from "../../commons/single-image-upload/single-image-upload.component";
import {StationService} from "../../services/station/station.service";
import {PromoteWizardComponent} from "../../components/wizards/promote-wizard/promote-wizard.component";
import {SelectDialogComponent} from "../../components/select-dialog/select-dialog.component";
import {of} from "rxjs";

interface AccessLevel {
    name: string;
    value: string;
}

interface Schedule {
    id: string,
    validFrom: string
}



@Component({
    selector: 'app-channel-details',
    templateUrl: './channel-details.component.html',
    styleUrls: ['./channel-details.component.scss'],
    host: {'class': 'item-content'}
})
export class ChannelDetailsComponent extends ContentHost implements OnInit, AfterViewInit {

    @ViewChild(ContentComponent)
    content: ContentComponent<Channel>;

    @ViewChild("coverImage")
    coverImage: SingleImageUploadComponent;

    @ViewChild("deleteScheduleDialog")
    deleteScheduleDialog: DeleteDialogComponent;

    @ViewChild("promoteWizard")
    promoteWizard: PromoteWizardComponent;

    @ViewChild("addGroupDialog")
    addGroupDialog: SelectDialogComponent;


    form: FormGroup;

    name: string;

    schedules: Schedule[] = [];

    activeScheduleId: string;

    accessLevels: AccessLevel[] = [
        {name: "Anonymous", value: "Anonymous"},
        {name: "Registered", value: "Registered"},
        {name: "Premium", value: "Premium"}
        ];

    // Input fields

    @ViewChild("addStaticStreamModal")
    addStaticStreamModal: AddStaticStreamComponent;

    readonly imageUploadUrl = "/api/uploads/channel-cover";

    processImageCallback: Callback<CreateImage, string> = new Callback(this, this.processImage);

    descModule = { toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'indent': '-1'}, { 'indent': '+1' }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'font': [] }],
        [{ 'align': [] }],
        ['link']
    ]};

    constructor(private fb: FormBuilder, private route: ActivatedRoute, private router: Router,
                private authService: AuthService, public stationService: StationService,
                private channelService: ChannelService, private scheduleService: ScheduleService) {
        super();
        this.form = fb.group({
            name: ['', [Validators.required, Validators.min(2), Validators.maxLength(32) ]],
            title: ['', [Validators.required, Validators.min(2), Validators.maxLength(32) ]],
            summary: [''],
            subTitle: [''],
            text: [''],
            enabled: [false],
            requiredAccessLevel: [null],
            stationId: [null]
        })
    }

    ngOnInit() {
        this.route.parent.params.subscribe(params => {
            this.name = params['name'];
        });
    }

    ngAfterViewInit() {
    }

    getChannel(): Observable<Channel> {
        return this.channelService.getChannel(this.name);
    }

    validate(post: Channel) {
        if(this.content.item.coverImageId == null) {
            this.coverImage.valid = false;
            return false;
        }

        return true;
    }

    setCoverImage(value: any) {
        this.content.setDirty();
        if(value != null) {
            this.content.item.coverImageId = value.imageId;
        }
    }

    processImage(createImage: CreateImage): Observable<string> {
        return this.channelService.processCoverImage(this.content.item.channelId, createImage);
    }

    updateChannel(): Observable<any> {
        return this.channelService.updateChannel(this.content.item);
    }

    deleteChannel(): Observable<any> {
        return this.channelService.deleteChannel(this.content.item.channelId);
    }

    onChannelDeleted() {
        this.router.navigate(['../../../../'], {relativeTo: this.route});
    }

    enabled(value: boolean) {
        return this.channelService.enableChannel(this.name, value)
            .subscribe()
    }

    addSchedule () {
        this.router.navigate(["create-schedule"], {relativeTo: this.route });
    }


    viewSchedule(schedule: Schedule) {
        this.router.navigate(["schedules/" + schedule.id], {relativeTo: this.route})
    }

    removeSchedule(schedule: Schedule) {
        this.deleteScheduleDialog.open(schedule.id, null, () => {
            this.scheduleService.deleteSchedule(schedule.id)
                .subscribe(() => {
                    this.content.refresh();
                });
        })
    }

    public promote() {
        this.promoteWizard.open("Channel", this.content.item.channelId);
    }

    isDirty(): boolean {
        return this.content.isDirty();
    }

}
