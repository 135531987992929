import {Component, OnInit, ViewChild} from '@angular/core';
import {ContentComponent, ContentHost} from "../../../components/content/content.component";
import {MemberRole} from "../../../model/member";
import {Observable} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../../services/auth/auth.service";
import {AdminService} from "../../../services/admin.service";
import {MemberService} from "../../../services/member.service";


@Component({
    selector: 'app-member-permissions',
    templateUrl: './member-roles.component.html',
    styleUrls: ['./member-roles.component.scss'],
    host: {'class': 'item-content'}
})
export class MemberRolesComponent extends ContentHost implements OnInit {

    @ViewChild(ContentComponent)
    content: ContentComponent<MemberRole[]>;

    name: string;

    memberId: string;

    availableGlobalRoles = [
        {role: "ADMIN", displayName: "Admin"},
        {role: "CHANNEL_EDITOR", displayName: "Channel Editor"},
    ];

    availableStations = [];

    availableStationRoles = [
        {role: "USER_MANAGER", displayName: "User Manager"},
        {role: "POST_ADMIN", displayName: "Post Manager"},
        {role: "POST_EDITOR", displayName: "Post Editor"},
        {role: "CAMPAIGN_EDITOR", displayName: "Campaign Editor"},
        {role: "CAMPAIGN_ADMIN", displayName: "Campaign Admin"},

    ];

    constructor(private route: ActivatedRoute, private router: Router,
                private authService: AuthService, private adminSevice: AdminService,
                private memberService: MemberService) {
        super();
    }

    ngOnInit() {
        this.content.item = [];

        this.route.parent.params.subscribe(params => {
            this.name = params['name'];
        });

        this.adminSevice.getStations(10, null, null)
            .subscribe(res => {
                this.availableStations = res.items;
            });
    }

    getRoles(): Observable<MemberRole[]> {
        return this.memberService.getMember(this.name)
            .do(member => this.memberId = member.memberId)
            .map(member => {
                if(member.roles == null)
                    return [];
                else
                    return member.roles;
            })
    }

    updateRoles(): Observable<any> {
        return this.memberService.updateMemberRoles(this.memberId, this.content.item);
    }

    getRoleDisplayName(role: string) {
        for (let p of this.availableGlobalRoles) {
            if (role == p.role)
                return p.displayName;
        }
        for (let p of this.availableStationRoles) {
            if (role == p.role)
                return p.displayName;
        }
        return ""
    }

    hasRole(role: MemberRole) {
        let roles = this.content.item;
        for (let r of roles) {
            if (r.id == role.id &&
                r.role == role.role &&
                r.scope == role.scope)
                return true;
        }
        return false;
    }

    addRole(scope: string, id: string, role: string, name) {
        this.content.setDirty();
        let memberRole = <MemberRole>{id: id, scope: scope, role: role, displayName: name};
        if (!this.hasRole(memberRole)) {
            this.content.item.push(memberRole);
        }
    }

    removeRole(role: MemberRole) {
        this.content.setDirty();
        this.content.item = this.content.item.filter(value => {
            if (value.id == role.id && value.scope == role.scope && value.role == role.role)
                return false;
            else
                return true;
        });
    }
}
