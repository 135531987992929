import {ImageDto} from "./image";

export class ContentEntry {
    constructor(public type: string) {
    }
}

export class ImagesContentEntry extends ContentEntry {
    images: ImageDto[];
}

export class CampaignContentEntry extends ContentEntry {
    campaignId: string;
}

export class Content {
    constructor() {
        this.text = {};
        this.entries = [];
    }

    text: {};
    entries: ContentEntry[];
}
