import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {ClrModal} from "@clr/angular";

@Component({
  selector: 'app-promote',
  templateUrl: './promote.component.html',
  styleUrls: ['./promote.component.scss']
})
export class PromoteComponent implements OnInit {

    form: FormGroup;

    @ViewChild("modal") modal: ClrModal;

    @Output("onFinish")
    onFinish: EventEmitter<any> = new EventEmitter<any>();

    modalOpen: boolean = false;

    loading: boolean = false;

    constructor() { }

    ngOnInit() {
    }

    open() {
        this.modal.open();
    }

    finish() {
        this.modal.close();
    }

    close() {
        this.finish();
    }

}
