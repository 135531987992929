import {Component, ContentChild, ElementRef, EventEmitter, OnInit} from '@angular/core';
import {StationService} from "../../../services/station/station.service";

@Component({
  selector: 'app-lang-select',
  templateUrl: './lang-select.component.html',
  styleUrls: ['./lang-select.component.scss']
})
export class LangSelectComponent implements OnInit {

    selectedLocale: string;

    onChange: EventEmitter<string> = new EventEmitter();

    constructor(public stationService: StationService) {
        this.selectedLocale = stationService.getDefaultLocale();
    }

    ngOnInit() {
    }

    public getText(text: {}): string {
        if(text == null || this.selectedLocale == null)
            return "";

        return text[this.selectedLocale];
    }
}
