import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {ClrModal} from "@clr/angular";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Input} from "../../radio/add-input-modal/add-input-modal.component";

export interface AddArtist {
    type: string, name: string, gender: string, country: string, isni: string
}

@Component({
  selector: 'app-add-artist',
  templateUrl: './add-artist.component.html',
  styleUrls: ['./add-artist.component.scss']
})
export class AddArtistComponent implements OnInit {

    @ViewChild("modal") modal: ClrModal;

    modalOpen: boolean;

    form: FormGroup;

    @Output("onFinish")
    onFinish: EventEmitter<AddArtist> = new EventEmitter<AddArtist>();

    constructor(private fb: FormBuilder) {
        this.form = fb.group({
            "name": [null, [Validators.required]],
        });
    }

    ngOnInit() {
    }

    open() {
        this.modal.open();
    }


    public finish() {
        this.onFinish.emit(<AddArtist>{
            name: this.form.get("name").value,
        });

        this.reset();
        this.close();
    }

    public reset() {
        this.form.reset()
    }
    public close() {
        this.modal.close();
        this.reset();
    }
}
