import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {ClrModal} from "@clr/angular";
import {ChannelService} from "../../../services/channel.service";
import {Channel} from "../../../model/channel";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {StationService} from "../../../services/station/station.service";
import {Schedule} from "../../../services/schedule.service";
import {concat, Observable, of, Subject} from "rxjs";
import {catchError, debounceTime, distinctUntilChanged, switchMap, tap} from "rxjs/operators";

@Component({
  selector: 'app-select-channel',
  templateUrl: './select-channel.component.html',
  styleUrls: ['./select-channel.component.scss']
})
export class SelectChannelComponent implements OnInit {

    @ViewChild("modal")
    modal: ClrModal;

    channels$: Observable<Channel[]>;

    loading: boolean = false;

    channelInput$ = new Subject<string>();

    selectedChannel: Channel;

    selector: any;

    selectForm: FormGroup;

    constructor(private fb: FormBuilder, private channelService: ChannelService, public stationService: StationService) {
        this.selectForm = this.fb.group({
            channel: [null, Validators.required]
        })
    }

    @Output("onSelected")
    onSelected: EventEmitter<any> = new EventEmitter<any>();

    @Output("onError")
    onError: EventEmitter<any> = new EventEmitter<any>();

    ngOnInit() {
        this.loadChannels();
    }

    private loadChannels() {
        this.channels$ = concat(
            of([]),
            this.channelInput$.pipe(
                debounceTime(200),
                distinctUntilChanged(),
                tap(() => this.loading = true),
                switchMap(input => this.channelService.getChannels(10, null, input, true, true)
                    .map(res =>
                        res.items.map(value => {
                            value.title = value.title[this.stationService.getDefaultLocale()];
                            return value;
                        })
                    )
                    .pipe(
                        catchError(() => of([])),
                        tap(() => this.loading = false)
                    ))
            )
        );
    }


    open() {
        this.selectedChannel = null;
        this.modal.open();
    }

    public close() {
        this.modal.close();
    }

    public finish() {
        this.onSelected.emit(this.selectedChannel);
        this.close();
    }
}
