import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {ContentListComponent} from "../../components/content-list/content-list.component";
import {Channel} from "../../model/channel";
import {Observable} from "rxjs/Observable";
import {PagedResult} from "../../services/admin.service";
import {ChannelService} from "../../services/channel.service";
import {DeleteDialogComponent} from "../../components/delete-dialog/delete-dialog.component";
import {StationService} from "../../services/station/station.service";

@Component({
    selector: 'app-channel-list',
    templateUrl: './channel-list.component.html',
    styleUrls: ['./channel-list.component.scss'],
})
export class ChannelListComponent implements OnInit {

    @ViewChild("deleteDialog") deleteDialog: DeleteDialogComponent;

    @ViewChild(ContentListComponent)
    content: ContentListComponent<Channel>;

    query: string;

    includeDisabled: boolean;

    constructor(private router: Router, private channelService: ChannelService, public stationService: StationService,
                private route: ActivatedRoute) {
    }

    ngOnInit() {
    }

    public addChannel() {
        this.router.navigate(["../create-channel"], {relativeTo: this.route});
    }

    public openChannel(channel: Channel) {
        this.router.navigate([ "/radio/channels/edit/" + channel.name])
    }

    deleteChannel(channel: Channel)  {
        this.deleteDialog.open(channel.channelId, channel.name, () => {
            this.channelService.deleteChannel(channel.channelId)
                .subscribe(value => {
                    this.content.removeItems(e => e.channelId == channel.channelId)
                });
        });
    }

    search(query: string) {
        this.query = query;
        this.content.refresh();
    }

    getChannels(pageSize: number, offset: string): Observable<PagedResult<Channel>> {
        return this.channelService.getChannels(pageSize, offset, this.query, this.includeDisabled);
    }
}
