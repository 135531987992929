import {Channel} from "./channel";

export class ChannelSection {
    id: string;
    title: {};
    type: string;
    rows: number;
    backgroundImageUrl: string;
    channels: Channel[];

    constructor () {
        this.title = {};
        this.channels = [];
    }
}
