import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {ClrModal} from "@clr/angular";
import {UserFilter} from "../../../model/user";
import {UserFilterComponent} from "../../../components/user-filter/user-filter.component";

@Component({
  selector: 'app-user-filter',
  templateUrl: './user-filter-dialog.component.html',
  styleUrls: ['./user-filter-dialog.component.scss']
})
export class UserFilterDialogComponent implements OnInit {

    form: FormGroup;

    filter = new UserFilter();

    @ViewChild("modal") modal: ClrModal;

    @ViewChild("userFilter") userFilter: UserFilterComponent;

    @Output("onFinish")
    onFinish: EventEmitter<any> = new EventEmitter<any>();

    modalOpen: boolean = false;

    loading: boolean = false;

    constructor() { }

    ngOnInit() {
    }

    open(filter: UserFilter) {
        this.filter = filter;
        this.userFilter.filter = this.filter;
        this.modal.open();
    }

    finish() {
        this.modal.close();
        this.onFinish.emit(this.filter);
    }

    close() {
        this.finish();
    }

    clear() {
        this.filter = new UserFilter();
        this.userFilter.filter = this.filter;
    }
}
