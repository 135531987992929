import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ImageUploadComponent} from "../../commons/image-upload/image-upload.component";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../services/auth/auth.service";
import {Show} from "../../model/show";
import {ShowService} from "../../services/show.service";
import {ContentComponent, ContentHost} from "../../components/content/content.component";
import {Observable} from "rxjs/Observable";
import {StationService} from "../../services/station/station.service";
import {Callback} from "../../utility/callback";
import {CreateImage} from "../../model/image";

@Component({
    selector: 'app-show-details',
    templateUrl: './show-details.component.html',
    styleUrls: ['./show-details.component.scss'],
    host: {'class': 'item-content'}
})
export class ShowDetailsComponent  extends ContentHost implements OnInit {

    @ViewChild(ContentComponent)
    content: ContentComponent<Show>;

    form: FormGroup;

    name: string;

    @ViewChild("#imageUpload")
    fileInput: ImageUploadComponent;

    imageUploadUrl = "/api/uploads/show-cover";

    processImageCallback: Callback<CreateImage, string> = new Callback(this, this.processImage);

    constructor(private fb: FormBuilder, private route: ActivatedRoute, private router: Router,
                private authService: AuthService, private showService: ShowService, public stationService: StationService) {
        super();
        this.form = this.fb.group({
            name: ['', [Validators.required, Validators.min(2), Validators.maxLength(32) ]],
            title: ['', [Validators.required, Validators.min(2), Validators.maxLength(32) ]],
            subTitle: [''],
            summary: [''],
            text: [''],
            stationId: [null]
        });
    }

    ngOnInit() {
        this.route.parent.params.subscribe(params => {
            this.name = params['name'];
        });
    }

    setCoverImage(value: any) {
        this.content.setDirty();
        if(value != null) {
            this.content.item.coverImageId = value.imageId;
        }
    }

    processImage(createImage: CreateImage): Observable<string> {
        return this.showService.processCoverImage(this.content.item.showId, createImage);
    }

    getShow(): Observable<Show> {
        return this.showService.getShow(this.name);
    }

    updateShow(): Observable<any> {
        return this.showService.updateShow(this.content.item);
    }

    deleteShow(): Observable<any> {
        return this.showService.deleteShow(this.content.item.showId);
    }

    onShowDeleted() {
        this.router.navigate(['../../../list'], {relativeTo: this.route});
    }

    uploadImageError(error) {
        this.content.showError(error);
    }
}
