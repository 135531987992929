import {
    Component,
    ContentChild,
    Directive,
    ElementRef, EventEmitter, forwardRef,
    Input,
    OnInit, Output, TemplateRef,
    ViewChild,
} from '@angular/core';

import {ContentHeaderComponent} from "../content-header/content-header.component";
import {PagedResult} from "../../services/admin.service";
import {Observable} from "rxjs/Observable";
import {ClrAlert} from "@clr/angular";
import {ContentFilterComponent} from "../content-filter/content-filter.component";
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgModel} from "@angular/forms";
import {DeleteDialogComponent} from "../delete-dialog/delete-dialog.component";

@Directive({
    selector: '[contentFilter]'
})
export class ContentFilter {}

@Directive({
    selector: '[tableHeader]'
})
export class ContentTableHeader {}

@Directive({
    selector: '[itemRow]'
})

export class ContentItemRow {}

@Component({
    selector: 'app-filter-checkbox',
    template: `
    <clr-checkbox-wrapper class="filter-checkbox">
        <input type="checkbox" clrCheckbox (change)="change($event)"><label>{{title}}</label>
    </clr-checkbox-wrapper>
    `,
    styleUrls: ['./content-list.component.scss']
})
export class ContentFilterCheckbox {

    checkedValue = false;

    @Input()
    get checked() {
        return this.checkedValue
    }

    @Output() checkedChange = new EventEmitter();

    set checked(value) {
        this.checkedValue = value;
        this.checkedChange.emit(this.checkedValue);
    }

    @Input()
    title: string;

    @Output("change")
    changeEmitter = new EventEmitter();

    change(event) {
        this.checked = event.target.checked;
    }
}

interface Page <T> {
    items : T[];
    first: number;
    last: number;
}

@Component({
    selector: 'app-filter-select',
    template: `
        <div class="clr-select-wrapper">
            <span>{{title}}</span>
            <select id="select-basic" class="clr-select" [(ngModel)]="_value" (change)="change($event)" >
                <option *ngFor="let o of options" value="{{o.value}}">{{o.name}}</option>
            </select>
        </div>
    `,
    styles: [`:host { margin-right: 20px; }`, `span { margin-right: 5px; }`]
})
export class ContentFilterSelect {

    _value: string = null;

    @Input()
    get value() {
        return this._value;
    }

    set value(v) {
        this._value = v;
        this.valueChange.emit(v)
    }

    @Input()
    title: string;

    @Input()
    options: {name: string, value: string}[];

    @Output() valueChange = new EventEmitter();


    change(event) {
        this.value = event.target.value;
    }
}

@Component({
    selector: 'app-content-list',
    templateUrl: './content-list.component.html',
    styleUrls: ['./content-list.component.scss']
})
export class ContentListComponent<T> implements OnInit {

    @ViewChild(ContentHeaderComponent)
    header: ContentHeaderComponent;

    @ViewChild(ContentFilterComponent)
    filter: ContentFilterComponent;

    @ViewChild("content")
    content: ElementRef;

    @ViewChild("list")
    contentList: ElementRef;

    @ContentChild("errorAlert")
    errorAlert: ClrAlert;

    @ViewChild(DeleteDialogComponent)
    deleteDialog: DeleteDialogComponent;

    @ContentChild(ContentFilter, { read: TemplateRef })
    filterTemplate;

    @ContentChild(ContentItemRow, { read: TemplateRef })
    itemTemplate;

    @ContentChild(ContentTableHeader, { read: TemplateRef })
    headerTemplate;


    @Input()
    instance: ContentListComponent<T>;

    @Input()
    title: string = null;

    @Input()
    mode: string = 'default';

    @Input()
    filterEnabled: boolean = false;

    @Input()
    addItem: () => void;

    @Input()
    selectItem: (T) => void;

    @Input() getItems: (number, string) => Observable<PagedResult<T>>;

    @Input() searchItems: (text) => Observable<PagedResult<T>>;

    @Input()
    preload: boolean = true;

    firstSearch: boolean = true;

    items: T[] = [];

    pages: Page<T>[] = [];

    firstItem: number = 0;

    lastItem: number = 0;

    activePage: number = 0;

    isLoading: boolean = false;

    errorMessage: string;

    error: Error;

    errorDialogOpen: boolean = false;


    errorFlag: boolean = false;

    pageSize: number = 15;

    next: string = null;

    total: number = null;

    pageable: boolean = false;

    public constructor() {
        this.instance = this;
    }

    ngOnInit() {
        if(this.instance == null) {
            throw new Error("Instance not set")
        }
        if(this.mode != 'search')
            this.refresh();
    }

    onScroll(event): void {
        if ((event.target.scrollTop + event.target.clientHeight) >= event.target.scrollHeight) {
            this.loadMore();
        }
    }

    private hasSpaceForMore(): boolean {
        if(this.content == null)
            return false;

        return (this.content.nativeElement.scrollTop +
            this.content.nativeElement.clientHeight) >= this.content.nativeElement.scrollHeight;
    }

    refresh(): void {
        this.next = null;
        this.isLoading = true;
        this.items = [];

        if(this.getItems == null)
            return;

        this.getItems.apply(this.instance, [this.pageSize, this.next])
            .subscribe((res: PagedResult<T>) => {
                this.pages = [];
                this.items = res.items;
                this.pages.push({items: res.items, first: 0, last: res.items.length});
                this.activePage = 0;
                this.firstItem = this.pages[this.activePage].first;
                this.lastItem = this.pages[this.activePage].last;
                this.next = res.next;
                this.total = res.total;

                if(this.next) {
                    this.pageable = true;
                }
                }, (error) => {
                    this.error = error;
                    this.isLoading = false;
                }, () => {
                    this.isLoading = false;
                }
            , error => {
                });
    }

    search(text: string) {
        this.firstSearch = false;
        if(this.searchItems != null) {
            this.searchItems.apply(this.instance, [text]);
        }
    }

    removeItems(filter: (e) => boolean) {
        this.items = this.items.filter(value => {
            return !(filter.call(filter, value))
        });
        this.refresh();
    }

    add() {
        this.addItem.apply(this.instance);
    }

    select(item: T) {
        if(this.selectItem != null)
            this.selectItem.apply(this.instance, [item]);
    }

    loadPrev(): void {
        if(this.activePage < 1)
            return;
        this.activePage--;
        this.firstItem = this.pages[this.activePage].first;
        this.lastItem = this.pages[this.activePage].last;
        this.items = this.pages[this.activePage].items;
    }

    loadMore(): void {
        this.contentList.nativeElement.scrollTop = 0;

        if((this.pages.length - 1) > this.activePage) {
            // Existing page
            this.activePage++;
            this.items = this.pages[this.activePage].items;
            this.firstItem = this.pages[this.activePage].first;
            this.lastItem = this.pages[this.activePage].last;
            return;
        } else {
            if(this.next == null)
                return;
            this.isLoading = true;

            this.getItems.apply(this.instance, [this.pageSize, this.next])
                .subscribe((res: PagedResult<T>) => {
                        if(res.items.length > 0) {
                            this.items = res.items;
                            this.pages.push({
                                items: res.items,
                                first: this.pages[this.activePage].last,
                                last: this.pages[this.activePage].last + res.items.length,
                            });

                            this.activePage++;
                            this.firstItem = this.pages[this.activePage].first;
                            this.lastItem = this.pages[this.activePage].last;
                        }

                        this.next = res.next;
                        this.isLoading = false;
                    }, (error) => {
                        this.error = error;
                        this.isLoading = false;
                    }, () => {
                        this.isLoading = false;
                    }
                );
        }
    };

    delete(id, name, fn) {
        this.deleteDialog.open(id, name, () => {
            fn.apply(null, [id]).subscribe( res => {
                this.refresh();
            }, error => {
                this.showError("Failed to delete:" + error.message)
            });
        })
    }

    showError(message: string) {
        this.errorMessage = message;
        this.errorDialogOpen = true;
    }
}
