import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-content-filter',
  templateUrl: './content-filter.component.html',
  styleUrls: ['./content-filter.component.scss']
})
export class ContentFilterComponent implements OnInit {

    visible = true;
    constructor() { }

    ngOnInit() {
    }


}
