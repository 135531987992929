import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ClrModal} from "@clr/angular";

@Component({
  selector: 'app-show-image-dialog',
  templateUrl: './show-image-dialog.component.html',
  styleUrls: ['./show-image-dialog.component.scss']
})
export class ShowImageDialogComponent implements OnInit {

    @ViewChild("modal")
    modal: ClrModal;

    @ViewChild("image")
    image: ElementRef<HTMLImageElement>;

    imageSrc: string;

    filename: string;

    loading: boolean;

    constructor() {
    }

    ngOnInit() {
    }

    open(src: string, filename: string) {

        this.loading = true;

        this.filename = filename;

        this.image.nativeElement.onload = ev => {
            this.loading = false;
        };

        this.imageSrc = src;

        this.modal.open();
    }

    close() {
        this.modal.close();
    }
}
