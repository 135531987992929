import {Component, OnInit, ViewChild} from '@angular/core';
import {ContentComponent} from "../../../components/content/content.component";
import {StationEmailSettings} from "../../../model/station";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {StationService} from "../../../services/station/station.service";
import {Observable} from "rxjs";
import {ClrModal} from "@clr/angular";

interface TestEmail {
    to: string;
    type: string;
    locale: string;
}

@Component({
  selector: 'app-station-email-settings',
  templateUrl: './station-email-settings.component.html',
  styleUrls: ['./station-email-settings.component.scss']
})
export class StationEmailSettingsComponent implements OnInit {

    @ViewChild(ContentComponent)
    content: ContentComponent<StationEmailSettings>;

    form: FormGroup;

    stationName: string;

    @ViewChild("sendTestEmailModal")
    sendTestEmailModal: ClrModal;

    testEmail = <TestEmail>{};

    constructor(private fb: FormBuilder, private route: ActivatedRoute, private stationService: StationService) {
        this.form = fb.group({
            fromName: [null],
            fromAddress: [null],
        })
    }

    ngOnInit() {
        this.route.parent.parent.params.subscribe(params => {
            this.stationName = params['name'];
        });
    }

    getSettings(): Observable<StationEmailSettings>{
        return this.stationService.getEmailSettings(this.stationName);
    }

    updateSettings(): Observable<any> {
        return this.stationService.updateEmailSettings(this.stationName, this.content.item);
    }

    sendTestEmail() {
        this.stationService.sendTestEMail(this.stationName, this.testEmail.to, this.testEmail.type, this.testEmail.locale)
            .subscribe();
    }
}
