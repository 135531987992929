import {Component, OnInit, ViewChild} from '@angular/core';
import {ContentComponent} from "../../../components/content/content.component";
import {Schedule, ScheduleService} from "../../../services/schedule.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ChannelService} from "../../../services/channel.service";
import {DeleteDialogComponent} from "../../../components/delete-dialog/delete-dialog.component";
import {ContentListComponent} from "../../../components/content-list/content-list.component";
import {PagedResult} from "../../../services/admin.service";

@Component({
  selector: 'app-schedule-list',
  templateUrl: './schedule-list.component.html',
  styleUrls: ['./schedule-list.component.scss']
})
export class ScheduleListComponent implements OnInit {

    @ViewChild(ContentListComponent)
    content: ContentListComponent<Schedule>;

    @ViewChild("deleteDialog")
    deleteDialog: DeleteDialogComponent;

    query: string;

    constructor(private route: ActivatedRoute, private router: Router,
                private channelService: ChannelService, private scheduleService: ScheduleService) {
    }

    ngOnInit() {
        this.route.parent.params.subscribe(params => {
        });
    }

    getSchedules(pageSize: number, offset: string) {
        return this.scheduleService.getSchedules(pageSize, offset, this.query);
    }

    addSchedule () {
        this.router.navigate(["/radio/create-schedule"] );
    }

    search(query: string) {
        this.query = query;
        this.content.refresh();
    }

    editSchedule(schedule: Schedule) {
        this.router.navigate(["/radio/schedules/edit/" + schedule.scheduleId])
    }

    deleteSchedule(schedule: Schedule) {
        this.deleteDialog.open(schedule.scheduleId, null, () => {
            this.scheduleService.deleteSchedule(schedule.scheduleId)
                .subscribe(() => {
                    this.content.refresh();
                });
        })
    }
}
