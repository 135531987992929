import {Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {ContentComponent, ContentHost} from "../../../components/content/content.component";
import {
    AudioSlide,
    Choice,
    ImageSlide,
    InfoSlide,
    MultiChoiceSlide,
    Slide,
    TextSlide,
    VideoSlide
} from "../../../model/campaign";
import {Observable, of} from "rxjs";
import {AddSlideComponent} from "../add-slide/add-slide.component";
import {Content} from "../../../model/content";
import {FormBuilder} from "@angular/forms";
import {MultiChoiceEditorComponent} from "../slide-editors/multi-choice-editor/multi-choice-editor.component";
import {InfoSlideEditorComponent} from "../slide-editors/info-slide-editor/info-slide-editor.component";
import {TextSlideEditorComponent} from "../slide-editors/text-slide-editor/text-slide-editor.component";
import {AudioSlideEditorComponent} from "../slide-editors/audio-slide-editor/audio-slide-editor.component";
import {ImageSlideEditorComponent} from "../slide-editors/image-slide-editor/image-slide-editor.component";
import {CampaignService} from "../../../services/campaign.service";
import {ActivatedRoute} from "@angular/router";
import {switchMap} from "rxjs/operators";
import {StationService} from "../../../services/station/station.service";

interface SlideEntry {
    slide: Slide;
    valid: boolean
}

@Component({
    selector: 'app-simple-campaign-editor',
    templateUrl: './simple-campaign-editor.component.html',
    styleUrls: ['./simple-campaign-editor.component.scss'],
    host: {'class': 'item-content'}
})
export class SimpleCampaignEditorComponent extends ContentHost implements OnInit {

    @ViewChild("slideEditor", {read: ViewContainerRef}) container;

    @ViewChild(ContentComponent)
    content: ContentComponent<SlideEntry[]>;

    @ViewChild(AddSlideComponent)
    addSlideModal: AddSlideComponent;

    selectedSlide: SlideEntry;

    campaignId: string;

    editable = false;

    sortOptions = {
        disabled: !this.editable,
        onUpdate: (event: any) => {
            this.content.setDirty();
        }
    };

    constructor(private fb: FormBuilder, private resolver: ComponentFactoryResolver,
                private stationService: StationService, private route: ActivatedRoute,
                private campaignService: CampaignService) {
        super();
    }

    ngOnInit() {
        this.route.parent.params.subscribe(params => {
            this.campaignId = params['id'];
        });
    }

    getCampaignSlides(): Observable<any> {
        return this.campaignService.getCampaign(this.campaignId)
            .pipe(
                switchMap(campaign => {
                    this.editable = !campaign.enabled;
                    this.sortOptions = {
                        disabled: !this.editable,
                        onUpdate: (event: any) => {
                            this.content.setDirty();
                        }
                    };

                    return this.campaignService.getCampaignSlides(campaign.campaignId)
                        .map(slides => slides.map(slide => <SlideEntry>{slide: slide, valid: true}))
                        .do((slides) => {
                        }, error => {}, () => {
                            if(this.content.item.length > 0)
                                this.selectSlide(this.content.item[0]);
                        })
                }));
    }

    updateCampaignSlides(): Observable<any> {
        for(let entry of this.content.item) {
            if (!this.validateSlide(entry.slide)) {
                entry.valid = false;
                return null;
            }
        }

        return this.campaignService.updateCampaignSlides(this.campaignId, this.content.item
            .map(entry => entry.slide))
            .do(res => {
                for(let e of this.content.item) {
                    e.valid = true;
                }
            });
    }

    public deleteSlide() {
        this.content.setDirty();

        if(this.selectedSlide == null || !this.editable)
            return;

        this.content.item = this.content.item.filter(value => {
            if (value == this.selectedSlide)
                return false;
            else
                return true;
        });

        this.container.clear();
    }

    public makeTerminal() {
        this.content.setDirty();

        if(this.selectedSlide == null || !this.editable)
            return;

        for(let slide of this.content.item) {
            slide.slide.terminal = false;
        }

        this.selectedSlide.slide.terminal = true;
    }

    private validateSlide(slide): boolean {
        if (!slide.title[this.stationService.getDefaultLocale()]) {
            this.content.showError("Slide title is missing");
            return false;
        }

        if(slide.type == "MultiChoice") {
            if (slide.choices.length < 1) {
                this.content.showError("Choices are empty");
                return false;
            }
        }

        return true;
    }

    public openAddSlideDialog() {
        if(!this.editable)
            return;

        this.addSlideModal.open();
    }

    public addSlide(slideType) {
        this.content.setDirty();

        var slide;

        switch (slideType) {
            case "Info":
                slide = new InfoSlide();
                break;
            case "Text":
                slide = new TextSlide();
                break;
            case "Audio":
                slide = new AudioSlide();
                break;
            case "Video":
                slide = new VideoSlide();
                break;
            case "MultiChoice":
                slide = new MultiChoiceSlide();
                break;
            case "Image":
                slide = new ImageSlide();
                break;
            default:
                console.error("Invalid slide");
            return;
        }

        let entry = <SlideEntry>{slide: slide, valid: true};
        this.content.item.push(entry);
        this.selectSlide(entry);
    }

    selectSlide(entry: SlideEntry) {

        this.selectedSlide = entry;
        this.container.clear();

        var factory = null;

        let slide = entry.slide;

        if (slide.type == "Info") {
            factory = this.resolver.resolveComponentFactory(InfoSlideEditorComponent);
        } else if (slide.type == "MultiChoice") {
            factory = this.resolver.resolveComponentFactory(MultiChoiceEditorComponent);
        } else if (slide.type == "Text") {
            factory = this.resolver.resolveComponentFactory(TextSlideEditorComponent);
        } else if (slide.type == "Image") {
            factory = this.resolver.resolveComponentFactory(ImageSlideEditorComponent);
        } else if (slide.type == "Audio") {
            factory = this.resolver.resolveComponentFactory(AudioSlideEditorComponent);
        }
        else
            return;

        let instance = this.container.createComponent(factory).instance;
        instance.slide = slide;
        instance.content.isLoading = false;
        instance.content.isInline = true;
        instance.content.hasLoadingState = false;
        instance.content.onChanged.subscribe((value) => this.content.setDirty());
    }
}
