import {Component, OnInit, ViewChild} from '@angular/core';
import {AdminService} from "../../services/admin.service";
import {FormBuilder, FormGroup, NgModel, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../services/auth/auth.service";
import {Station, StationLocale} from "../../model/station";
import {ContentComponent, ContentHost} from "../../components/content/content.component";
import {Observable} from "rxjs/Observable";
import * as moment from 'moment-timezone';
import {AddLanguageComponent} from "../../ui/common/add-language/add-language.component";
import {Callback} from "../../utility/callback";
import {CreateImage} from "../../model/image";
import {StationService} from "../../services/station/station.service";

@Component({
    selector: 'app-station-details',
    templateUrl: './station-details.component.html',
    styleUrls: ['./station-details.component.scss'],
    host: {'class': 'item-content'}
})
export class StationDetailsComponent extends ContentHost implements OnInit {

    @ViewChild(ContentComponent)
    content: ContentComponent<Station>;

    @ViewChild(AddLanguageComponent)
    addLanguageModal: AddLanguageComponent;

    form: FormGroup;

    availableTimeZones = moment.tz.names();

    readonly uploadImageUrl = "/api/uploads/station-cover";

    processImageCallback: Callback<CreateImage, string> = new Callback(this, this.processImage);

    name: string;

    // Input fields

    constructor(private fb: FormBuilder, private route: ActivatedRoute, private router: Router,
                private authService: AuthService, private adminService: AdminService,
                private stationService: StationService) {
        super();

        this.form = this.fb.group({
            name: ['', [Validators.required, Validators.min(2), Validators.maxLength(32) ]],
            displayName: ['', [Validators.required, Validators.min(2), Validators.maxLength(32) ]],
            domainName: ['', [Validators.required,  Validators.min(2), Validators.maxLength(128) ]],
            enabled: [false],
            timeZone: ['', [Validators.required]],
            defaultLocale: [null, [Validators.required]]
        });
    }

    ngOnInit() {
        this.route.parent.params.subscribe(params => {
            this.name = params['name'];
        });

        this.addLanguageModal.onFinish.subscribe(value => {
            this.addLocale(value);
        });
    }

    getStation(): Observable<Station> {
        return this.adminService.getStation(this.name)
            .do((station) => {
                this.form.setValue({
                    name: station.name,
                    displayName: station.displayName,
                    domainName: station.domainName,
                    enabled: station.enabled,
                    timeZone: station.timeZone,
                    defaultLocale: station.defaultLocale
                });
            })
    }

    updateStation(): Observable<any> {
        return this.adminService.updateStation(this.content.item)
            .do((item) => {},
                (error) => {
                    if (error.status == 409) {
                        if (error.error.errorCode == 'nameExists') {
                            this.form.get("name").setErrors({nameExists: true});
                        }
                    } if(error.status == 400) {
                        if(error.error.errorCode == 'invalidTimeZone') {
                            this.form.get("timeZone").setErrors({invalidTimeZone: true})
                        }

                    } else {
                        this.content.showError("Update failed");
                    }
                }, () => {});
    }

    enabled(value: boolean) {
        this.adminService.enableStation(this.content.item.stationId, value)
            .subscribe();
    }

    deleteStation() {
        this.adminService.deleteStation(this.content.item.stationId)
            .subscribe(() => {
                this.authService.refresh();
                this.router.navigate(['/organisation/stations'])
            })
    }

    addLocale(locale: string) {
        this.content.setDirty();

        if (!this.content.item.locales.find(value => value.locale == locale)) {
            this.content.item.locales.push({locale: locale, disabled: false});
        }
    }

    setCoverImage(value: any) {
        this.content.setDirty();

        if(value != null) {
            this.content.item.coverImageId = value.imageId;
        } else {
            this.content.item.coverImageId = null;
        }
    }

    removeLocale(locale: StationLocale) {
        this.content.setDirty();

        this.content.item.locales =
            this.content.item.locales.filter(value => value.locale != locale.locale)
    }

    processImage(createImage: CreateImage): Observable<string> {
        return this.stationService.processCoverImage(this.content.item.stationId, createImage);
    }

    onStationDeleted() {
        this.router.navigate(['../../'], {relativeTo: this.route});
    }

}
