import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ClrModal} from "@clr/angular";
import {AdminService} from "../../services/admin.service";
import {Router} from "@angular/router";
import {MemberListComponent} from "../member-list/member-list.component";
import {Observable} from "rxjs/Observable";
import {Subject} from "rxjs/Subject";
import {NgModel} from "@angular/forms";
import {AfterViewInit} from "@angular/core/src/metadata/lifecycle_hooks";
import {HttpErrorResponse} from "@angular/common/http";
import {MemberService} from "../../services/member.service";

@Component({
  selector: 'app-add-member',
  templateUrl: './add-member.component.html',
  styleUrls: ['./add-member.component.scss']
})
export class AddMemberComponent implements OnInit, AfterViewInit {

    @ViewChild("#modal") modal: ClrModal;

    modalOpen: boolean = false;

    name: string;

    displayName: string;

    email: string;

    // Input fields

    @ViewChild("nameInput")
    nameInput: NgModel;

    @ViewChild("displayNameInput")
    displayNameInput: NgModel;

    @ViewChild("emailInput")
    emailInput: NgModel;

    // Observables

    readonly onMemberCreated: Subject<any> = new Subject();

    readonly onError: Subject<any> = new Subject();

    constructor(private memberService: MemberService) { }

    ngOnInit() {
    }

    ngAfterViewInit() {
    }

    add() {

    }

    close () {
        this.modalOpen = false;
        this.reset();
    }

    reset() {
        this.name = "";
        this.displayName = "";
        this.email = "";

        this.nameInput.reset();
        this.displayNameInput.reset();
        this.emailInput.reset();
    }
}
