export class User {
    userId: string;
    username: string;
    email: string;
    disabled: boolean;
    internal: boolean;
    devices: Device[];
}

export class UserStatistics {
    total: number;
    anonymous: number;
    registered: number;
    withFacebook: number;
    genderDistribution: any;
    osDistribution: any;
    topLocations: any;
    birthDecades: any;
    createdHistogram: any;
    lastSeenHistogram: any;
}

export class Device {
    deviceId: string;
    os: string;
    deviceModel: string;
    osVersion: string;
    appVersion: string;
    apiVersion: string;
    locale: string;
    lastSeen: string;
}

export class NumericRange {
    from: number;
    to: number;

    constructor(from: number, to: number) {
        this.from = from;
        this.to = to;
    }
}

export class UserFilter {
    genders: string[];
    devices: string[];
    options: string[];
    ageRange: NumericRange;

    constructor() {
        this.genders = [];
        this.devices = [];
        this.options = [];
        this.ageRange = new NumericRange(null, null);
    }
}
