import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ClrModal} from "@clr/angular";


export interface Input {
    type: string;
    name: string;
    online: boolean;
    config: any;
}

@Component({
  selector: 'app-add-input-modal',
  templateUrl: './add-input-modal.component.html',
  styleUrls: ['./add-input-modal.component.scss']
})
export class AddInputModalComponent implements OnInit {

    @ViewChild("modal") modal: ClrModal;

    modalOpen: boolean;

    form: FormGroup;

    @Output("onFinish")
    onFinish: EventEmitter<Input> = new EventEmitter<Input>();

    urlPattern = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';

    constructor(private fb: FormBuilder) {
        this.form = fb.group({
            "type": [],
            "name": ['', [Validators.required]],
            "source": ['', [Validators.required, Validators.pattern(this.urlPattern)]],
        });
    }

    ngOnInit() {
    }

    open() {
        this.modal.open();
    }


    public finish() {
        this.onFinish.emit({
            type: this.form.get("type").value,
            online: false,
            name: this.form.get("name").value,
            config: {
                source: this.form.get("source").value
            }
        });

        this.reset();
        this.close();
    }

    public reset() {
        this.form.reset()
    }
    public close() {
        this.modal.close();
        this.reset();
    }
}
