import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {NgModel} from "@angular/forms";

@Component({
  selector: 'app-add-static-stream',
  templateUrl: './add-static-stream.component.html',
  styleUrls: ['./add-static-stream.component.scss']
})
export class AddStaticStreamComponent implements OnInit {

    modalOpen: boolean = false;

    name: string;

    source: string;

    @Output("onAdd")
    onAdd: EventEmitter<any> = new EventEmitter<any>();

    // Input fields

    @ViewChild("nameInput")
    nameInput: NgModel;

    @ViewChild("sourceInput")
    displayNameInput: NgModel;

    ngOnInit() {
    }

    public open() {
        this.modalOpen = true;
    }

    public close() {
        this.modalOpen = false;
    }

    add() {
        this.close();
        this.onAdd.emit({name: this.name, source: this.source});
    }

}
