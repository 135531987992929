import {Component, OnInit, ViewChild} from '@angular/core';
import {ContentComponent} from "../../../components/content/content.component";
import {StationDocument, StationEmailTemplate} from "../../../model/station";
import {FormBuilder, FormGroup} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {StationService} from "../../../services/station/station.service";
import {Observable} from "rxjs";

@Component({
  selector: 'app-station-email-template',
  templateUrl: './station-email-template.component.html',
  styleUrls: ['./station-email-template.component.scss']
})
export class StationEmailTemplateComponent implements OnInit {


    @ViewChild(ContentComponent)
    content: ContentComponent<StationEmailTemplate>;

    form: FormGroup;

    stationName: string;

    templateType: string;

    contentModule = { toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'indent': '-1'}, { 'indent': '+1' }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'font': [] }],
            [{ 'align': [] }],
            ['link']
        ]};

    plainModule = { toolbar: null};

    constructor(private fb: FormBuilder, private route: ActivatedRoute, private stationService: StationService) {
        this.form = fb.group({
            subject: [null],
            html: [null],
            plain: [null]
        });
    }


    ngOnInit() {
        this.route.parent.parent.params.subscribe(params => {
            this.stationName = params['name'];
        });

        this.route.data.subscribe(data => {
            this.templateType = data['templateType'];
            this.content.title = data['title']
        });
    }

    getTemplate(): Observable<StationEmailTemplate> {
        return this.stationService.getEmailTemplate(this.stationName, this.templateType);
    }

    updateTemplate(): Observable<any> {
        return this.stationService.updateEmailTemplate(this.stationName, this.templateType, this.content.item);
    }

}
