import * as moment from "moment";


export class WeekTime {

    public static readonly SECONDS_PER_WEEK: number = 1440 * 60 * 7;

    public static readonly SECONDS_PER_DAY: number = 1440 * 60;

    public static readonly SECONDS_PER_HOUR: number = 3600;

    public days: number;

    public hours: number;

    public minutes: number;

    public seconds: number;

    public nextWeek: boolean;

    constructor(days: number, hours: number, minutes: number, seconds: number, nextWeek: boolean) {
        this.days = days % 7;

        this.hours = hours;
        this.minutes = minutes;
        this.seconds = seconds;

        this.nextWeek = nextWeek;
    }

    public static fromDate(date: Date, nextWeek: boolean = false): WeekTime {
        let d = moment(date);
        let day = d.isoWeekday() - 1;
        return new WeekTime(day, d.hours(), d.minutes(), d.seconds(), nextWeek);
    }


    public static fromSeconds(seconds: number): WeekTime {
        let secondsInWeek = seconds % this.SECONDS_PER_WEEK;

        let secsInDay = secondsInWeek % this.SECONDS_PER_DAY;
        let secs = secondsInWeek % 60;

        let mins = Math.floor(secsInDay / 60) % 60;
        let hours = Math.floor(secsInDay / this.SECONDS_PER_HOUR);
        let days = Math.floor(seconds / this.SECONDS_PER_DAY);

        let nextWeek = false;
        if(seconds >= this.SECONDS_PER_WEEK)
            nextWeek = true;

        return new WeekTime(days, hours, mins, secs, nextWeek);
    }

    public getSeconds(): number {
        let secs = this.days * WeekTime.SECONDS_PER_DAY + this.hours * WeekTime.SECONDS_PER_HOUR + this.minutes * 60 + this.seconds;
        if(this.nextWeek)
            secs += WeekTime.SECONDS_PER_WEEK;
        return secs;
    }

    public static fromTimeString(day: number, timeString: string, nextWeek: boolean): WeekTime {
        let time = moment(timeString, "HH:mm:ss");
        return new WeekTime(day, time.hours() , time.minutes(), time.seconds(), nextWeek);
    }

    public getTimeString(includeSeconds:boolean = true): string {
        let out: string = "";

        if(this.hours == 24)
            this.hours = 0;

        if(this.hours < 10)
            out += "0" + this.hours + ":";
        else
            out += this.hours + ":";

        if(this.minutes < 10)
            out += "0" + this.minutes;
        else
            out += this.minutes;

        if(!includeSeconds)
            return out;

        out += ":";

        if(this.seconds < 10)
            out += "0" + this.seconds;
        else
            out += this.seconds;

        return out;
    }
}
