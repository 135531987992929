import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-radio-info',
  templateUrl: './radio-info.component.html',
  styleUrls: ['./radio-info.component.scss']
})
export class RadioInfoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
