import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'resolveImage'
})
export class ResolveImagePipe implements PipeTransform {

  transform(value: any, arg1: any): any {
      if(value == null) {
          return null
      } else {
          if (arg1 == null) {
              return "/api/images/" + value;
          } else {
              return "/api/images/" + value + "?type=" + arg1;
          }
      }
  }
}
