import {
    AfterViewInit, Component, EventEmitter, Input, OnInit, Output,
    ViewChild
} from '@angular/core';
import {ClrModal} from "@clr/angular";
import {WeekTime} from "../../utility/week-time";
import {WeekRange} from "../../utility/week-range";
import {ScheduleProvider} from "../schedule/schedule-provider";
import {CreateShowSlot, Show} from "../../model/show";
import {ShowService} from "../../services/show.service";
import {StationService} from "../../services/station/station.service";
import {WeekTimeRangeComponent} from "../../components/week-time-range/week-time-range.component";
import {concat, Observable, of, Subject} from "rxjs";
import {Schedule, ScheduleService} from "../../services/schedule.service";
import {catchError, debounceTime, distinctUntilChanged, switchMap, tap} from "rxjs/operators";
import {DialogComponent} from "../../components/dialogs/dialog/dialog.component";

@Component({
  selector: 'app-add-slot',
  templateUrl: './add-slot.component.html',
  styleUrls: ['./add-slot.component.scss']
})
export class AddSlotComponent implements OnInit, AfterViewInit {

    @ViewChild("modal") modal: DialogComponent;

    @ViewChild(WeekTimeRangeComponent)
    weekTimeRange: WeekTimeRangeComponent;

    shows$: Observable<Show[]>;

    loading: boolean = false;

    @Output("onFinish")
    onFinish: EventEmitter<any> = new EventEmitter<any>();

    @Output("onError")
    onError: EventEmitter<any> = new EventEmitter<any>();

    range: WeekRange;

    rangeValid: boolean;

    showInput$ = new Subject<string>();

    selectedShow: any;

    provider: ScheduleProvider;

    query: string;

    channelId: string;

    week: number;

    year: number;

    isScheduleSlot: boolean = false;

    constructor(private showService: ShowService, public stationService: StationService, private scheduleService: ScheduleService) { }

    ngOnInit() {
        this.weekTimeRange.rangeChange.subscribe(range => {
            if(this.provider != null)
                this.rangeValid = this.provider.checkRangeValid(this.range);
        });

        this.loadShows();
    }

    ngAfterViewInit() {
    }

    private loadShows() {
        this.shows$ = concat(
            of([]),
            this.showInput$.pipe(
                debounceTime(200),
                distinctUntilChanged(),
                tap(() => this.loading = true),
                switchMap(input => this.showService.getShows(10, null, input)
                    .map(res =>
                        res.items.map(value => {
                            value.title = value.title[this.stationService.getDefaultLocale()];
                            return value;
                        })
                    )
                    .pipe(
                        catchError(() => of([])),
                        tap(() => this.loading = false)
                    ))
            )
        );
    }

    public open(provider: ScheduleProvider, range: WeekRange, channelId: string, week: number, year: number) {
        this.provider = provider;
        this.range = range;
        this.channelId = channelId;
        this.week = week;
        this.year = year;

        if(channelId == null)
            this.isScheduleSlot = true;

        this.modal.open();
    }

    reset() {
        this.range = new WeekRange(new WeekTime(0,0,0,0, false),
            new WeekTime(0, 1, 0, 0, false));
    }

    validate() {
        return this.selectedShow != null && this.rangeValid;
    }

    close() {
        this.selectedShow = null;
    }

    submit() {
        let slot = <CreateShowSlot>{
            slotId: null,
            showId: this.selectedShow.showId,
            from: this.range.from.getSeconds(),
            to: this.range.to.getSeconds(),
            title: {},
            subTitle: {},
            summary: {},
            content: null
        };

        if(!this.isScheduleSlot) {
            return this.scheduleService.createShowSlot(this.channelId, this.year, this.week, slot);
        } else {
            return of(this.selectedShow)
        }
    }

    failed(error: any) {
        this.onError.emit(error);
    }
}
