import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Feed} from "../model/feed";
import {Observable} from "rxjs/Observable";
import {CreatePost, Post} from "../model/post";
import {environment} from "../../environments/environment";
import {CreateImage, CreateImageResponse} from "../model/image";
import {CampaignContentEntry, ImagesContentEntry} from "../model/content";
import {UserFilter} from "../model/user";

@Injectable({
  providedIn: 'root'
})
export class PostService {

    constructor(private http: HttpClient) {
    }

    public createPost(post: CreatePost): Observable<any> {
        return this.http.post("/api/posts", post)
    }

    public getPost(shortId: string): Observable<Post> {
        return this.http.get<Post>(environment.apiEndpoint + "/api/posts/shortId=" + shortId);
    }

    public deletePost(id: string): Observable<any> {
        return this.http.delete(environment.apiEndpoint + "/api/posts/id=" + id);
    }

    public getPosts(limit: number, offset: string, query: string, feed: string, disabled: boolean, 
        published: boolean, descending: boolean): Observable<any> {
        let params = new HttpParams();
        if(limit != null)
            params = params.set("limit", String(limit));
        if(offset != null)
            params = params.set("offset", offset);
        if(query != null)
            params = params.set("query", query);
        if(feed != null && feed != "all")
            params = params.set("feed", feed);
        if(disabled != null)
            params = params.set("disabled", String(disabled));
        if(published != null)
            params = params.set("published", String(published));
        if(descending != null)
            params = params.set("descending", String(descending));

        return this.http.get(environment.apiEndpoint + "/api/posts", { params: params })
    }

    public updatePost(post: Post): Observable<any> {
        // TODO: cleanup hack
        let entries;
        if (post.content != null && post.content.entries != null) {
            entries = post.content.entries.map(value => {
                if (value.type == "Images") {
                    return {
                        type: "Images",
                        images: (<ImagesContentEntry>value).images.map(value => {
                            return {
                                uploadUrl: value.uploadUrl,
                                imageId: value.imageId,
                                license: value.license != null ? value.license.name : null,
                                copyright: value.copyright,
                            }
                        })
                    }
                } else if (value.type == "Campaign") {
                    return {
                        type: "Campaign",
                        campaignId:  (<CampaignContentEntry>value).campaignId
                    }
                } else {
                    return value;
                }
            });
        }

        return this.http.put(environment.apiEndpoint + "/api/posts/id=" + post.postId, {
            groupId: post.groupId,
            authorId: post.authorId,
            feedId: post.feedId,
            title: post.title,
            subTitle: post.subTitle,
            coverImageId: post.coverImageId,
            url: post.url,
            content: post.content != null ? {
                text: post.content.text,
                entries: entries,
            } : null,
            publishDate: post.publishDate,
            unpublishDate: post.unpublishDate,
            enabled: post.enabled,
            internal: post.internal
            });
    }

    public processCoverImage(postId: string, createImage: CreateImage): Observable<string> {
        return this.http.post<CreateImageResponse>("/api/posts/id=" + postId + "/coverImage", createImage)
            .map(res => res.imageId);
    }

    public enablePost(id: string, value: boolean): Observable<any> {
        return this.http.put(environment.apiEndpoint + "/api/posts/id=" + id, {
            disabled: !value
        });
    }

    public createFeed(name: string, displayName: string): Observable<any> {
        return this.http.post(environment.apiEndpoint + "/api/feeds",
            {name: name, displayName: name});
    }

    public getFeeds(): Observable<Feed[]> {
        return this.http.get<Feed[]>("/api/feeds");
    }

    public deleteFeed(id: string): Observable<any> {
        return this.http.delete(environment.apiEndpoint + "/api/feeds/id=" + id);
    }

    // promotion

    public promotePost(postId: string, title: string, message: string, filter: UserFilter): Observable<any> {
        return this.http.post("/api/posts/id=" + postId + "/promote", {
            title: title, message: message, userFilter: filter
        });
    }
}
