import {Component, OnInit, ViewChild} from '@angular/core';
import {Artist, Track} from "../../../model/track";
import {Observable, of} from "rxjs";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AddArtistComponent} from "../add-artist/add-artist.component";
import {ContentComponent} from "../../../components/content/content.component";

@Component({
  selector: 'app-create-track',
  templateUrl: './create-track.component.html',
  styleUrls: ['./create-track.component.scss']
})
export class CreateTrackComponent implements OnInit {

    @ViewChild(ContentComponent)
    content: ContentComponent<Track>;

    @ViewChild(AddArtistComponent)
    addArtistModal: AddArtistComponent;

    form: FormGroup;

    releaseForm: FormGroup;

    metatag: string;

    artists: Array<Artist> = [
    ];

    constructor(private fb: FormBuilder) {
        this.form = fb.group({
            title: ['', [Validators.required]],
            artistCredits: ['', [Validators.required]],

            isrc: [''],
            disambiguation: ['']
        });

        this.releaseForm = fb.group({
            title: [''],
            released: [null],
            type: [null]
        });
    }

    ngOnInit() {
    }

    newTrack(): Observable<Track> {
        return of(new Track());
    }

    createTrack(): Observable<any> {
        return of(null);
    }

    onMetatagChange() {
        this.metatag = this.form.get("artistCredits").value + " - " + this.form.get("title").value
    }

    addArtist() {
        this.addArtistModal.open();
    }
}
