import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {AuthService} from "../services/auth/auth.service";
import {Station} from "../model/station";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(private router: Router, public authService: AuthService) {
      this.refresh();
  }

  activeStation: string;

  availableStations: Station[];

  ngOnInit() {
      this.refresh();
  }

  refresh() {
      this.activeStation = this.authService.getActiveStation();
      this.availableStations = this.authService.getAvailableStations()
  }

  setActiveStation(station: Station) {
    this.authService.setActiveStation(station);
    this.refresh();
  }

  openProfile() {
    this.router.navigate(['/profile'])
  }

  logout() {
      this.authService.logout()
          .subscribe();

      this.router.navigate(['/login'])
  }
}
