import {Component, OnInit, ViewChild} from '@angular/core';
import {Subject} from "rxjs/Subject";
import {HttpErrorResponse} from "@angular/common/http";
import {AdminService} from "../../services/admin.service";
import {NgModel} from "@angular/forms";
import {ClrModal} from "@clr/angular";

@Component({
  selector: 'app-add-role',
  templateUrl: './add-role.component.html',
  styleUrls: ['./add-role.component.scss']
})
export class AddRoleComponent implements OnInit {

    @ViewChild("#modal") modal: ClrModal;

    modalOpen: boolean = false;

    role: string;

    // Input fields

    @ViewChild("role")
    roleInput: NgModel;

    // Observables

    readonly onRoleSelected: Subject<any> = new Subject();

    readonly onError: Subject<any> = new Subject();

    constructor(private adminService: AdminService) { }

    ngOnInit() {
    }

    open() {
        this.modalOpen = true;
    }

    add() {
        this.close();
        this.onRoleSelected.next(this.role)
    }

    close () {
        this.modalOpen = false;
        this.reset();
    }

    reset() {


        this.roleInput.reset();
    }
}
