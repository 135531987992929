import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Track, TrackReport, TrackWarning} from "../../model/track";
import {Observable} from "rxjs";
import {PagedResult} from "../admin.service";
import {HttpParams} from "../../../../node_modules/@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class TrackService {

    constructor(private http: HttpClient) { }

    // Tracks

    public createTrack(track: Track): Observable<any> {
        return this.http.post("/api/tracks", track)
    }

    public getTracks(pageSize: number, offset: string = null, query: string = null): Observable<Track[]> {
        let params = new HttpParams();
        if(pageSize != null)
            params = params.set("pageSize", String(pageSize));
        if(offset != null)
            params = params.set("offset", offset);
        if(query != null)
            params = params.set("query", query);

        return this.http.get<Track[]>("/api/tracks", { params: params})
    }

    public getTrackById(id: String): Observable<Track> {
        return this.http.get<Track>("/api/tracks/track_id=" + id);
    }

    public getTrackByMetatag(metatag: string): Observable<Track> {
        return this.http.get<Track>("/api/tracks/metatag=" + metatag);
    }

    public updateTrack(track: Track): Observable<any> {
        return this.http.put("/api/tracks/track_id=" + track.trackId, track);
    }

    public deleteTrack(id: string): Observable<any> {
        return this.http.delete("/api/tracks/track_id=" + id);
    }

    public reindexAllTracks(): Observable<any> {
        return this.http.post("/api/tracks/reindex-all", {});
    }

    // Warnings

    public getTrackWarnings(pageSize: number, offset: string = null): Observable<PagedResult<TrackWarning>> {
        let params = new HttpParams();
        if(pageSize != null)
            params = params.set("pageSize", String(pageSize));
        if(offset != null)
            params = params.set("offset", offset);

        return this.http.get<PagedResult<TrackWarning>>("/api/track-warnings", { params: params});
    }

    public getTrackWarningsByTrack(trackId: string, pageSize: number, offset: string = null): Observable<PagedResult<TrackWarning>> {
        let params = new HttpParams();
        if (pageSize != null)
            params = params.set("pageSize", String(pageSize));
        if (offset != null)
            params = params.set("offset", offset);


        return this.http.get<PagedResult<TrackWarning>>("/api/track-warnings/track_id=" + trackId, {params: params});
    }

    public clearTrackWarning(trackId: string, type: string): Observable<any> {
        return this.http.delete("api/track-warnings/track_id=" + trackId + "/" + type);
    }

    public clearTrackWarnings(trackId: string, types: string[]): Observable<any> {
        return this.http.post("api/track-warnings/track_id=" + trackId + "/clear", types);
    }

    // Reports

    public getTrackReports(pageSize: number, offset: string = null, includeAck: boolean = false): Observable<PagedResult<TrackReport>> {
        let params = new HttpParams();
        if(pageSize != null)
            params = params.set("pageSize", String(pageSize));
        if(offset != null)
            params = params.set("offset", offset);

        if(includeAck)
            params = params.set("includeAck", "true");
        else
            params = params.set("includeAck", "false");

        return this.http.get<PagedResult<TrackReport>>("/api/track-reports", { params: params});
    }

    public getTrackReportsByTrack(trackId: string, pageSize: number, offset: string = null, includeAck: boolean = false): Observable<PagedResult<TrackReport>> {
        let params = new HttpParams();
        if (pageSize != null)
            params = params.set("pageSize", String(pageSize));
        if (offset != null)
            params = params.set("offset", offset);
        if(includeAck)
            params = params.set("includeAck", "true");
        else
            params = params.set("includeAck", "false");

        return this.http.get<PagedResult<TrackReport>>("/api/track-reports/trackId=" + trackId, {params: params});
    }

    public acknowledgeReport(reportId: string): Observable<any> {
        return this.http.post("/api/track-reports/report_id=" + reportId + "/acknowledge", {});
    }

    public unacknowledgeReport(reportId: string): Observable<any> {
        return this.http.post("/api/track-reports/report_id=" + reportId + "/unacknowledge", {});
    }

    public deleteReport(reportId: string): Observable<any> {
        return this.http.delete("/api/track-reports/report_id=" + reportId);
    }
}
