import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {Choice, MultiChoiceSlide, Slide} from "../../../../model/campaign";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ContentComponent} from "../../../../components/content/content.component";
import {Observable, of} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {CampaignService} from "../../../../services/campaign.service";
import {Callback} from "../../../../utility/callback";
import {CreateImage} from "../../../../model/image";
import {ContentItemsComponent} from "../../../../components/content-items/content-items.component";

@Component({
  selector: 'app-multi-choice-editor',
  templateUrl: './multi-choice-editor.component.html',
  styleUrls: ['./multi-choice-editor.component.scss']
})
export class MultiChoiceEditorComponent implements OnInit, AfterViewInit {


    @ViewChild(ContentComponent)
    content: ContentComponent<MultiChoiceSlide>;

    @ViewChild("choicesComponent")
    choicesComponent: ContentItemsComponent<Choice>;

    @Input()
    slide: MultiChoiceSlide;

    form: FormGroup;

    campaignId: string;

    readonly imageUploadUrl = "/api/uploads/campaign-slide-image";

    processImageCallback: Callback<CreateImage, string> = new Callback(this, this.processImage);


    constructor(private fb: FormBuilder, private route: ActivatedRoute,
                private campaignService: CampaignService) {
        this.form = fb.group({
            title: ['', [Validators.required, Validators.minLength(1)]],
            skippable: [false],
            positiveButtonText: [''],
            negativeButtonText: [''],
            multi: [false]
        })
    }


    ngOnInit() {
        this.route.parent.params.subscribe(params => {
            this.campaignId = params['id'];
        });
    }

    ngAfterViewInit() {
        this.content.setItem(this.slide);
    }

    addChoice() {
        this.content.setDirty();

        if (this.slide == null)
            return;

        if (this.slide.choices == null)
            this.slide.choices = [];

        this.slide.choices.push({text: {}});
    }

    removeChoice(choice) {
        this.content.setDirty();

        if(this.slide == null || this.slide.choices == null)
            return;

        this.slide.choices = this.slide.choices.filter(value => {
            if (value == choice)
                return false;
            else
                return true;
        });
    }

    getSlide(): Observable<Slide> {
        return of(this.slide);
    }

    processImage(createImage: CreateImage): Observable<string> {
        return this.campaignService.processSlideImage(this.campaignId, createImage);
    }

    setCoverImage(value: any) {
        this.content.setDirty();

        if(value != null) {
            this.content.item.coverImageId = value.imageId;
        } else {
            this.content.item.coverImageId = null;
        }
    }
}
