import {Component, OnInit, ViewChild} from '@angular/core';
import {AdminService} from "../../services/admin.service";
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormGroup, NgModel, Validators} from "@angular/forms";
import {Member, MemberPermission} from "../../model/member";
import {ContentComponent, ContentHost} from "../../components/content/content.component";
import {Observable} from "rxjs/Observable";
import {AddRoleComponent} from "../add-role/add-role.component";
import {SetPasswordComponent} from "../set-password/set-password.component";
import {AuthService} from "../../services/auth/auth.service";
import 'rxjs/add/operator/mergeMap';
import {Callback} from "../../utility/callback";
import {CreateImage} from "../../model/image";
import {MemberService} from "../../services/member.service";

@Component({
    selector: 'app-member-details',
    templateUrl: './member-details.component.html',
    styleUrls: ['./member-details.component.scss'],
    host: {'class': 'item-content'}
})
export class MemberDetailsComponent extends ContentHost  implements OnInit {

    @ViewChild(ContentComponent)
    content: ContentComponent<Member>;

    @ViewChild("addRoleModal")
    addRoleModal: AddRoleComponent;

    @ViewChild("setPasswordModal")
    setPasswordModal: SetPasswordComponent;

    form: FormGroup;

    name: string;

    readonly imageUploadUrl = "/api/uploads/member-profile";

    imageProcessUrl: string;

    processImageCallback: Callback<CreateImage, string> = new Callback(this, this.processImage);

    constructor(private fb: FormBuilder, private route: ActivatedRoute,private router: Router,
                private authService: AuthService, private memberService: MemberService) {
        super()
        this.form = this.fb.group({
            username: ['', [Validators.required, Validators.min(2), Validators.maxLength(32) ]],
            displayName: ['', [Validators.required, Validators.min(2), Validators.maxLength(32) ]],
            email: ['', [Validators.required, Validators.email]],
            enabled: [false]
        });
    }

    ngOnInit() {
        this.route.parent.params.subscribe(params => {
            this.name = params['name'];
        });

        this.setPasswordModal.onPasswordChange.subscribe(params => {
            this.memberService.setMemberPassword(this.content.item.memberId, params)
                .subscribe(value => {
                    console.debug("Password updated");
                    // Password updated
                }, error => {
                    // Failed to update password
                    this.content.showError("Failed to set password")
                })
        })
    }

    getMember(): Observable<Member> {
        return this.memberService.getMember(this.name)
            .do((member) => {
                this.imageProcessUrl = "/api/members/" + member.memberId + "/profileImage";
            });
    }

    updateMember(): Observable<any> {
        return this.memberService.updateMember(this.content.item);
    }

    deleteMember() {
        return this.memberService.deleteMember(this.content.item.memberId);
    }

    onMemberDeleted() {
        this.router.navigate(['../'], {relativeTo: this.route});
    }

    setProfileImage(value: any) {
        if(value != null) {
            this.content.item.profileImageId = value.imageId;
        } else {
            this.content.item.profileImageId = null;
        }
    }

    processImage(createImage: CreateImage): Observable<string> {
        return this.memberService.processProfileImage(this.content.item.memberId, createImage);
    }

    setPassword() {
        this.setPasswordModal.open()
    }
}
