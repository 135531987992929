import {Component, OnInit, ViewChild} from '@angular/core';
import {TrackService} from "../../../services/disco/track.service";
import {ContentListComponent} from "../../../components/content-list/content-list.component";
import {TrackReport, TrackWarning} from "../../../model/track";
import {Observable} from "rxjs";
import {PagedResult} from "../../../services/admin.service";

@Component({
  selector: 'app-track-warning-list',
  templateUrl: './track-warning-list.component.html',
  styleUrls: ['./track-warning-list.component.scss']
})
export class TrackWarningListComponent implements OnInit {

    constructor(private trackService: TrackService) { }

    @ViewChild(ContentListComponent)
    content: ContentListComponent<TrackWarning>;

    ngOnInit() {
    }

    getWarnings(pageSize: number, offset: string): Observable<PagedResult<TrackWarning>> {
        return this.trackService.getTrackWarnings(pageSize, offset);
    }

    openWarning(warning: TrackWarning) {
    }

    getTypeString(item: TrackWarning): string {
        if(item.type == "MISSING_COVER_IMAGE") {
            return "Artwork missing";
        } else {
            return "Metadata missing";
        }
    }

    deleteWarning(item: TrackWarning) {
        this.trackService.clearTrackWarning(item.trackId, item.type)
            .subscribe(value => {
                this.content.refresh();
            }, error => {
            })
    }
}
