import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {Observable} from "rxjs";
import {StationService} from "../../../services/station/station.service";
import {ContentComponent} from "../../../components/content/content.component";
import {StationDocument} from "../../../model/station";

@Component({
    selector: 'app-station-document',
    templateUrl: './station-document.component.html',
    styleUrls: ['./station-document.component.scss']
})
export class StationDocumentComponent implements OnInit {

    @ViewChild(ContentComponent)
    content: ContentComponent<StationDocument>;

    form: FormGroup;

    title: string;

    contentModule = { toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'indent': '-1'}, { 'indent': '+1' }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'font': [] }],
            [{ 'align': [] }],
            ['link']
        ]};

    stationName: string;

    docType: string;

    constructor(private fb: FormBuilder, private route: ActivatedRoute, private stationService: StationService) {
        this.form = fb.group({
            contents: [null],
        });
    }

    ngOnInit() {
        this.route.parent.parent.params.subscribe(params => {
            this.stationName = params['name'];
        });

        this.route.data.subscribe(data => {
            this.docType = data['docType'];
            this.title = data['title']
        });
    }

    getDocument(): Observable<StationDocument> {
        return this.stationService.getStationDocument(this.stationName, this.docType);
    }

    updateDocument(): Observable<any> {
        return this.stationService.updateStationDocument(this.stationName, this.docType, this.content.item);
    }
}
