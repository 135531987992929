import {Component, ElementRef, EventEmitter, Input, OnInit, ViewChild} from '@angular/core';
import {DialogComponent} from "../dialogs/dialog/dialog.component";
import {concat, Observable, of, Subject} from "rxjs";
import {Schedule} from "../../services/schedule.service";
import {catchError, debounceTime, distinctUntilChanged, switchMap, tap} from "rxjs/operators";
import {NgSelectComponent} from "@ng-select/ng-select";

@Component({
  selector: 'app-select-dialog',
  templateUrl: './select-dialog.component.html',
  styleUrls: ['./select-dialog.component.scss']
})
export class SelectDialogComponent implements OnInit {

    @ViewChild("modal") modal: DialogComponent;

    @ViewChild("select") select: NgSelectComponent;

    items$: Observable<Schedule[]>;

    loading: boolean = false;

    input$ = new Subject<string>();

    selectedItem: any;

    @Input()
    title: string;

    @Input()
    getItems: (input) => Observable<any>;

    @Input()
    selectItem: (item) => Observable<any>;

    @Input()
    bindValue: string;

    model: any;

    onError = new EventEmitter<any>();

    onFinish = new EventEmitter<any>();

    constructor() { }

    ngOnInit() {
        this.loadItems();
    }

    private loadItems() {
        this.items$ = concat(
            of([]),
            this.input$.pipe(
                debounceTime(200),
                distinctUntilChanged(),
                tap(() => this.loading = true),
                switchMap(input => this.getItems(input)
                    .map(res => res.items)
                    .pipe(
                        catchError(() => of([])),
                        tap(() => this.loading = false)
                    ))
            )
        );
    }

    open() {
        this.selectedItem = null;
        this.modal.open();
        this.select.focus();
    }

    validate() {
        return this.selectedItem != null;
    }

    close () {
        this.selectedItem = null;
    }

    submit() {
        return this.selectItem(this.selectedItem);
    }

    failed(error) {
        this.onError.emit(error);
    }


}
