import {ModuleWithProviders} from '@angular/core/src/metadata/ng_module';
import {RouterModule, Routes} from '@angular/router';

import {DashboardComponent} from "./dashboard/dashboard.component";
import {UserListComponent} from "./user-list/user-list.component";
import {MemberListComponent} from "./general/member-list/member-list.component";
import {StationListComponent} from "./general/station-list/station-list.component";
import {LoginComponent} from "./login/login.component";
import {LayoutComponent} from "./layout/layout.component";
import {ChannelListComponent} from "./radio/channel-list/channel-list.component";
import {AuthGuard} from "./services/auth/auth.guard";
import {ShowListComponent} from "./radio/show-list/show-list.component";
import {ProfileComponent} from "./profile/profile.component";
import {CreateScheduleComponent} from "./radio/create-schedule/create-schedule.component";
import {ChannelItemComponent} from "./radio/channel-item/channel-item.component";
import {ShowItemComponent} from "./radio/show-item/show-item.component";
import {RadioSectionsComponent} from "./ui/radio/radio-sections/radio-sections.component";
import {CreateTrackComponent} from "./ui/disco/create-track/create-track.component";
import {TrackItemComponent} from "./ui/disco/track-item/track-item.component";
import {TrackWarningListComponent} from "./ui/disco/track-warning-list/track-warning-list.component";
import {TrackReportListComponent} from "./ui/disco/track-report-list/track-report-list.component";
import {StationDetailsComponent} from "./general/station-details/station-details.component";
import {OrgaDetailsComponent} from "./ui/orga/orga-details/orga-details.component";
import {MemberDetailsComponent} from "./general/member-details/member-details.component";
import {TracksComponent} from "./ui/disco/tracks/tracks.component";
import {PostsComponent} from "./ui/publisher/posts/posts.component";
import {TrackListComponent} from "./ui/disco/track-list/track-list.component";
import {ChannelsComponent} from "./radio/channels/channels.component";
import {OrgaInfoComponent} from "./ui/orga/orga-info/orga-info.component";
import {ChannelDetailsComponent} from "./radio/channel-details/channel-details.component";
import {EditStreamsComponent} from "./radio/edit-streams/edit-streams.component";
import {StationItemComponent} from "./general/station-item/station-item.component";
import {StationsComponent} from "./ui/orga/stations/stations.component";
import {MemberItemComponent} from "./general/member-item/member-item.component";
import {MembersComponent} from "./ui/orga/members/members.component";
import {ShowsComponent} from "./ui/radio/shows/shows.component";
import {ShowDetailsComponent} from "./radio/show-details/show-details.component";
import {CreatePostComponent} from "./ui/publisher/create-post/create-post.component";
import {PostItemComponent} from "./ui/publisher/post-item/post-item.component";
import {PostDetailsComponent} from "./ui/publisher/post-details/post-details.component";
import {FeedListComponent} from "./ui/publisher/feed-list/feed-list.component";
import {UserItemComponent} from "./ui/social/user-item/user-item.component";
import {PostsListComponent} from "./ui/publisher/posts-list/posts-list.component";
import {UsersComponent} from "./ui/social/users/users.component";
import {UserDetailsComponent} from "./ui/social/user-details/user-details.component";
import {MemberRolesComponent} from "./ui/orga/member-roles/member-roles.component";
import {StationDocsComponent} from "./ui/orga/station-docs/station-docs.component";
import {ScheduleListComponent} from "./ui/radio/schedule-list/schedule-list.component";
import {StationDocumentComponent} from "./ui/orga/station-document/station-document.component";
import {StationTosComponent} from "./ui/orga/station-tos/station-tos.component";
import {StationEmailSettingsComponent} from "./ui/orga/station-email-settings/station-email-settings.component";
import {StationEmailTemplateComponent} from "./ui/orga/station-email-template/station-email-template.component";
import {CreateMemberComponent} from "./ui/orga/create-member/create-member.component";
import {CreateChannelComponent} from "./ui/radio/create-channel/create-channel.component";
import {CreateShowComponent} from "./ui/radio/create-show/create-show.component";
import {ProgramComponent} from "./ui/radio/program/program.component";
import {SchedulesComponent} from "./ui/radio/schedules/schedules.component";
import {EditSlotComponent} from "./ui/radio/edit-slot/edit-slot.component";
import {CreateStationComponent} from "./ui/orga/create-station/create-station.component";
import {StationAppListComponent} from "./ui/orga/station-app-list/station-app-list.component";
import {AddAppComponent} from "./ui/orga/add-app/add-app.component";
import {CampaignsComponent} from "./ui/publisher/campaigns/campaigns.component";
import {CampaignListComponent} from "./ui/publisher/campaign-list/campaign-list.component";
import {CreateCampaignComponent} from "./ui/publisher/create-campaign/create-campaign.component";
import {CampaignItemComponent} from "./ui/publisher/campaign-item/campaign-item.component";
import {CampaignDetailsComponent} from "./ui/publisher/campaign-details/campaign-details.component";
import {SimpleCampaignEditorComponent} from "./ui/publisher/simple-campaign-editor/simple-campaign-editor.component";
import {CampaignStatisticsComponent} from "./ui/publisher/campaign-results/campaign-results.component";
import {CampaignSubmissionListComponent} from "./ui/publisher/campaign-submission-list/campaign-submission-list.component";
import {CampaignSubmissionDetailsComponent} from "./ui/publisher/campaign-submission-details/campaign-submission-details.component";
import {UserStatisticsComponent} from "./ui/social/user-statistics/user-statistics.component";
import {ChannelGroupListComponent} from "./radio/channel-group-list/channel-group-list.component";
import {EditChannelGroupComponent} from "./radio/edit-channel-group/edit-channel-group.component";
import {CanDeactivateGuard} from "./components/can-deactivate.guard";
import {HomeEditorComponent} from "./ui/publisher/home-editor/home-editor.component";
import {EditScheduleComponent} from "./radio/edit-schedule/edit-schedule.component";
import {ChannelGroupItemComponent} from "./radio/channel-group-item/channel-group-item.component";
import {EditChannelGroupListComponent} from "./radio/edit-channel-group-list/edit-channel-group-list.component";

export const ROUTES: Routes = [
    {path: 'login', component: LoginComponent},
    {
        path: '',
        canActivate: [AuthGuard],
        component: LayoutComponent,
        children: [
            { path: 'home', component: DashboardComponent },

            { path: 'profile', component: ProfileComponent },


            /* Organisation */

            { path: 'organisation/info', component: OrgaInfoComponent, children: [
                    { path: '', redirectTo: 'details', pathMatch: 'full'},
                    { path: 'details', component: OrgaDetailsComponent, canDeactivate: [CanDeactivateGuard]},
                ]},

            /* Stations */

            { path: 'organisation/stations', component: StationsComponent, children: [
                    { path: '', redirectTo: 'list', pathMatch: 'full'},
                    { path: 'list', component: StationListComponent},
                ]
            },

            { path: 'organisation/create-station', component: CreateStationComponent },

            { path: 'organisation/stations/edit/:name', component: StationItemComponent, children: [
                    { path: '', redirectTo: 'details', pathMatch: 'full'},
                    { path: 'details', component: StationDetailsComponent, canDeactivate: [CanDeactivateGuard]},
                    { path: 'docs', component: StationDocsComponent, children: [
                            {path: 'tos', component: StationTosComponent },
                            {path: 'privacy', component: StationDocumentComponent, data: { title: 'Privacy', docType: "privacy"}},
                            {path: 'imprint', component: StationDocumentComponent, data: { title: 'Imprint', docType: "imprint"}},
                            {path: 'email-settings', component: StationEmailSettingsComponent },
                            {path: 'email-templates/validation', component: StationEmailTemplateComponent, data: { title: 'Validation Email', templateType: "validation"}},
                            {path: 'email-templates/reset-password', component: StationEmailTemplateComponent, data: { title: 'Password Reset Email', templateType: "reset"}},
                        ]},
                    { path: 'apps', component: StationAppListComponent},
                ]
            },

            { path: 'organisation/stations/add-app', component: AddAppComponent},

            /* Members */

            { path: 'organisation/members', component: MembersComponent, children: [
                    { path: '', redirectTo: 'list', pathMatch: 'full'},
                    { path: 'list', component: MemberListComponent},
                ]
            },

            { path: 'organisation/members/create-member', component: CreateMemberComponent},

            { path: 'organisation/members/edit/:name', component: MemberItemComponent, children: [
                    { path: '', redirectTo: 'details', pathMatch: 'full'},
                    { path: 'details', component: MemberDetailsComponent, canDeactivate: [CanDeactivateGuard]},
                    { path: 'permissions', component: MemberRolesComponent, canDeactivate: [CanDeactivateGuard]},
                ]
            },

            /* Channels */

            { path: 'radio/channels', component: ChannelsComponent, children: [
                    { path: '', redirectTo: 'list', pathMatch: 'full'},
                    { path: 'list', component: ChannelListComponent},
                    { path: 'sections', component: RadioSectionsComponent},
                    { path: 'groups', component: ChannelGroupListComponent},
                    { path: 'warnings', component: TrackWarningListComponent},
                ]
            },

            { path: 'radio/channels/groups/:id', component: ChannelGroupItemComponent, children: [
                    { path: '', redirectTo: 'details', pathMatch: 'full'},
                    { path: 'details', component: EditChannelGroupComponent, canDeactivate: [CanDeactivateGuard]},
                    { path: 'channels', component: EditChannelGroupListComponent, canDeactivate: [CanDeactivateGuard]}
                ]
            },

            { path: 'radio/channels/create-channel', component: CreateChannelComponent},

            { path: 'radio/channels/edit/:name', component: ChannelItemComponent, children: [
                    { path: '', redirectTo: 'details', pathMatch: 'full'},
                    { path: 'details', component: ChannelDetailsComponent, canDeactivate: [CanDeactivateGuard]},
                    { path: 'schedules', component: ScheduleListComponent},
                    { path: 'program', component: ProgramComponent},
                    { path: 'streaming', component: EditStreamsComponent, canDeactivate: [CanDeactivateGuard]},
                    { path: 'create-schedule', component: CreateScheduleComponent },
                ]
            },

            { path: 'radio/channels/edit/:name/program/:id', component: EditSlotComponent },

            /* Schedules */

            { path: 'radio/schedules', component: SchedulesComponent, children: [
                    { path: '', redirectTo: 'list', pathMatch: 'full'},
                    { path: 'list', component: ScheduleListComponent},
                ]
            },


            { path: 'radio/create-schedule', component: CreateScheduleComponent },

            { path: 'radio/schedules/edit/:id', component: EditScheduleComponent },

            /* Shows */

            { path: 'radio/shows', component: ShowsComponent, children: [
                    { path: '', redirectTo: 'list', pathMatch: 'full'},
                    { path: 'list', component: ShowListComponent},
                ]
            },

            { path: 'radio/shows/edit/:name', component: ShowItemComponent, children: [
                    { path: '', redirectTo: 'details', pathMatch: 'full'},
                    { path: 'details', component: ShowDetailsComponent, canDeactivate: [CanDeactivateGuard]},
                ]
            },

            { path: 'radio/shows/create-show', component: CreateShowComponent},

            /* Posts */


            { path: 'publisher/home', component: PostsComponent, children: [
                    { path: '', redirectTo: 'editor', pathMatch: 'full'},
                    { path: 'editor', component: HomeEditorComponent},
                ]
            },

            { path: 'publisher/posts', component: PostsComponent, children: [
                    { path: '', redirectTo: 'list', pathMatch: 'full'},
                    { path: 'list', component: PostsListComponent},
                    { path: 'feeds', component: FeedListComponent},
                ]
            },

            { path: 'publisher/posts/create-post', component: CreatePostComponent},

            { path: 'publisher/posts/edit/:shortId', component: PostItemComponent, children: [
                    { path: '', redirectTo: 'details', pathMatch: 'full'},
                    { path: 'details', component: PostDetailsComponent, canDeactivate: [CanDeactivateGuard]},
                ]
            },

            /* Campaigns */

            { path: 'publisher/campaigns', component: CampaignsComponent, children: [
                    { path: '', redirectTo: 'list', pathMatch: 'full'},
                    { path: 'list', component: CampaignListComponent},
                ]
            },

            { path: 'publisher/campaigns/create-campaign', component: CreateCampaignComponent},

            { path: 'publisher/campaigns/edit/:id', component: CampaignItemComponent, children: [
                    { path: '', redirectTo: 'details', pathMatch: 'full'},
                    { path: 'details', component: CampaignDetailsComponent, canDeactivate: [CanDeactivateGuard]},
                    { path: 'editor', component: SimpleCampaignEditorComponent, canDeactivate: [CanDeactivateGuard]},
                    { path: 'statistics', component: CampaignStatisticsComponent},
                    { path: 'submissions', component: CampaignSubmissionListComponent},
                    { path: 'submissions/:userId', component: CampaignSubmissionDetailsComponent},
                ]
            },

            /* Users */

            { path: 'users', component: UsersComponent, children: [
                    { path: '', redirectTo: 'statistics', pathMatch: 'full'},
                    { path: 'statistics', component: UserStatisticsComponent},
                    { path: 'list', component: UserListComponent},
                ]
            },

            { path: 'social/users/edit/:id', component: UserItemComponent, children: [
                    { path: '', redirectTo: 'details', pathMatch: 'full'},
                    { path: 'details', component: UserDetailsComponent, canDeactivate: [CanDeactivateGuard]},
                ]
            },

            /* Tracks */

            { path: 'disco/tracks', component: TracksComponent, children: [
                    { path: '', redirectTo: 'search', pathMatch: 'full'},
                    { path: 'search', component: TrackListComponent},
                    { path: 'reports', component: TrackReportListComponent},
                    { path: 'warnings', component: TrackWarningListComponent},
                ]
            },

            { path: 'disco/tracks/edit/:id', component: TrackItemComponent, canDeactivate: [CanDeactivateGuard]},

            { path: 'disco/reports', component: TrackReportListComponent},
            { path: 'disco/warnings', component: TrackWarningListComponent},


            { path: 'disco/create-track', component: CreateTrackComponent}


]}];

export const ROUTING: ModuleWithProviders = RouterModule.forRoot(ROUTES);
