import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs/Observable";
import {ScheduledSlot} from "../model/scheduled-slot";
import {environment} from "../../environments/environment";
import {PagedResult} from "./admin.service";
import {HttpParams} from "../../../node_modules/@angular/common/http";
import {CreateShowSlot, ShowSlot} from "../model/show";
import {CreateImage, CreateImageResponse} from "../model/image";
import {CampaignContentEntry, ImagesContentEntry} from "../model/content";
import { delay } from 'rxjs/operators';

export interface Program {
    title: string;
}

export interface ShowDetails {

}

export interface Show {
    id: string;
    name: string,
    title: string;
}

export interface Slot {
    from: number;
    to: number;
    showId: string;
    showTitle: {};
}

export interface CreateSlot {
    from: number;
    to: number,
    showId: string
}

export interface Schedule {
    scheduleId: string,
    name: string,
    slots: Slot[];
}

export interface SchedulesResponse {
    schedules: Schedule[];
    activeScheduleId: string;
}

@Injectable()
export class ScheduleService {

    constructor(private http: HttpClient) { }

    // Schedules

    public createSchedule(name: string, slots: CreateSlot[]): Observable<any> {
        return this.http.post(environment.apiEndpoint +"/api/schedules", {
            name: name,
            slots: slots
        });
    }

    public getSchedule(scheduleId: string): Observable<Schedule> {
        return this.http.get<Schedule>("/api/schedules/id=" + scheduleId);
    }

    public getSchedules(pageSize: number, offset: string = null, query: string = null): Observable<PagedResult<Schedule>> {
        let params = new HttpParams();

        if(pageSize != null)
            params = params.set("pageSize", String(pageSize));
        if(offset != null)
            params = params.set("offset", offset);
        if(query != null)
            params = params.set("query", query);

        return this.http.get<PagedResult<Schedule>>("/api/schedules", { params: params});
    }

    public updateSchedule(scheduleId: string, name: string, slots: CreateSlot[]): Observable<any> {
        return this.http.put(environment.apiEndpoint +"/api/schedules/id=" + scheduleId, {
            name: name,
            slots: slots
        });
    }

    public deleteSchedule(scheduleId: string): Observable<any> {
        return this.http.delete(environment.apiEndpoint +"/api/schedules/" + scheduleId);
    }

    // Slots

    public createShowSlot(channelId: string, year: number, week: number, slot: CreateShowSlot): Observable<any> {
        return this.http.post("/api/channels/id=" + channelId + "/slots", {
            force: false,
            year: year,
            week: week,
            slot: {
                showId: slot.showId,
                from: slot.from,
                to: slot.to,
                title: slot.title,
                subTitle: slot.subTitle,
                summary: slot.summary
            }
        })
    }

    public importSchedule(channelId: string, scheduleId: string, year: number, week: number): Observable<any> {
        return this.http.post("/api/channels/id=" + channelId + "/import-schedule", {
            scheduleId: scheduleId,
            year: year,
            week: week
        });
    }

    public processShowSlotCoverImage(showSlotId: string, createImage: CreateImage): Observable<string> {
        return this.http.post<CreateImageResponse>("/api/slots/id=" + showSlotId + "/coverImage", createImage)
            .map(res => res.imageId);
    }

    public saveShowSlots(channelId: string, year: number, week: number, slots: CreateShowSlot[], force: Boolean): Observable<any> {
        return this.http.put("/api/channels/id=" + channelId + "/slots", {
            slots: slots,
            year: year,
            week: week,
            force: force
        });
    }

    public getSlotById(slotId: string): Observable<ShowSlot> {
        return this.http.get<ShowSlot>("/api/slots/id=" + slotId);
    }

    public getShowSlotsForWeek(channelId: string, year: number, week: number): Observable<any> {
        let params = new HttpParams();

        params = params.set("year", String(year));
        params = params.set("week", String(week));

        return this.http.get("/api/channels/id=" + channelId + "/slots-of-week", { params: params })
            .pipe(delay(1000));
    }

    public updateShowSlot(showSlot: ShowSlot): Observable<any> {

        let entries;
        if (showSlot.content != null && showSlot.content.entries != null) {
            entries = showSlot.content.entries.map(value => {
                if (value.type == "Images") {
                    return {
                        type: "Images",
                        images: (<ImagesContentEntry>value).images.map(value => {
                            return {
                                //copyright: value.copyright,
                                //license: value.license,
                                uploadUrl: value.uploadUrl,
                                imageId: value.imageId,
                                license: value.license != null ? value.license.name : null,
                                copyright: value.copyright,
                            }
                        })
                    }
                } else if (value.type == "Campaign") {
                    return {
                        type: "Campaign",
                        campaignId:  (<CampaignContentEntry>value).campaignId
                    }
                } else {
                    return value;
                }
            });
        }

        return this.http.put("/api/slots/id=" + showSlot.slotId, {
            title: showSlot.title,
            subTitle: showSlot.subTitle,
            summary: showSlot.summary,
            coverImageId: showSlot.coverImageId,
            content: showSlot.content != null ? {
                text: showSlot.content.text,
                entries: entries,
            } : null,
        });
    }

    public deleteShowSlot(slotId: string): Observable<any> {
        return this.http.delete("/api/slots/id=" + slotId);
    }
}
