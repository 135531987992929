import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {HttpClient, HttpEvent, HttpEventType, HttpRequest} from "@angular/common/http";
import {Subscription} from "rxjs/Subscription";

interface ImageUploadResult {
    imageId: string;
    imageUrl: string;
}

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent implements OnInit {

    @Input("allowedWidth") allowedWidth: number = 0;

    @Input("allowedHeight") allowedHeight: number = 0;

    @Input("minHeight") minHeight: number = 0;

    @Input("minWidth") minWidth; number = 0;

    @Input("uploadUrl") uploadUrl: string;

    @Input("image") imageData: any = 0;

    @Output("uploadFailed")
    uploadFailed: EventEmitter<string> = new EventEmitter<string>();

    @Output("onFinish")
    onFinish: EventEmitter<string> = new EventEmitter<string>();

    @ViewChild("imageInput") fileInput: ElementRef;

    @ViewChild("image") imageElement: HTMLImageElement;

    isUploading: boolean = false;

    isProcessing: boolean = false;

    isUploadCompleted: boolean = false;

    uploadProgress: number = 0;

    state: string;


    uploadSubscription: Subscription;

    constructor(private http: HttpClient) { }

    ngOnInit() {
        if(this.imageData != null)
            this.isUploadCompleted = true;
    }

    onImageChange(event) {
        let reader = new FileReader();

        if(event.target.files && event.target.files.length > 0) {
            let file = event.target.files[0];
            reader.onload = () => {
                this.imageData = reader.result;
                let image = new Image();
                if(typeof image.src  == "string") {
                    image.src = String(reader.result);
                }

                image.onerror = (error) => {
                    this.isUploading = false;
                    this.isUploadCompleted = false;
                    this.imageData = null;

                    console.error(error);

                    this.uploadFailed.emit("Invalid image file.")
                };

                image.onload = () => {
                    if(this.allowedHeight != 0) {
                        if(this.allowedHeight != image.height) {
                            this.uploadFailed.emit("Wrong image size");
                            return;
                        }
                    }

                    if(this.allowedWidth != 0) {
                        if(this.allowedWidth != image.width) {
                            this.uploadFailed.emit("Wrong image size");
                            return;
                        }
                    }

                    // Start upload
                    this.upload(this.uploadUrl, this.imageData.split(',')[1]);
                };
            };
            reader.readAsDataURL(file);
        }
    }

    upload(url: string, data: any) {
        this.isUploading = true;
        this.state = "Uploading";

        let request = new HttpRequest('POST', url, data,{reportProgress: true});

        this.uploadSubscription = this.http.request<{key: string}>(request)
            .subscribe((event: HttpEvent<any>) => {
                switch(event.type) {
                    case HttpEventType.UploadProgress:
                        this.uploadProgress = Math.round(100 * event.loaded / event.total);
                        break;
                    case HttpEventType.Response:
                        this.isUploading = false;
                        this.isUploadCompleted = true;
                        this.onFinish.emit(event.body.url);
                        break;
                }
            }, error => {
                this.uploadFailed.emit(error);
                this.cancel();
                this.isUploading = false;
            }, () => {
                this.isUploading = false;
                this.isUploadCompleted = true;
            });
    }

    public cancel() {
        this.isUploading = false;
        this.isUploadCompleted = false;
        this.uploadProgress = 0;
        this.imageData = undefined;
        this.fileInput.nativeElement.value = "";
    }

    openChooseImageDialog() {
        this.fileInput.nativeElement.click();
    }
}
