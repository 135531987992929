import {Component, OnInit, ViewChild} from '@angular/core';
import {ContentComponent} from "../../../components/content/content.component";
import {CreateMember, Member} from "../../../model/member";
import {CreateStation, StationLocale} from "../../../model/station";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {StationService} from "../../../services/station/station.service";
import {AuthService} from "../../../services/auth/auth.service";
import {Observable} from "rxjs";
import {AddLanguageComponent} from "../../common/add-language/add-language.component";
import * as moment from "moment-timezone";

@Component({
    selector: 'app-create-station',
    templateUrl: './create-station.component.html',
    styleUrls: ['./create-station.component.scss'],
    host: {'class': 'item-content'}
})
export class CreateStationComponent implements OnInit {

    @ViewChild(ContentComponent)
    content: ContentComponent<CreateStation>;


    @ViewChild(AddLanguageComponent)
    addLanguageModal: AddLanguageComponent;

    item = new CreateStation();

    availableTimeZones = moment.tz.names();

    form: FormGroup;

    constructor(private fb: FormBuilder, private route: ActivatedRoute,private router: Router,
                public stationService: StationService, private authService: AuthService) {
        this.form = this.fb.group({
            name: ['', [Validators.required, Validators.min(2), Validators.maxLength(32) ]],
            displayName: ['', [Validators.required, Validators.min(2), Validators.maxLength(32) ]],
            domainName: ['', [Validators.required,  Validators.min(2), Validators.maxLength(128) ]],
            defaultLocale: [null, [Validators.required]]
        });
    }

    ngOnInit() {
        this.addLanguageModal.onFinish.subscribe(value => {
            this.addLocale(value);
        });
    }

    onCreated() {
        this.router.navigate(['..', 'stations', 'list'], {relativeTo: this.route})
    }

    addLocale(locale: string) {
        if (!this.content.item.locales.find(value => value.locale == locale)) {
            this.content.item.locales.push({locale: locale, disabled: false});
        }
    }

    removeLocale(locale: StationLocale) {
        this.content.item.locales =
            this.content.item.locales.filter(value => value.locale != locale.locale)
    }


    validate(item: CreateMember) {
        return true;
    }

    createStation(item: CreateStation): Observable<any> {
        return this.stationService.createStation(this.item);
    }

}
