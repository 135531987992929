import {Component, OnInit, ViewChild} from '@angular/core';
import {ContentComponent} from "../../../components/content/content.component";
import {CreateChannel} from "../../../model/channel";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Observable} from "rxjs";
import {PostService} from "../../../services/post.service";
import {ActivatedRoute, Router} from "@angular/router";
import {CreatePost} from "../../../model/post";
import {ChannelService} from "../../../services/channel.service";
import {Station} from "../../../model/station";
import {StationService} from "../../../services/station/station.service";
import {SingleImageUploadComponent} from "../../../commons/single-image-upload/single-image-upload.component";

interface AccessLevel {
    name: string;
    value: string;
}

@Component({
    selector: 'app-create-channel',
    templateUrl: './create-channel.component.html',
    styleUrls: ['./create-channel.component.scss'],
    host: {'class': 'item-content'}
})
export class CreateChannelComponent implements OnInit {

    @ViewChild(ContentComponent)
    content: ContentComponent<CreateChannel>;

    @ViewChild("coverImage")
    coverImage: SingleImageUploadComponent;

    form: FormGroup;

    item = new CreateChannel();

    readonly uploadImageUrl = "/api/uploads/channel-cover";

    accessLevels: AccessLevel[] = [
        {name: "Anonymous", value: "Anonymous"},
        {name: "Registered", value: "Registered"},
        {name: "Premium", value: "Premium"},
        {name: "Internal", value: "Internal"}
    ];

    stations: Station[] = [];

    constructor(private fb: FormBuilder, private channelService: ChannelService, private route: ActivatedRoute,
                public stationService: StationService, public router: Router) {
        this.form = this.fb.group({
            name: ['', [Validators.required, Validators.minLength(1)]],
            title: ['', [Validators.required, Validators.minLength(1)]],
            subTitle: [''],
            summary: [''],
            requiredAccessLevel: [null],
            stationId: [null]
        })
    }
    ngOnInit() {
    }

    validate(channel: CreateChannel) {
        if(this.coverImage.imageData == null) {
            this.coverImage.valid = false;
        }

        if(this.coverImage.imageData == null) {
            return false;
        }

        return true;
    }

    createChannel(): Observable<any> {
        return this.channelService.createChannel(this.item);
    }

    onCreated() {
        this.router.navigate(['..', 'list'], {relativeTo: this.route})
    }
}
