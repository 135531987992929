import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ClrModal} from "@clr/angular";

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent implements OnInit {

    @ViewChild("#modal") modal: ClrModal;

    dialogOpen: boolean = false;

    text: string;

    name: string;

    id: string;

    title: string = "Delete";

    cb: () => void;

    @Output("onDelete")
    onDelete: EventEmitter<string> = new EventEmitter();

    constructor() { }

    ngOnInit() {
    }

    public open(id: string = null, name: string = null, cb: () => void) {
        this.name = name;
        this.id = id;
        this.cb = cb;
        if(name != null)
            this.text = "Do you really want to delete " + name;
        else
            this.text = "Are you sure?";

        this.dialogOpen = true;
    }

    delete() {
        this.dialogOpen = false;
        this.cb();
    }
}
