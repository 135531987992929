import {CreateImage} from "./image";
import {Content, ContentEntry} from "./content";

export class CreatePost {
    constructor() {
        this.content = new Content();
    }

    groupId: string;
    authorId: string;
    feedId: string;

    type: string;

    title: {};
    subTitle: {};
    coverImage: CreateImage;
    url: string;

    content: Content;

    publishDate: Date;
    enabled: boolean;
    internal: boolean;
}

export class Post {
    postId: string;
    shortId: string;
    groupId: string;
    authorId: string;
    feedId: string;

    type: string;

    title: {};
    subTitle: {};
    coverImageId: string;
    url: string;

    content: Content;

    publishDate: Date;
    unpublishDate: Date;
    
    enabled: boolean;
    internal: boolean;
    created: Date;
}
