import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {WeekTimeComponent} from "../week-time/week-time.component";
import {WeekRange} from "../../utility/week-range";

@Component({
  selector: 'app-week-time-range',
  templateUrl: './week-time-range.component.html',
  styleUrls: ['./week-time-range.component.scss']
})
export class WeekTimeRangeComponent implements OnInit {

    @ViewChild("fromInput") fromInput: WeekTimeComponent;

    @ViewChild("toInput") toInput: WeekTimeComponent;

    rangeValue: WeekRange;

    @Input()
    valid: boolean = true;

    @Input()
    nextWeek: boolean;

    @Input()
    get range() {
        return this.rangeValue;
    }

    @Output()
    rangeChange: EventEmitter<WeekRange> = new EventEmitter<WeekRange>();

    @Output()
    nextWeekChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    set range(value: any) {
        this.rangeValue = value;
        this.rangeChange.emit(value);
    }

    constructor() {
    }

    ngOnInit() {
    }

    onRangeChanged() {
        this.rangeChange.next(this.range);
    }
}
