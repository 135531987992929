import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {WeekTime} from "../../utility/week-time";

/**
 * Component for setting time in a week
 */
@Component({
    selector: 'app-week-time',
    templateUrl: './week-time.component.html',
    styleUrls: ['./week-time.component.scss']
})
export class WeekTimeComponent implements OnInit {

    timeValue: WeekTime;

    @Input()
    valid: boolean;

    @Input()
    get time() {
        return this.timeValue;
    }

    @Output()
    timeChange: EventEmitter<WeekTime>= new EventEmitter<WeekTime>();

    dayInput: string = "0";

    timeInput: string;

    set time(value: WeekTime) {
        if(value == undefined)
            value = WeekTime.fromSeconds(0);

        this.timeValue = value;

        this.timeInput = this.timeValue.getTimeString();
        this.dayInput = this.timeValue.days.toString();

        this.timeChange.emit(value);
    }

    constructor() {
        this.timeValue = new WeekTime(0,0,0,0, false);
    }

    ngOnInit() {
    }

    onDayChanged(value: string) {
        this.timeValue.days = parseInt(value);
        this.time = this.timeValue;
    }

    onTimeChanged(value: string) {
        this.timeValue = WeekTime.fromTimeString(this.timeValue.days, value, this.timeValue.nextWeek);

        if(this.timeValue.days == 6 && this.timeValue.hours == 24 && this.timeValue.minutes == 0 && this.timeValue.seconds == 0 && this.timeValue.nextWeek == false) {
            this.timeValue.days = 0;
            this.timeValue.nextWeek = true;
        }

        this.time = this.timeValue;
    }
}
