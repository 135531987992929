import {
    Directive,
    ElementRef,
    HostListener,
    Input, Optional, Self,
} from '@angular/core';

import {QuillEditorComponent} from "ngx-quill";
import {ContentComponent} from "../components/content/content.component";

@Directive({
  selector: '[rsField]'
})
export class FieldDirective {

    @Input()
    item: any;

    @Input()
    field: string;

    content: ContentComponent<any>;

    @Input()
    formControlName: string;

    @Input()
    inverted = false;

    @Input()
    type: string;

    initialized: boolean = false;


    constructor(private el: ElementRef, @Self() @Optional() private quill: QuillEditorComponent) {
    }

    private setNestedField(obj: any, dir: string, value: any) {
        // TODO: implement
    }

    @HostListener('change', ['$event']) ngOnChanges(event) {
        if (this.item != null) {
            if (this.initialized) {
                if(this.content != null) {
                    if (event.item != undefined) {
                        // checkbox init event
                        return
                    }
                    this.content.setDirty();
                }

                if (this.type == 'checkbox') {
                    var value = this.el.nativeElement.checked;

                    if (this.inverted) {
                        this.item[this.field] = !value;
                    } else {
                        this.item[this.field] = value;
                    }
                } else {
                    this.item[this.field] = this.el.nativeElement.value;
                }
            }
        }
    }

    @HostListener('onContentChanged', ['$event']) ngOnContentChanged(event) {
        if(this.item != null) {
            if(this.content != null) {
                this.content.setDirty();
            }
            if(this.item[this.field] == undefined) {
                this.item[this.field] = "";
            }
            this.item[this.field] = event.html;
        }
    }
}
