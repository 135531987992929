import {
    Component,
    ContentChild,
    ContentChildren,
    Directive, EventEmitter,
    Input,
    OnInit, Output,
    QueryList,
    TemplateRef
} from '@angular/core';
import {SortablejsOptions} from "angular-sortablejs";

@Directive({
    selector: '[itemTitle]'
})
export class ContentItemTitleDirective {}

@Directive({
    selector: '[itemBody]'
})
export class ContentItemBodyDirective {}


@Directive({
    selector: '[itemNew]'
})
export class ContentItemNewDirective {}


@Directive({
    selector: 'item-field'
})
export class ContentFieldDirective {
    @Input() name: string;
    @Input() value: string;
    @Input() type: string;
}

@Component({
  selector: 'app-content-items',
  templateUrl: './content-items.component.html',
  styleUrls: ['./content-items.component.scss'],
})
export class ContentItemsComponent<T> implements OnInit {

    @Input("items")
    items: T[] = [];

    @Input("mode")
    mode: 'card' | 'list' | 'table' | 'simple-table' | 'grid' | 'simple' = 'list';

    @Input("max")
    maxItems: number = null;

    @Input("sortable")
    sortable: boolean = true;

    @Input("deletable")
    deletable: boolean = true;

    @ContentChild(ContentItemTitleDirective, {read: TemplateRef})
    titleTemplate;

    @ContentChild(ContentItemBodyDirective, {read: TemplateRef})
    bodyTemplate;

    @ContentChild(ContentItemNewDirective, {read: TemplateRef})
    newTemplate;


    @ContentChildren(ContentFieldDirective)
    fields: QueryList<ContentFieldDirective>;

    @Output("onRemoved")
    onRemoved: EventEmitter<T> = new EventEmitter();

    @Output("itemClicked")
    onClicked: EventEmitter<T> = new EventEmitter();

    @Output("itemsChanged")
    onChanged: EventEmitter<T[]> = new EventEmitter();

    sortOptions: SortablejsOptions = {
        draggable: '.draggable',
        onUpdate: (event: any) => {
            this.onChanged.emit(this.items);
        }
    };

    constructor() { }

    ngOnInit() {
    }

    getItems(): T[] {
        if(this.items == null)
            return [];

        return this.items.filter(value => value != null);
    }

    removeItem(item: T) {
        //this.items = this.items.filter(value => value != item);
        this.onRemoved.emit(item);
        this.onChanged.emit(this.items);
    }

    addItem(item: T) {
        this.items.push(item);
        this.onChanged.emit(this.items);
    }

    getFieldNames() {
        return this.fields.toArray().map(v => v.name);
    }

    getFields() {
        return this.fields.toArray();
    }

    getFieldValues(item: T) {
        return this.fields.toArray().map(v => item[v.value]);
    }
}
