import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Subject} from "rxjs/Subject";

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit {

    modalOpen: boolean;

    form: FormGroup;

    password: string;

    passwordRepeat: string;

    // Observables

    readonly onPasswordChange: Subject<any> = new Subject();

    readonly onError: Subject<any> = new Subject();

    constructor(private fb: FormBuilder) {
        this.form = this.fb.group({
            password: ['', [Validators.required, Validators.maxLength(32), Validators.minLength(6)]],
            passwordRepeat: ['', [Validators.required, Validators.maxLength(32), Validators.minLength(6)]],
        })
    }

    ngOnInit() {
    }

    open() {
        this.modalOpen = true;
    }

    close() {
        this.reset();
        this.modalOpen = false;
    }

    reset() {
        this.form.get("password").reset();
        this.form.get("passwordRepeat").reset();
    }

    validate(): boolean {
        Object.keys(this.form.controls).forEach((key) => {
            this.form.get(key).markAsDirty();
        });

        return this.form.valid;
    }

    finish() {
        if(!this.validate())
            return;

        if(this.form.get("password").value != this.form.get("passwordRepeat").value) {
            this.form.get("passwordRepeat").setErrors({
                notEqual: true
            });
            return;
        }

        this.onPasswordChange.next(this.form.get("password").value);
        this.close();
    }
}
