import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {StationService} from "../../../services/station/station.service";
import {ContentComponent} from "../../../components/content/content.component";
import {Show, ShowSlot} from "../../../model/show";
import {Observable, of} from "rxjs";
import {PlatformLocation} from "@angular/common";
import {ScheduleService} from "../../../services/schedule.service";
import {ActivatedRoute} from "@angular/router";
import {Callback} from "../../../utility/callback";
import {CreateImage} from "../../../model/image";

@Component({
    selector: 'app-edit-slot',
    templateUrl: './edit-slot.component.html',
    styleUrls: ['./edit-slot.component.scss'],
    host: {'class': 'item-content'}
})
export class EditSlotComponent implements OnInit {

    @ViewChild(ContentComponent)
    content: ContentComponent<ShowSlot>;

    form: FormGroup;

    slotId: string;

    processImageCallback: Callback<CreateImage, string> = new Callback(this, this.processImage);


    processImage(createImage: CreateImage): Observable<string> {
        return this.scheduleService.processShowSlotCoverImage(this.content.item.slotId, createImage);
    }

    setCoverImage(value: any) {
        if(value != null) {
            this.content.item.coverImageId = value.imageId;
        }
    }

    constructor(private fb: FormBuilder,
                private route: ActivatedRoute,
                public stationService: StationService,
                private scheduleService: ScheduleService) {
        this.form = this.fb.group({
            title: ['', [Validators.required, Validators.min(2), Validators.maxLength(32) ]],
            subTitle: [''],
            summary: [''],
            text: ['']
        });
    }

    ngOnInit() {
        this.route.params.map(params => params['id'])
            .subscribe(id => {
                this.slotId = id;
                this.content.refresh()
            });
    }

    getSlot(): Observable<any> {
        if(this.slotId == null)
            return of(null);

        return this.scheduleService.getSlotById(this.slotId);
    }

    updateSlot(): Observable<ShowSlot> {
        return this.scheduleService.updateShowSlot(this.content.item);
    }
}
