import {Station} from "./station";
import {CreateImage} from "./image";

export interface MemberStation {
    id: string;
    displayName: string;
    enabled: string;
}

export class CreateMember {
    username: string;
    displayName: string;
    email: string;
    password: string;
    profileImage: CreateImage;
    enabled: boolean;
}

export interface MemberPermission {
    stationId: string,
    role: string,
}

export interface MemberRole {
    displayName: string;
    id: string;
    role: string;
    scope: string;
}

export interface MemberPermissions {
    admin: boolean,
    stationPermissions: StationPermissions[];
}

export interface StationPermissions {
    displayName: string,
    id: string,
    channelEditor: boolean,
    postRole: string,
    support: boolean
}

export class Member {
    memberId: string;
    username: string;
    displayName: string;
    email: string;
    enabled: boolean;
    profileImageId: string;
    stations: Station[];
    orgaId: string;
    roles: MemberRole[];
}

export class MemberOrgaFlags {

}

export class MemberMe {
    root: boolean;
    profile: Member;
    orgaFlags: MemberOrgaFlags;
}
