import {Component, OnInit, ViewChild} from '@angular/core';
import {ContentComponent} from "../../../components/content/content.component";
import {CreateChannel} from "../../../model/channel";
import {AddApp} from "../../../model/app";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ChannelService} from "../../../services/channel.service";
import {ActivatedRoute, Router} from "@angular/router";
import {StationService} from "../../../services/station/station.service";
import {Observable} from "rxjs";

interface AppType {
    value: string, name: string;
}


@Component({
    selector: 'app-add-app',
    templateUrl: './add-app.component.html',
    styleUrls: ['./add-app.component.scss'],
    host: {'class': 'item-content'}
})
export class AddAppComponent implements OnInit {

    @ViewChild(ContentComponent)
    content: ContentComponent<AddApp>;

    selectedOS: string;

    form: FormGroup;

    androidForm: FormGroup;

    iOSForm: FormGroup;

    item = new AddApp();

    appTypes = [
        <AppType>{name: 'Android', value: 'Android'},
        <AppType>{name: 'iOS', value: 'iOS'}
        ];


    constructor(private fb: FormBuilder, private route: ActivatedRoute,
    public stationService: StationService, public router: Router) {
        this.form = this.fb.group({
            name: ['', [Validators.required, Validators.minLength(1)]],
            firebaseAppId: [''],
            chromeCastId: [''],
        });

        this.iOSForm = this.fb.group({
            bundleId: ['', [Validators.required, Validators.minLength(1)]],
            appStoreId: ['', [Validators.required, Validators.minLength(1)]],
            teamId: ['', [Validators.required, Validators.minLength(1)]],
        });

        this.androidForm = this.fb.group({
            packageName: ['', [Validators.required, Validators.minLength(1)]],
            signature: ['', [Validators.required, Validators.minLength(1)]],
        });
    }

    ngOnInit() {
    }

    validate(app: AddApp) {
        return true;
    }

    addApp(): Observable<any> {
        return this.stationService.addApp(this.item);
    }

    onCreated() {

    }
}
