import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute} from "@angular/router";
import {Observable} from "rxjs";
import {ClrModal} from "@clr/angular";
import {ContentComponent} from "../../../components/content/content.component";
import {StationTOS} from "../../../model/station";
import {StationService} from "../../../services/station/station.service";

@Component({
  selector: 'app-station-tos',
  templateUrl: './station-tos.component.html',
  styleUrls: ['./station-tos.component.scss']
})
export class StationTosComponent implements OnInit {

    @ViewChild(ContentComponent)
    content: ContentComponent<StationTOS>;

    @ViewChild("updateTosModal")
    updateTosModal: ClrModal;

    form: FormGroup;

    newTOSVersion: string;

    textModule = { toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'indent': '-1'}, { 'indent': '+1' }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'font': [] }],
            [{ 'align': [] }],
            ['link']
        ]};

    stationName: string;

    constructor(private fb: FormBuilder, private route: ActivatedRoute, private stationService: StationService) {
        this.form = fb.group({
            version: [null, [Validators.required]],
            text: [null, [Validators.required]],
        })
    }

    ngOnInit() {
        this.route.parent.parent.params.subscribe(params => {
            this.stationName = params['name'];
        });
    }

    getTOS(): Observable<StationTOS> {
        return this.stationService.getStationTOS(this.stationName);
    }

    updateTOS(): Observable<any> {
        return this.stationService.updateStationTOS(this.stationName, this.content.item.text);
    }

    updateTOSVersion(version: string) {
        this.stationService.updateStationTOSVersion(this.stationName, version)
            .subscribe(value => {
                this.newTOSVersion = null;
                this.updateTosModal.close();
                this.content.refresh();
            }, error => {
                this.newTOSVersion = null;
                this.updateTosModal.close();
            }, () => {
                this.newTOSVersion = null;
                this.updateTosModal.close();
            });
    }

}
