import {Component, Input, OnInit} from '@angular/core';

interface DataEntry {
    name: string;
    value: number;
}

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent implements OnInit {

    @Input()
    data: DataEntry[] = [
        {name: "1980", value: 12504},
        {name: "1990", value: 15504},
        {name: "2000", value: 34002},
        {name: "2010", value: 40504},
    ];

    @Input()
    title: string;


    colorScheme = {
        domain: ['#5AA454', '#A10A28', '#C7B42C']
    };

    constructor() { }

    ngOnInit() {
    }

}
