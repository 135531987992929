import {Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren} from '@angular/core';

@Component({
    selector: 'app-content-header-button',
    template: '<div class="btn-group"><button  [clrLoading]="isLoading" class="btn {{buttonClass}}"><ng-content></ng-content></button></div>'
})
export class ContentHeaderButton implements OnInit {

    constructor() {}

    @Input()
    buttonClass: string;

    @Input()
    isLoading: boolean;

    ngOnInit() {

    }
}

@Component({
    selector: 'app-content-header-filter',
    template: '<div>Filter</div>'
})
export class ContentHeaderFilter implements OnInit {

    constructor() {}

    ngOnInit() {
    }
}

@Component({
  selector: 'app-content-header',
  templateUrl: './content-header.component.html',
  styleUrls: ['./content-header.component.scss']
})
export class ContentHeaderComponent implements OnInit {

  constructor() { }

  @Output()
  search: EventEmitter<string> = new EventEmitter();

  @Input()
  title: string;

  @Input()
  searchEnabled: boolean;

  @ViewChildren(ContentHeaderButton)
  buttons: QueryList<ContentHeaderButton>;

  ngOnInit() {
  }

  onSearch(event: any) {
      this.search.next(event.target.value);
  }
}
