import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {AuthService} from "./services/auth/auth.service";
import {AdminService} from "./services/admin.service";

@Component({
    selector: 'my-app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {

    loaded = false;

    constructor(private router: Router, authService: AuthService) {
        authService.refresh((ok: boolean) => {
            if(!ok) {
                router.navigate(["/login"]);
                this.loaded = true
            } else {
                this.loaded = true
            }
        });
    }
}

