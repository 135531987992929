import {Component, OnInit, ViewChild} from '@angular/core';
import {ContentComponent} from "../../../components/content/content.component";
import {SingleImageUploadComponent} from "../../../commons/single-image-upload/single-image-upload.component";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CreateShow} from "../../../model/show";
import {ActivatedRoute, Router} from "@angular/router";
import {Observable} from "rxjs";
import {ShowService} from "../../../services/show.service";
import {StationService} from "../../../services/station/station.service";

@Component({
    selector: 'app-create-show',
    templateUrl: './create-show.component.html',
    styleUrls: ['./create-show.component.scss'],
    host: {'class': 'item-content'}
})
export class CreateShowComponent implements OnInit {

    @ViewChild(ContentComponent)
    content: ContentComponent<CreateShow>;

    @ViewChild("coverImage")
    coverImage: SingleImageUploadComponent;

    form: FormGroup;

    item = new CreateShow();

    readonly uploadImageUrl = "/api/uploads/show-cover";

    constructor(private fb: FormBuilder, private showService: ShowService, public stationService: StationService,
                public router: Router, private route: ActivatedRoute) {
        this.form = this.fb.group({
            name: ['', [Validators.required, Validators.minLength(1)]],
            title: ['', [Validators.required, Validators.minLength(1)]],
            subTitle: [''],
            summary: [''],
            stationId: [null]
        })
    }

    ngOnInit() {
    }

    validate() {
        return true;
    }

    createShow(): Observable<any> {
        return this.showService.createShow(this.item);
    }

    onCreated() {
        this.router.navigate(['..', 'list'], {relativeTo: this.route})
    }

}
