import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs/Observable";
import "rxjs/add/operator/debounceTime";
import "rxjs/add/operator/distinctUntilChanged";
import "rxjs/add/operator/switchMap";
import {Station, StationApi} from "../model/station";
import {Member, MemberMe, MemberPermission, MemberRole} from "../model/member";
import {Orga} from "../model/orga";
import {environment} from '../../environments/environment';
import {User} from "../model/user";
import { throwError } from 'rxjs';
import {AuthService} from "./auth/auth.service";
import {SearchResult} from "../model/misc";

export interface UserSearchParams {
    query: string,
    locale: string,
    stationId: string,
    minCreated: string, // TODO: use :number with simple epoch timestamp?
    maxCreated: string,
    disabled: Boolean,
    pageSize: number,
    page: number
}

export class PagedResult<T> {
    items: T[];
    next: string;
    total: number;

    constructor (items: T[], next: string) {
        this.items = items;
        this.next = next;
    }
}

@Injectable()
export class AdminService {

    constructor(private http: HttpClient, private authService: AuthService) {
    }

    // Orga

    public getOrga(id: string): Observable<Orga> {
        return this.http.get<Orga>(environment.apiEndpoint + "/api/orgas/" + id);
    }

    public updateOrga(orga: Orga) {
        return this.http.put(environment.apiEndpoint +"/api/orgas/" + orga.id, orga);
    }

    // Users

    public searchUsers(limit: number, offset: string, searchParams: any): Observable<PagedResult<User>> {

        let params = new HttpParams();
        if(limit)
            params = params.set("limit", String(limit));
        if(offset)
            params = params.set("offset", offset);

        return this.http.post<PagedResult<User>>("/api/users/search", searchParams, {params: params});
    }

    public getUsers(limit: number, offset: string, query: string = null, disabled: boolean = false,
                    minCreated: Date = null, maxCreated: Date = null): Observable<PagedResult<User>> {
        let params = new HttpParams();
        if(minCreated)
            params = params.set("minCreated", minCreated.toISOString());
        if(maxCreated)
            params = params.set("maxCreated", maxCreated.toISOString());
        if(disabled != null)
            params = params.set("disabled", String(disabled));
        if(query)
            params = params.set("query", query);
        if(limit)
            params = params.set("limit", String(limit));
        if(offset)
            params = params.set("offset", offset);

        return this.http.get<PagedResult<User>>(environment.apiEndpoint +"/api/users", {params: params});
    }

    public addUser(email: string, password: string, name: string): Observable<any> {
        return this.http.post(environment.apiEndpoint + "/api/users",
            { email: email, password: password, name: name});
    }

    public deleteUser(id: string) {
        return this.http.delete(environment.apiEndpoint + "/api/users/id=" + id)
    }

    // Stations

    public addStation(name: string, displayName: string, domainName: string) {
        return this.http.post("/api/stations", {name: name, displayName: displayName, domainName: domainName});
    }

    public getStations(pageSize: number, offset: string, query: string): Observable<PagedResult<Station>> {
        let params = new HttpParams();
        if(pageSize != null)
            params = params.set("pageSize", String(pageSize));
        if(offset != null)
            params = params.set("offset", offset);
        if(query != null)
            params = params.set("query", query);

        return this.http.get<PagedResult<Station>>(environment.apiEndpoint +"/api/stations", {params: params});
    }

    public getStation(name: string): Observable<Station> {
        return this.http.get<Station>(environment.apiEndpoint +"/api/stations/" + name)
    }

    public updateStation(station: Station): Observable<any> {
        return this.http.put(environment.apiEndpoint +"/api/stations/" + station.stationId, station);
    }

    public enableStation(id: string, enabled: boolean): Observable<any> {
        return this.http.put(environment.apiEndpoint +"/api/stations/" + id, { disabled: !enabled })
    }

    public deleteStation(id: string): Observable<any> {
        return this.http.delete(environment.apiEndpoint +"/api/stations/" + id);
    }

    public addAPI(stationId: string, domain: string, auto: boolean, cert: string, privateKey: string): Observable<any> {
        return this.http.post(environment.apiEndpoint +"/api/stations/" + stationId + "/apis", {
            domain: domain,
            auto: auto,
            privateKey: privateKey,
            cert: cert
        })
    }

    public getAPIs(stationId: string, pageSize: number, offset: string): Observable<PagedResult<StationApi>> {
        let params = new HttpParams();
        if(pageSize != null)
            params = params.set("pageSize", String(pageSize));
        if(offset != null)
            params = params.set("offset", offset);

        return this.http.get<PagedResult<StationApi>>(environment.apiEndpoint +"/api/stations/" + stationId + "/apis", {params: params});
    }

    public deleteAPI(id: string): Observable<any> {
        return this.http.delete(environment.apiEndpoint +"/api/stations/apis/" + id);
    }

    public refreshAPI(id: string): Observable<any> {
        return this.http.post(environment.apiEndpoint +"/api/stations/apis/" + id + "/refresh", null)
    }

}
