import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ClrModal} from "@clr/angular";

@Component({
  selector: 'app-add-metatag',
  templateUrl: './add-metatag.component.html',
  styleUrls: ['./add-metatag.component.scss']
})
export class AddMetatagComponent implements OnInit {

    @ViewChild("modal") modal: ClrModal;

    modalOpen: boolean;

    form: FormGroup;

    @Output("onFinish")
    onFinish: EventEmitter<string> = new EventEmitter<string>();

    constructor(private fb: FormBuilder) {
        this.form = fb.group({
            "metatag": ['', [Validators.required, Validators.pattern("(\\w+)\\s-\\s(\\w+)")]],
        });
    }

    ngOnInit() {
    }

    open() {
        this.modal.open();
    }


    public finish() {
        this.onFinish.emit(this.form.get("metatag").value);

        this.reset();
        this.close();
    }

    public reset() {
        this.form.reset()
    }
    public close() {
        this.modal.close();
        this.reset();
    }
}
