import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-show-item',
  templateUrl: './show-item.component.html',
  styleUrls: ['./show-item.component.scss']
})
export class ShowItemComponent implements OnInit {

    page: string = 'details';

    constructor() { }

    ngOnInit() {
    }

}
