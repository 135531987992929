import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-radio',
    templateUrl: './radio.component.html',
    styleUrls: ['./radio.component.scss'],
    host: {'class': 'content-container'}
})
export class RadioComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
