import {Component, OnInit, ViewChild} from '@angular/core';
import {ContentComponent} from "../../../components/content/content.component";
import {Artist, Track} from "../../../model/track";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {TrackService} from "../../../services/disco/track.service";
import {Observable, of} from "rxjs";
import {DeleteDialogComponent} from "../../../components/delete-dialog/delete-dialog.component";
import {AddMetatagComponent} from "../add-metatag/add-metatag.component";
import {parseCookieValue} from "../../../../../node_modules/@angular/common/src/cookie";
import {AddArtistComponent} from "../add-artist/add-artist.component";
import {AddReleaseComponent} from "../add-release/add-release.component";
import {MultipleImageUploadComponent} from "../../../commons/multiple-image-upload/multiple-image-upload.component";

@Component({
  selector: 'app-track-details',
  templateUrl: './track-details.component.html',
  styleUrls: ['./track-details.component.scss']
})
export class TrackDetailsComponent implements OnInit {

    @ViewChild(ContentComponent)
    content: ContentComponent<Track>;

    @ViewChild("addMetatagDialog")
    addMetatagDialog: AddMetatagComponent;

    @ViewChild("addArtistDialog")
    addArtistDialog: AddArtistComponent;

    @ViewChild("addReleaseDialog")
    addReleaseDialog: AddReleaseComponent;

    @ViewChild("artwork")
    artwork: MultipleImageUploadComponent;

    form: FormGroup;

    releaseForm: FormGroup;

    id: string;

    images = [];

    constructor(fb: FormBuilder, private route: ActivatedRoute, private router: Router,
                private trackService: TrackService) {
        this.form = fb.group({
            title: ['', [Validators.required, Validators.min(1)]],
            artistCredits: ['', [Validators.required, Validators.min(1)]],
            disambiguation: ['', [Validators.maxLength(128)]],
            isrc: ['', [Validators.maxLength(128)]],

        });

        this.releaseForm = fb.group({
            title: [null, [Validators.required]],
            type: [null, [Validators.required]],
            labelName: [''],
            labelCode: [''],
            country: [''],
            releaseYear: [''],
            barcode: [''],
        });
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.id = params['id'];
        });

        this.addMetatagDialog.onFinish.subscribe(value => {
            this.content.item.metatags.push(value);
        });

        this.addArtistDialog.onFinish.subscribe(value => {
            this.content.item.artists.push(new Artist(value.name));
        })
    }

    getTrack(): Observable<any> {
        return of(new Track());

        //return this.trackService.getTrackById(this.id);
    }

    updateTrack(): Observable<any> {
        this.content.item.title = this.form.get('title').value;
        this.content.item.artistCredits = this.form.get('artistCredits').value;

        return this.trackService.updateTrack(this.content.item);
    }

    openTrack(track: Track) {
    }

    deleteTrack(track: Track) {
    }

    addMetatag() {
        this.addMetatagDialog.open();
    }

    removeMetatag(metatag: string) {
        this.content.item.metatags = this.content.item.metatags.filter(value => value != metatag);
    }

    addRelease() {
        this.addReleaseDialog.open();
    }

    removeRelease() {
        this.content.item.release = null;
    }

    addArtist() {
        this.addArtistDialog.open();
    }

    removeArtist() {

    }
}


