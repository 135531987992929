import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ClrModal} from "@clr/angular";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ImageUploadComponent} from "../../../commons/image-upload/image-upload.component";
import {Observable} from "rxjs";
import {CreateImage, ImageLicense} from "../../../model/image";
import {Callback} from "../../../utility/callback";

export interface AddImageResult {
    copyright: string,
    license: ImageLicense,
    imageId: string,
    uploadUrl: string,
    imageData: any;
}

@Component({
  selector: 'app-add-image',
  templateUrl: './add-image.component.html',
  styleUrls: ['./add-image.component.scss']
})
export class AddImageComponent implements OnInit {

    @ViewChild("modal") modal: ClrModal;

    @ViewChild(ImageUploadComponent)
    imageUpload: ImageUploadComponent;

    modalOpen: boolean;

    @Input("uploadUrl")
    uploadUrl: string;

    // Upload & process urls
    @Input("processImage") processImage: Callback<CreateImage, string>;


    @Input()
    onlyImage: boolean = false;

    form: FormGroup;

    imageUrl: string;

    imageId: string;

    @Output("onFinish")
    onFinish: EventEmitter<AddImageResult> = new EventEmitter<AddImageResult>();

    constructor(private fb: FormBuilder) {
        this.form = fb.group({
            "copyright": [''],
            "license": [''],
        });
    }

    ngOnInit() {
    }

    open() {
        this.modal.open();
    }

    onImageUploaded(uploadUrl: string) {
        this.imageUrl = uploadUrl;
    }

    public finish() {
        if(this.imageUrl != null) {

            let createImage = <CreateImage>{
                copyright: this.form.get("copyright").value,
                license: this.form.get("license").value,
                uploadUrl: this.imageUrl
            };

            if (this.processImage != null) {
                // Processing image!
                this.imageUpload.isProcessing = true;

                this.processImage.fn.apply(this.processImage.instance, [createImage]).subscribe(imageId => {
                    this.onFinish.emit(<AddImageResult> {
                        license: this.form.get("license").value,
                        copyright: this.form.get("copyright").value,
                        imageId: imageId,
                        imageData: this.imageUpload.imageData
                    });

                    this.imageUpload.isProcessing = false;

                    this.reset();
                    this.close();

                },error => {

                    this.imageUpload.isProcessing = false;

                    console.error("Failed to process image");

                    this.reset();
                    this.close();
                });
            } else {
                // Only uploading image
                this.onFinish.emit(<AddImageResult> {
                    license: this.form.get("license").value,
                    copyright: this.form.get("copyright").value,
                    uploadUrl: this.imageUrl,
                    imageData: this.imageUpload.imageData
                });

                this.reset();
                this.close();
            }
        } else {
            this.reset();
            this.close();
        }
    }

    public reset() {
        this.form.reset();
        this.imageUpload.cancel();
    }

    public close() {
        this.modal.close();
        this.reset();
    }
}
