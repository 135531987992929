import {Component, OnInit, ViewChild} from '@angular/core';
import {ContentComponent, ContentHost} from "../../../components/content/content.component";
import {Post} from "../../../model/post";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Feed} from "../../../model/feed";
import {ActivatedRoute, Router} from "@angular/router";
import {PostService} from "../../../services/post.service";
import {Observable} from "rxjs";
import {SingleImageUploadComponent} from "../../../commons/single-image-upload/single-image-upload.component";
import {CreateImage} from "../../../model/image";
import {Callback} from "../../../utility/callback";
import {PromoteWizardComponent} from "../../../components/wizards/promote-wizard/promote-wizard.component";
import {AuthService} from "../../../services/auth/auth.service";

@Component({
    selector: 'app-post-details',
    templateUrl: './post-details.component.html',
    styleUrls: ['./post-details.component.scss'],
    host: {'class': 'item-content'}
})
export class PostDetailsComponent extends ContentHost implements OnInit {

    @ViewChild(ContentComponent)
    content: ContentComponent<Post>;

    @ViewChild("coverImage")
    coverImage: SingleImageUploadComponent;

    @ViewChild("promoteWizard")
    promoteWizard: PromoteWizardComponent;

    form: FormGroup;

    feeds: Feed[];

    test = 2;

    readonly uploadImageUrl = "/api/uploads/post-image";

    processImageCallback: Callback<CreateImage, string> = new Callback(this, this.processImage);

    private id: string;

    textModule = { toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'indent': '-1'}, { 'indent': '+1' }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'font': [] }],
            [{ 'align': [] }],
            ['link']
        ]};


    constructor(private fb: FormBuilder, private route: ActivatedRoute,
                private router: Router, public authService: AuthService, private postService: PostService) {
        super();
        this.form = this.fb.group({
            title: ['', Validators.required ],
            subTitle: [''],
            text: [''],
            url: ['', [Validators.pattern("https?://.+")]],
            feed: [null, Validators.required],
            publishDate: [null],
            unpublishDate: [null],
            enabled: ['off'],
            internal: [false]
        })
    }

    ngOnInit() {
        this.route.parent.params.subscribe(params => {
            this.id = params['shortId'];
        });

        this.postService.getFeeds().subscribe(feeds => {
            this.feeds = feeds;
        });
    }

    validate(post: Post) {
        if(this.content.item.coverImageId == null) {
            this.coverImage.valid = false;
            return false;
        }

        if(post.type == null) {
            return true;
        } else if(post.type == "WebLink") {
            if(post.url == null || post.url == "") {
                this.form.get("url").setErrors({'required': true});
                return false;
            }
        }

        return true;
    }

    enabled(value: boolean) {
        return this.postService.enablePost(this.content.item.postId, value)
            .subscribe()
    }

    getPost(): Observable<Post> {
        return this.postService.getPost(this.id);
    }

    setCoverImage(value: any) {
        this.content.setDirty();

        if(value != null) {
            this.content.item.coverImageId = value.imageId;
        } else {
            this.content.item.coverImageId = null;
        }
    }

    processImage(createImage: CreateImage): Observable<string> {
        return this.postService.processCoverImage(this.content.item.postId, createImage);
    }

    updatePost(): Observable<any> {
        return this.postService.updatePost(this.content.item)
    }

    onPostDeleted() {
        this.router.navigate(['../../../'], {relativeTo: this.route});
    }

    deletePost(): Observable<any> {
        return this.postService.deletePost(this.content.item.postId);
    }

    public promote() {
        this.promoteWizard.open("Post", this.content.item.postId);
    }
}
