import {Component, OnInit, ViewChild} from '@angular/core';
import {ContentComponent} from "../../../components/content/content.component";
import {CreatePost, Post} from "../../../model/post";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Observable} from "rxjs";
import {PostService} from "../../../services/post.service";
import {Feed} from "../../../model/feed";
import {ActivatedRoute, Router} from "@angular/router";
import {SingleImageUploadComponent} from "../../../commons/single-image-upload/single-image-upload.component";
import {AuthService} from "../../../services/auth/auth.service";

interface PostType {
    name: string;
    value: string;
}

@Component({
    selector: 'app-create-post',
    templateUrl: './create-post.component.html',
    styleUrls: ['./create-post.component.scss'],
    host: {'class': 'item-content'}
})
export class CreatePostComponent implements OnInit {

    @ViewChild(ContentComponent)
    content: ContentComponent<CreatePost>;

    @ViewChild("coverImage")
    coverImage: SingleImageUploadComponent;

    form: FormGroup;

    readonly uploadImageUrl = "/api/uploads/post-image";

    processImageUrl = "";

    availableTypes: PostType[] = [
        {name: "Article", value: "Article"},
        // {name: "Podcast", value: "Podcast"},
        {name: "Web Link", value: "WebLink"}
        ];

    item = new CreatePost();

    feeds: Feed[];

    constructor(private fb: FormBuilder, private postService: PostService, public router: Router,
                private route: ActivatedRoute, public authService: AuthService) {
        this.form = this.fb.group({
            title: ['', [Validators.required, Validators.minLength(1)]],
            subTitle: [''],
            text: [''],
            url: ['', [Validators.pattern("https?://.+")]],
            feed: [null, Validators.required],
            publishDate: [null],
            enabled: [false],
            internal: [false],
            type: [null, Validators.required]
        })
    }

    ngOnInit() {
        this.postService.getFeeds().subscribe(feeds => {
            this.feeds = feeds;
        });
    }

    validate(post: CreatePost) {
        if(this.coverImage.imageData == null) {
            this.coverImage.valid = false;
        }

        if(post.type == null) {
            return false;
        } else if(post.type == "WebLink") {
            if(post.url == null || post.url == "") {
                this.form.get("url").setErrors({'required': true});
                return false;
            }
        }

        if(this.coverImage.imageData == null) {
            return false;
        }

        return true;
    }

    createPost(item: Post): Observable<any> {
        return this.postService.createPost(this.item);
    }

    onCreated() {
        this.router.navigate(['..', 'list'], {relativeTo: this.route})
    }
}
