import {Component, OnInit, ViewChild} from '@angular/core';
import {AdminService, PagedResult} from "../../services/admin.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ContentListComponent} from "../../components/content-list/content-list.component";
import {ShowService} from "../../services/show.service";
import {Show} from "../../model/show";
import {Observable} from "rxjs/Observable";
import {DeleteDialogComponent} from "../../components/delete-dialog/delete-dialog.component";
import {StationService} from "../../services/station/station.service";

@Component({
  selector: 'app-show-list',
  templateUrl: './show-list.component.html',
  styleUrls: ['./show-list.component.scss']
})
export class ShowListComponent implements OnInit {

    @ViewChild("deleteDialog") deleteDialog: DeleteDialogComponent;

    @ViewChild(ContentListComponent)
    content: ContentListComponent<Show>;

    query: string;

    constructor(private router: Router, private showService: ShowService, public stationService: StationService,
                private route: ActivatedRoute) {
    }

    ngOnInit() {
    }

    public addShow() {
        this.router.navigate(["../create-show"], {relativeTo: this.route});
    }

    public openShow(show: Show) {
        this.router.navigate([ "/radio/shows/edit/" + show.name])
    }

    search(query: string) {
        this.query = query;
        this.content.refresh();
    }

    getShows(pageSize: number, offset: string): Observable<PagedResult<Show>> {
        return this.showService.getShows(pageSize, offset, this.query);
    }

    deleteShow(show: Show) {
        this.deleteDialog.open(show.showId, show.name, () => {
            this.showService.deleteShow(show.showId)
                .subscribe(value => {
                    this.content.removeItems(e => e.showId == show.showId)
                });
        });
    }
}
