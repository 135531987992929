import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lang'
})
export class LangPipe implements PipeTransform {

  transform(value: any, args?: any): any {
      if(value == null)
          return "";

    if(args != null) {
        return value.get(args[0]);
    } else {
        if(Object.keys(value).length > 0) {
            return value[Object.keys(value)[0]];
        } else {
            return ""
        }
    }
  }
}
