import {Component, OnInit, ViewChild} from '@angular/core';
import {ContentComponent} from "../../../components/content/content.component";
import {CampaignStatistics} from "../../../model/campaign";
import {Observable} from "rxjs";
import {UserService} from "../../../services/user.service";
import {UserStatistics} from "../../../model/user";

@Component({
    selector: 'app-user-statistics',
    templateUrl: './user-statistics.component.html',
    styleUrls: ['./user-statistics.component.scss'],
    host: {'class': 'item-content'}
})
export class UserStatisticsComponent implements OnInit {


    userCounters: any;

    @ViewChild(ContentComponent)
    statistics: ContentComponent<UserStatistics>;

    constructor(private userService: UserService) {

    }

    ngOnInit() {
    }

    getStatistics(): Observable<UserStatistics> {
        return this.userService.getUserStatistics()
            .do(stats => this.setCounters(stats));
    }

    setCounters (stats : UserStatistics) {
        this.userCounters = [
            {name: "Total", value: stats.total},
            {name: "Anonymous", value: stats.anonymous},
            {name: "Registered", value: stats.registered},
            {name: "with Facebook", value: stats.withFacebook},
        ];
    }}
