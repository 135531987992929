import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NumericRange} from "../../model/user";

@Component({
  selector: 'app-numeric-range',
  templateUrl: './numeric-range.component.html',
  styleUrls: ['./numeric-range.component.scss']
})
export class NumericRangeComponent implements OnInit {

    rangeValue: NumericRange = new NumericRange(null, null);

    @Input()
    get range() {
        return this.rangeValue;
    }

    @Output()
    rangeChange: EventEmitter<NumericRange> = new EventEmitter();

    set range(range: NumericRange) {
        this.rangeValue = range;
        this.rangeChange.emit(range);
    }

    @Input()
    step: number = 1;

    constructor() { }

    ngOnInit() {
    }

    onMinChanged(value: number) {
        this.rangeValue.from = value;
        this.range = this.rangeValue;
    }

    onMaxChanged(value: number) {
        this.rangeValue.to = value;
        this.range = this.rangeValue;
    }
}
