import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ContentComponent} from "../../../components/content/content.component";
import {ChannelSection} from "../../../model/channel-section";
import {RadioService} from "../../../services/radio.service";
import {Observable} from "rxjs/Observable";
import {SelectChannelComponent} from "../select-channel/select-channel.component";
import {AddSectionComponent} from "../add-section/add-section.component";
import {ChannelService} from "../../../services/channel.service";
import {UUID} from "angular2-uuid";
import {EditSectionComponent} from "../edit-section/edit-section.component";
import {StationService} from "../../../services/station/station.service";

@Component({
    selector: 'app-radio-sections',
    templateUrl: './radio-sections.component.html',
    styleUrls: ['./radio-sections.component.scss'],
    host: {'class': 'item-content'}
})
export class RadioSectionsComponent implements OnInit , AfterViewInit {

    @ViewChild(ContentComponent)
    content: ContentComponent<ChannelSection[]>;

    @ViewChild("addSectionModal") addSectionModal: AddSectionComponent;

    @ViewChild("editSectionModal") editSectionModal: EditSectionComponent;

    @ViewChild("selectChannelModal") selectChannelModal: SelectChannelComponent;

    sortOptions = {
        onUpdate: () => {
            this.content.setDirty();
        }
    };

    constructor(private radioService: RadioService, private channelService: ChannelService,
                public stationService: StationService) {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.addSectionModal.onCreated.subscribe(section => {
            this.content.item.push(section);
            this.content.setDirty();
        });

        this.selectChannelModal.onSelected.subscribe(res => {
            let section = this.content.item.find(value => value.id == this.selectChannelModal.selector);
            if(section != null) {
                if(section.channels.find(value => value.channelId == res.channelId) != null) {
                    this.content.showError("Channel already added");
                } else {
                    section.channels.push(res);
                    this.content.setDirty();
                }
            }
        });
    }

    getSections(): Observable<ChannelSection[]> {
        return this.channelService.getSections()
            .map(value => {
                // HACK
                for(let section of value) {
                    section.id = UUID.UUID();
                }
                return value;
            })
    }

    updateSections(item: ChannelSection[]): Observable<any> {
        let update = [];

        if(item == null)
            return Observable.throw(new Error("No channel sections"));

        for(let i of this.content.item) {
            let channelIds = null;
            if(i.channels != null)
                channelIds = i.channels.map(value => value.channelId);

            update.push({title: i.title, backgroundImageUrl:i.backgroundImageUrl, type: i.type, rows: i.rows, channelIds})
        }

        return this.channelService.updateSections(update);
    }

    addSection() {
        this.addSectionModal.open();
    }

    addChannelForSection(id: string) {
        this.selectChannelModal.selector = id;
        this.selectChannelModal.open();
    }

    removeSection(sectionId: string) {
        this.content.item = this.content.item.filter(value => value.id != sectionId);
        this.content.setDirty();
    }

    sectionUpdated(section: ChannelSection) {
        for(let s of this.content.item) {
            if(s.id == section.id) {
                s.title = section.title;
                s.rows = section.rows;
                s.backgroundImageUrl = section.backgroundImageUrl;
            }
        }
        this.content.setDirty();
    }

    removeChannelForSection(sectionId: string, channelId: string) {
        this.content.setDirty();

        for(let s of this.content.item) {
            if(s.channels == null || s.channels.length < 1)
                continue;

            for(let c of s.channels) {
                if(s.id == sectionId && c.channelId == channelId) {
                    s.channels = s.channels.filter(value => value.channelId != channelId);
                    return;
                }
            }
        }
    }
}
