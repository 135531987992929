import {
    AfterViewInit,
    Component,
    ContentChildren,
    EventEmitter, HostListener, Injectable,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {Observable} from "rxjs/Observable";
import {ClrAlert, ClrLoading, ClrLoadingState} from "@clr/angular";
import {DeleteDialogComponent} from "../delete-dialog/delete-dialog.component";
import {FormGroup} from "@angular/forms";
import {FieldDirective} from "../../directives/field.directive";
import {LocalizedFieldDirective} from "../../directives/localized.directive";
import {LangSelectComponent} from "../../ui/common/lang-select/lang-select.component";

import * as R from 'ramda';
import {BehaviorSubject, Subject} from "rxjs";
import {StationService} from "../../services/station/station.service";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {CanDeactivate} from "@angular/router";
import {SimpleDialogComponent} from "../dialogs/simple-dialog/simple-dialog.component";


export abstract class ContentHost {

    abstract get content(): ContentComponent<any>


    public isDirty() {
        return this.content.isDirty();
    };

    public showNotSavedDialog(subject: Subject<boolean>) {
        this.content.notSavedDialog.open(subject);
    }

    @HostListener('window:beforeunload', ['$event'])
    onUnload(event: any) {
        if(this.isDirty()) {
            event.returnValue = true;
        }
    }
}

@Component({
    selector: 'app-content',
    templateUrl: './content.component.html',
    styleUrls: ['./content.component.scss'],
    animations: [
        trigger('loading', [
            state('true', style({
                opacity: 1,
                display: 'block'
            })),
            state('false', style({
                opacity: 0,
                display: 'none'
            })),
            transition('1 => 0', [
                animate("200ms")
            ]),
            transition("0 => 1", [
                animate("200ms")
            ]),
        ]),
        trigger('footerVisible', [
            state('true', style( {
                opacity: 1,
                display: 'block'
            })),
            state('false', style({
                opacity: 0,
                display: 'none'
            })),
            transition('1 => 0', [
                animate("200ms")
            ]),
            transition("0 => 1", [
                animate("200ms")
            ])
        ])
    ]
})
export class ContentComponent<T> implements OnInit, AfterViewInit {

    @ViewChild("alertWarning")
    alertWarning: ClrAlert;

    warning: string;

    @ViewChild(DeleteDialogComponent)
    deleteDialog: DeleteDialogComponent;

    @ViewChild(LangSelectComponent)
    langSelect: LangSelectComponent;

    @ViewChild("notSavedDialog")
    notSavedDialog: SimpleDialogComponent;

    @Input()
    name: string;

    @Input()
    instance: any;

    @Input()
    title: string;

    @Input()
    hasToolbar = false;

    @Input()
    hasFooter = true;

    @Input()
    mode: 'card' | 'simple' = 'simple';

    @Input()
    toolbarPosition: 'top' | 'bottom' = "bottom";

    @Input()
    deleteEnabled: boolean = false;

    @Input()
    updateEnabled: boolean = false;

    @Input()
    createEnabled: boolean = false;

    @Input()
    refreshEnabled: boolean = false;

    @Input()
    headerEnabled: boolean = true;

    @Input()
    isInline: boolean = false;

    @Input() getItem: () => Observable<T>;

    @Input() updateItem: (item: T) => Observable<any>;

    @Input() deleteItem: () => Observable<any>;

    @Input()
    addItem: () => void;

    @Input() createItem: (item: T) => Observable<any> = null;

    @Input() createButtonEnabled: boolean = true;

    @Input() validateItem: (item: T) => boolean;

    @Input()
    isLocalized: boolean = true;

    @Input()
    hideOnLoad: boolean = true;

    @Input()
    hasLoadingState: boolean = true;

    @ContentChildren(FieldDirective, {descendants: true})
    fields;

    @ContentChildren(LocalizedFieldDirective, {descendants: true})
    localizedFields;

    @Input()
    form: FormGroup;

    @Output() onDeleted = new EventEmitter();

    @Output() onCreated = new EventEmitter();

    @Output() onUpdated = new EventEmitter();

    @Output() onLoaded = new EventEmitter();

    @Output() onChanged = new EventEmitter();

    isLoading: boolean = false;

    errorMessage: string;

    error: Error;

    errorDialogOpen: boolean = false;

    @Input() item: T;

    createButtonState: ClrLoadingState = ClrLoadingState.DEFAULT;

    saveButtonState: ClrLoadingState = ClrLoadingState.DEFAULT;

    deleteButtonState: ClrLoadingState = ClrLoadingState.DEFAULT;

    $langSelected: BehaviorSubject<string>;

    dirty: boolean = false;

    public constructor (private stationService: StationService) {
        this.$langSelected = new BehaviorSubject(this.stationService.getDefaultLocale());
    }

    ngOnInit() {
        if(this.createItem == null) {
            // Only refresh if content is not a creation form
            this.refresh();
        }
    }

    public updateForm() {
        if(this.fields != null) {
            this.fields.forEach(f => {
                f.content = this;
                // Update item only if formControlName is set, otherwise it is an direct mapping
                if(f.formControlName != null)
                    f.item = this.item;
            });
        }

        if(this.localizedFields != null) {
            this.localizedFields.forEach(f => {
                f.content = this;
                // Update item only if formControlName is set, otherwise it is an direct mapping
                if(f.formControlName != null)
                    f.item = this.item;
            });
        }

        if(this.form != null) {
            // TODO: fix!
            this.setupForm(this.form, this.$langSelected.getValue());
        }
    }

    ngAfterViewInit() {
        if(this.createItem != null) {
            // no getter -> creating form
            this.isLoading = false;

            this.onLoaded.emit(this.item);

            this.updateForm();
        }


        this.$langSelected.subscribe(locale => {
            this.setupForm(this.form, locale);
        });

        if(this.langSelect != null) {
            this.langSelect.onChange.subscribe(locale => {
                this.$langSelected.next(locale);
            })
        }
    }

    setItem(item: T) {
        setTimeout(() => {
            this.isLoading = false;
            this.item = item;

            this.updateForm();

            this.onLoaded.emit(this.item);
        });
    }

    public setDirty() {
        this.dirty = true;
        this.onChanged.emit();
    }

    clearItem() {
        this.item = null;
        if(this.form != null)
            this.clearForm(this.form);
    }

    refresh(): void {
        if(this.hasLoadingState)
            this.isLoading = true;

        if(this.getItem == null) {
            this.isLoading = false;
            return;
        }

        this.getItem.apply(this.instance)
            .subscribe(item => {

                this.item = item;
                this.isLoading = false;

                this.onLoaded.emit(item);

                if(this.fields != null) {
                    this.fields.forEach(f => {
                        f.item = item;
                        f.content = this;
                    });
                }

                if(this.localizedFields != null) {
                    this.localizedFields.forEach(f => {
                        f.content = this;
                        if(f.formControlName != null)
                           f.item = item;
                    });
                }

                if(this.form != null) {
                    this.setupForm(this.form, this.$langSelected.getValue());
                }

                this.dirty = false;

            }, (error) => {
                this.isLoading = false;
                this.showError(error.message)
            });
    };

    add() {
        this.addItem.apply(this.instance);
    }

    create() {
        if(!this.validate())
            return;

        this.pullForm();

        this.createButtonState = ClrLoadingState.LOADING;

        this.createItem.apply(this.instance, this.item)
            .subscribe(value => {
                this.createButtonState = ClrLoadingState.SUCCESS;
                this.onCreated.emit(value);
            }, error => {
                this.createButtonState = ClrLoadingState.ERROR;
            }, () => {
                this.createButtonState = ClrLoadingState.SUCCESS;
            });
    }

    update() {
        if(!this.validate())
            return;

        this.pullForm();

        let o = this.updateItem.apply(this.instance, this.item);

        if(o == null)
            return;

        this.saveButtonState = ClrLoadingState.LOADING;


        o.subscribe((item) => {
                this.saveButtonState = ClrLoadingState.SUCCESS;
                this.onUpdated.emit();
                this.dirty = false;
            }, (error) => {
                this.showError("Failed to save: " + error.message);
                this.saveButtonState = ClrLoadingState.ERROR;
            });
    }

    delete() {
        this.deleteDialog.open(null, null, () => {
            this._delete();
        })
    }

    _delete() {
        this.deleteButtonState = ClrLoadingState.LOADING;

        this.deleteItem.apply(this.instance)
            .subscribe((res) => {
                this.onDeleted.emit();
                this.deleteButtonState = ClrLoadingState.SUCCESS;
            }, (error) => {
                this.showError("Failed to delete: " + error.message);
            });
    }

    formValid(formGroup: FormGroup): boolean {
        return !Object.keys(formGroup.controls)
            .map(controlName => formGroup.controls[controlName])
            .filter(control => {
                control.markAsTouched();
                control.updateValueAndValidity();
                return !control.valid;
            }).length;
    }


    validate(): boolean {
        var valid = true;

        if(this.form != null) {
            if(!this.formValid(this.form)) {
                valid = false;
            }
        }

        if(this.validateItem == null)
            return valid;

        return this.validateItem.apply(this.instance, [this.item]) && valid;
    }

    showError(message: string) {
        this.errorMessage = message;
        this.errorDialogOpen = true;
    }

    showWarning(msg: string) {
        this.warning = msg;
        this.alertWarning.open();
    }

    getNestedField(item: any, dir: string): any{
        return R.path(dir.split('.'))(item);
    }

    setupForm(form: FormGroup, locale: string) {
        let values = {};

        if(this.fields != null) {
            this.fields.forEach(f => {
                if (f.item == null) {
                    return;
                }

                f.content = this;
                f.initialized = true;

                if (f.field == null) {
                    console.error("Field is missing");
                } else {
                    if (f.type == 'datetime-local') {
                        var date = this.getNestedField(f.item, f.field);
                        if (date != null) {
                            if (date instanceof Date) {
                                values[f.formControlName] = date.toISOString().slice(0, 16);
                            } else {
                                values[f.formControlName] = date.slice(0, 16);
                            }
                        } else {
                            values[f.formControlName] = null;
                        }
                    } else if (f.type == 'checkbox') {
                        var value = this.getNestedField(f.item, f.field);

                        if (f.inverted)
                            value = !value;

                        if (value) {
                            values[f.formControlName] = true;
                        } else {
                            values[f.formControlName] = false;
                        }
                    } else {
                        values[f.formControlName] = this.getNestedField(f.item, f.field);
                    }
                }
            });
        }
        if(this.localizedFields != null) {

            if (locale) {
                this.localizedFields.forEach(f => {
                    if (f.item == null) {
                        return;
                    }

                    f.selectedLocale = locale;
                    f.valueReady = false;
                    f.initialized = true;

                    if (f.field == null) {
                        console.error("Field is missing");
                    } else {
                        if(f.formControlName != null) {
                            let v = this.getNestedField(f.item, f.field);
                            if (v == null) {
                                v = {};
                            }
                            values[f.formControlName] = v[locale] || "";
                        } else {
                            let v = f.item[f.field];

                            if (v == null) {
                                v = {};
                            }

                            f.el.nativeElement.value = v[this.langSelect.selectedLocale] || "";
                        }
                    }
                });
            }
        }

        if(form != null)
            form.patchValue(values);
    }

    pullForm() {
        this.fields.forEach(f => {
            if (f.field == null) {
                console.error("Field is missing");
            } else {
                if(this.form.contains(f.formControlName)) {
                    if(f.type == 'datetime-local') {
                        var dateString = this.form.get(f.formControlName).value;
                        if(dateString == null || dateString == "") {
                            f.item[f.field] = null;
                        } else {
                            f.item[f.field] = new Date(this.form.get(f.formControlName).value + "Z");
                        }
                    } else if(f.type == 'checkbox') {
                        var value = this.form.get(f.formControlName).value;
                        if (f.inverted) {
                            if(value) {
                                f.item[f.field] = false;
                            } else {
                                f.item[f.field] = true;
                            }
                        } else {
                            if(value) {
                                f.item[f.field] = true;
                            } else {
                                f.item[f.field] = false;
                            }
                        }
                    } else {
                        if(f.formControlName != null) {
                            f.item[f.field] = this.form.get(f.formControlName).value;
                        }
                        // Else is direct mapping and not needed
                    }
                }
            }
        });
    }

    clearForm(form: FormGroup) {
        let values = {};

        this.fields.forEach(f => {
            values[f.formControlName] = null;
        });

        this.localizedFields.forEach(f => {
            values[f.formControlName] = null;
        });

        form.patchValue(values);
    }

    public isDirty() {
        return this.dirty;
    }

    public showNotSavedDialog(subject: Subject<boolean>) {
        this.notSavedDialog.open(subject);
    }
}
