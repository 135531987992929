import {Component, OnInit, ViewChild} from '@angular/core';
import {ContentComponent} from "../../../components/content/content.component";
import {MemberRole} from "../../../model/member";
import {App} from "../../../model/app";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
    selector: 'app-station-app-list',
    templateUrl: './station-app-list.component.html',
    styleUrls: ['./station-app-list.component.scss'],
    host: {'class': 'item-content'}
})
export class StationAppListComponent implements OnInit {

    @ViewChild(ContentComponent)
    content: ContentComponent<App[]>;


    constructor(private route: ActivatedRoute, private router: Router) { }

    ngOnInit() {
        this.content.item = [
            new App(), new App()
        ];
    }

    addApp() {
        this.router.navigate(['../../../add-app'], {relativeTo: this.route});
    }

    openApp(app: App) {
        //this.router.navigate(['../add-app'], {relativeTo: this.route});
    }
}
