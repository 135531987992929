import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanDeactivate} from '@angular/router';
import {Observable, of, Subject} from 'rxjs';
import {ContentComponent, ContentHost} from "./content/content.component";

@Injectable({
    providedIn: "root"
})
export class CanDeactivateGuard implements CanDeactivate<ContentHost> {

    subject: Subject<boolean> = new Subject();

    canDeactivate(host: ContentHost): Observable<boolean> {
        if(host.isDirty()) {
            host.showNotSavedDialog(this.subject);
            return this.subject;
        } else {
            return of(true);
        }
    }
}
