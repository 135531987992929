export class Artist {
    constructor(n) {
        this.name = n;
    }
    name: string;
    country: string;
}

export class Label {
    name: string;
    code: string;
}

export class Release {
    title: string;
    type: string;
    label: Label;
    country: string;
    releaseYear: string;
    barcode: string;
}

export class ExternalId {
}

export class Track {

    constructor () {
        this.metatags = [];
        this.artists = [];
    }

    trackId: string;
    metatags: string[];

    title: string;
    disambiguation: string;

    isrc: string;
    artwork: string;

    artistCredits: string;
    artists: Array<Artist>;

    release: Release;

    validated: boolean;

    externalIds: Array<ExternalId>;

    duration: number;
    audioId: string;
}

export class TrackWarning {
    trackId: string;
    orgaId: string;
    type: string;
    created: Date;
    additionalInfo: any;
}

export class TrackReport {
    reportId: string;
    trackId: string;
    orgaId: string;
    stationId: string;
    userId: string;
    type: string;
    message: string;
    timestamp: number;
    acked: boolean;
    ackedBy: string;
    additionalInfo: any;
}
