import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {ClrModal} from "@clr/angular";
import {concat, Observable, of, Subject} from "rxjs";
import {Channel} from "../../../model/channel";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ChannelService} from "../../../services/channel.service";
import {StationService} from "../../../services/station/station.service";
import {catchError, debounceTime, distinctUntilChanged, switchMap, tap} from "rxjs/operators";
import {Campaign} from "../../../model/campaign";
import {CampaignService} from "../../../services/campaign.service";

@Component({
  selector: 'app-select-campaign',
  templateUrl: './select-campaign.component.html',
  styleUrls: ['./select-campaign.component.scss']
})
export class SelectCampaignComponent implements OnInit {

    @ViewChild("modal")
    modal: ClrModal;

    campaigns$: Observable<Campaign[]>;

    loading: boolean = false;

    campaignInput$ = new Subject<string>();

    selectedCampaign: Campaign;

    selectForm: FormGroup;

    constructor(private fb: FormBuilder, private campaignService: CampaignService, public stationService: StationService) {
        this.selectForm = this.fb.group({
            campaign: [null, Validators.required]
        })
    }

    @Output("onSelected")
    onSelected: EventEmitter<any> = new EventEmitter<any>();

    @Output("onError")
    onError: EventEmitter<any> = new EventEmitter<any>();

    selector: any;

    ngOnInit() {
        this.loadChannels();
    }

    private loadChannels() {
        this.campaigns$ = concat(
            of([]),
            this.campaignInput$.pipe(
                debounceTime(200),
                distinctUntilChanged(),
                tap(() => this.loading = true),
                switchMap(input => this.campaignService.getCampaigns(10, null, input)
                    .map(res => res.items)
                    .pipe(
                        catchError(() => of([])),
                        tap(() => this.loading = false)
                    ))
            )
        );
    }


    open(selector: string) {
        this.selector = selector;
        this.modal.open();
    }

    public close() {
        this.modal.close();
    }

    public finish() {
        this.onSelected.emit({selector: this.selector, campaign: this.selectedCampaign});
        this.close();
    }
}
