import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {UserFilter} from "../../model/user";

@Component({
  selector: 'app-user-search',
  templateUrl: './user-filter.component.html',
  styleUrls: ['./user-filter.component.scss']
})
export class UserFilterComponent implements OnInit {

    form: FormGroup;

    filterValue: UserFilter = new UserFilter();

    @Input()
    get filter() {
        return this.filterValue;
    }

    @Output()
    filterChange: EventEmitter<UserFilter> = new EventEmitter<UserFilter>();

    set filter(filter: UserFilter) {
        this.filterValue = filter;
        this.filterChange.emit(filter);
    }

    constructor(private fb: FormBuilder) {
        this.form = fb.group({

        });
    }

    genderList = [
        { item_id: "Female", item_text: 'Female' },
        { item_id: "Male", item_text: 'Male' },
        { item_id: "Other", item_text: 'Other' },
        { item_id: "Unknown", item_text: 'Unknown' },
    ];

    deviceList = [
        { item_id: "iOS", item_text: 'iOS' },
        { item_id: "Android", item_text: 'Android' },
        { item_id: "Web", item_text: 'Web' },
    ];

    optionsList = [
        { item_id: "FacebookLogin", item_text: 'Facebook Login' },
        { item_id: "EmailAuthorized", item_text: 'Email Authorized' },
        { item_id: "Phone", item_text: 'Phone Number' },
    ];

    dropdownSettings = {};

    ngOnInit() {
        this.dropdownSettings = {
            singleSelection: false,
            idField: 'item_id',
            textField: 'item_text',
            selectAllText: 'Select All',
            unSelectAllText: 'Unselect All',
            itemsShowLimit: 10,
            allowSearchFilter: false
        };
    }
}
