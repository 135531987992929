import {Component, OnInit, ViewChild} from '@angular/core';
import {Observable, of} from "rxjs";
import {PagedResult} from "../../model/misc";
import {Channel, ChannelGroup} from "../../model/channel";
import {StationService} from "../../services/station/station.service";
import {ContentListComponent} from "../../components/content-list/content-list.component";
import {Post} from "../../model/post";
import {ActivatedRoute, Router} from "@angular/router";
import {CreateChannelGroupDialogComponent} from "../create-channel-group-dialog/create-channel-group-dialog.component";
import {ChannelService} from "../../services/channel.service";
import {DeleteDialogComponent} from "../../components/delete-dialog/delete-dialog.component";

@Component({
  selector: 'app-channel-group-list',
  templateUrl: './channel-group-list.component.html',
  styleUrls: ['./channel-group-list.component.scss']
})
export class ChannelGroupListComponent implements OnInit {

    @ViewChild("deleteDialog") deleteDialog: DeleteDialogComponent;

    @ViewChild(ContentListComponent)
    content: ContentListComponent<ChannelGroup>;

    @ViewChild(CreateChannelGroupDialogComponent)
    createDialog: CreateChannelGroupDialogComponent;

    constructor(private router: Router, private route: ActivatedRoute, private stationService: StationService,
                private channelService: ChannelService) { }

    query: string;

    ngOnInit() {
    }

    add() {
        this.createDialog.open();
    }

    open(group: ChannelGroup) {
        this.router.navigate([group.groupId], { relativeTo: this.route })
    }

    delete(group) {
        this.deleteDialog.open(group.groupId, group.name, () => {
            this.channelService.deleteChannel(group.groupId)
                .subscribe(value => {
                    this.content.removeItems(e => e.groupId == group.groupId)
                });
        });
    }

    get(pageSize: number, offset: string): Observable<PagedResult<ChannelGroup>> {
        return this.channelService.getGroups(pageSize, offset, this.query);
    }

    search(query: string) {
        this.query = query;
        this.content.refresh();
    }
}
