import {Component, OnInit, ViewChild} from '@angular/core';
import {DeleteDialogComponent} from "../../../components/delete-dialog/delete-dialog.component";
import {ContentListComponent} from "../../../components/content-list/content-list.component";
import {Campaign} from "../../../model/campaign";
import {CampaignService} from "../../../services/campaign.service";
import {PagedResult} from "../../../services/admin.service";
import {Observable} from "rxjs";
import {StationService} from "../../../services/station/station.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-campaign-list',
  templateUrl: './campaign-list.component.html',
  styleUrls: ['./campaign-list.component.scss']
})
export class CampaignListComponent implements OnInit {

    @ViewChild("deleteDialog") deleteDialog: DeleteDialogComponent;

    @ViewChild(ContentListComponent)
    content: ContentListComponent<Campaign>;

    query: string = null;

    selectedStatus: string = "";

    status = [
        {value: "", name: "All"},
        {value: "enabled", name: "Enabled"},
        {value: "disabled", name: "Disabled"},
    ];

    constructor(private router: Router, private route: ActivatedRoute,
                private campaignService: CampaignService, public stationService: StationService) {
    }

    ngOnInit() {
    }

    public addCampaign() {
        this.router.navigate(["../create-campaign"], {relativeTo: this.route})
    }

    getCampaigns(pageSize: number, offset: string) : Observable<PagedResult<Campaign>> {
        var enabled = null;

        if(this.selectedStatus == "enabled") {
            enabled = true;
        } else if (this.selectedStatus == "disabled") {
            enabled = false
        }

        return this.campaignService.getCampaigns(pageSize, offset, this.query, enabled);
    }

    public search(query: string) {
        this.query = query;
        this.content.refresh();
    }

    public openCampaign(campaign: Campaign) {
        this.router.navigate(["../edit/", campaign.campaignId], {relativeTo: this.route})
    }

    public deleteCampaign(campaign: Campaign) {


        this.deleteDialog.open(campaign.campaignId, null, () => {
            this.campaignService.deleteCampaign(campaign.campaignId)
                .subscribe(value => {
                    this.content.removeItems(p => p.campaignId == campaign.campaignId)
                });
        });
    }

}
