import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {DialogComponent} from "../../components/dialogs/dialog/dialog.component";
import {ChannelService} from "../../services/channel.service";
import {of} from "rxjs";

@Component({
  selector: 'app-create-channel-group-dialog',
  templateUrl: './create-channel-group-dialog.component.html',
  styleUrls: ['./create-channel-group-dialog.component.scss']
})
export class CreateChannelGroupDialogComponent implements OnInit {

    @ViewChild("modal") modal: DialogComponent;

    @Output("onFinish")
    onFinish: EventEmitter<any> = new EventEmitter<any>();

    @Output("onError")
    onError: EventEmitter<any> = new EventEmitter<any>();

    name: string = "";

    constructor(private channelService: ChannelService) {
    }

    ngOnInit() {
    }

    validate() {
        if(!this.name)
            return false;
        else
            return true;
    }

    open() {
        this.name = "";
        this.modal.open();
    }

    create() {
        return of(null);
    }

    failed(error) {
        return this.onError.next(error);
    }

}
