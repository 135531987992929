import {Component, OnInit, ViewChild} from '@angular/core';
import {NgModel} from "@angular/forms";
import {ClrModal} from "@clr/angular";
import {AdminService} from "../../services/admin.service";
import {Subject} from "rxjs/Subject";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-add-station',
  templateUrl: './add-station.component.html',
  styleUrls: ['./add-station.component.scss']
})
export class AddStationComponent implements OnInit {

    @ViewChild("#modal") modal: ClrModal;

    modalOpen: boolean = false;

    name: string;

    displayName: string;

    domainName: string;

    // Input fields

    @ViewChild("nameInput")
    nameInput: NgModel;

    @ViewChild("displayNameInput")
    displayNameInput: NgModel;

    @ViewChild("domainNameInput")
    domainNameInput: NgModel;

    // Observables

    readonly onStationCreated: Subject<any> = new Subject();

    readonly onError: Subject<any> = new Subject();

    constructor(private adminService: AdminService) { }

    ngOnInit() {
    }

    add() {
        this.adminService.addStation(this.name, this.displayName, this.domainName)
            .subscribe((member) => {
                this.onStationCreated.next(member);
                this.close();
            },(error: HttpErrorResponse) => {
                if(error.status == 409) {
                    if(error.error.errorCode == 'stationNameExists') {
                        this.nameInput.control.setErrors({nameExists: true});
                    }
                }

                this.onError.next(error);
            });
    }

    close () {
        this.modalOpen = false;
        this.reset();
    }

    reset() {
        this.name = "";
        this.displayName = "";

        this.nameInput.reset();
        this.displayNameInput.reset();
    }
}
