import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ClrModal} from "@clr/angular";
import {ImageUploadComponent} from "../../../commons/image-upload/image-upload.component";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {StationService} from "../../../services/station/station.service";

export interface LanguageItem {
    locale: string;
    name: string;
}

@Component({
  selector: 'app-add-language',
  templateUrl: './add-language.component.html',
  styleUrls: ['./add-language.component.scss']
})
export class AddLanguageComponent implements OnInit {


    @ViewChild("modal") modal: ClrModal;

    @ViewChild(ImageUploadComponent)
    imageUpload: ImageUploadComponent;

    modalOpen: boolean;

    form: FormGroup;

    @Output("onFinish")
    onFinish: EventEmitter<string> = new EventEmitter<string>();

    constructor(private fb: FormBuilder, public stationService: StationService) {
        this.form = fb.group({
            "locale": [null, [Validators.required]]
        });
    }

    ngOnInit() {
    }

    open() {
        this.modal.open();
    }


    public finish() {
        this.onFinish.emit(this.form.get("locale").value);
        this.reset();
        this.close();
    }

    public reset() {
        this.form.reset();
    }

    public close() {
        this.modal.close();
        this.reset();
    }

}
