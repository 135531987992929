import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {User, UserStatistics} from "../model/user";
import {Observable} from "rxjs/Observable";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class UserService {

    constructor(private http: HttpClient) {
    }

    getUser(id: string): Observable<User> {
        return this.http.get<User>(environment.apiEndpoint +"/api/users/id=" + id);
    }

    getUserStatistics(): Observable<UserStatistics> {
        return this.http.get<UserStatistics>(environment.apiEndpoint +"/api/users/statistics");
    }

    setPassword(id: string, password: string): Observable<any> {
        return this.http.put(environment.apiEndpoint + "/api/users/id=" + id + "/password", {
            password: password
        });
    }

    updateUser(userId: string, item: any) {
        return this.http.put(environment.apiEndpoint + "/api/users/id=" + userId, {
            internal: item.internal
        });
    }

    public enableUser(id: string, value: boolean): Observable<any> {
        return this.http.put(environment.apiEndpoint +"/api/users/id=" + id, {disabled: !value});
    }

    public deleteUser(id: string) {
        return this.http.delete(environment.apiEndpoint + "/api/users/id=" + id)
    }

    public invalidateUserEmail(id: string) {
        return this.http.post(environment.apiEndpoint + "/api/users/id=" + id + "/invalidate-email", {})
    }
    // Messaging

    public sendDebugMessage(userId: string, title: string, message: string, sandbox: boolean) {
        return this.http.post("/api/users/id=" + userId + "/sendDebugMessage", {
            title: title, message: message, sandbox: sandbox
        });
    }
}
