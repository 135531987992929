import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ContentItemsComponent} from "../../components/content-items/content-items.component";
import {ImageDto} from "../../model/image";
import {AddImageComponent, AddImageResult} from "../../ui/common/add-image/add-image.component";

class ImageEntry {
    file: string;
    data: any;
    image: ImageDto;


    constructor(file: string, data: any, image: ImageDto) {
        this.file = file;
        this.data = data;
        this.image = image;
    }
}

@Component({
  selector: 'app-multiple-image-upload',
  templateUrl: './multiple-image-upload.component.html',
  styleUrls: ['./multiple-image-upload.component.scss']
})
export class MultipleImageUploadComponent implements OnInit {

    constructor() { }

    @ViewChild("addImageModal")
    addImageModal: AddImageComponent;

    @ViewChild("imageInput")
    fileInput: ElementRef;

    @ViewChild("imageItems")
    imageItems: ContentItemsComponent<ImageEntry>;

    imagesValue = [];

    @Input("images")
    get images (): AddImageResult[] {
        return this.imagesValue;
    }

    set images(val) {
        this.imagesValue = val;
    }

    @Output()
    imagesChange = new EventEmitter();

    @Input("processUrl")
    processUrl: string;

    imageDataDict = {};

    sortOptions = {

    }

    // Size contraints

    @Input("maxWidth") allowedWidth: number = 0;

    @Input("maxHeight") allowedHeight: number = 0;

    @Input("minHeight") minHeight: number = 0;

    @Input("minWidth") minWidth; number = 0;

    // Upload & process urls
    @Input("uploadUrl") uploadUrl: string;

    // Output events
    @Output("uploadFailed")
    uploadFailed: EventEmitter<string> = new EventEmitter<string>();

    @Output("uploadSucceeded")
    uploadSucceeded: EventEmitter<any> = new EventEmitter<any>();


    ngOnInit() {
        this.imageItems.onRemoved.subscribe(item => {
            this.imagesValue = this.images.filter(value => value != item);
        });

        this.imageItems.onChanged.subscribe(item => {
            this.imagesChange.emit(this.imagesValue);
        })

        this.addImageModal.onFinish.subscribe(value => {
            this.imageDataDict[value.uploadUrl] = value.imageData;
            delete value.imageData;
            this.imagesValue.push(value);
        });
    }

    addImage() {
        this.addImageModal.open();

        //this.fileInput.nativeElement.click();
    }
}
