import {Observable} from "rxjs";

export class Callback <A, B> {

    fn: (t: A) => Observable<B>;

    instance: any;

    constructor(instance: any, fn: (t: A) => Observable<B>) {
        this.instance = instance;
        this.fn = fn;
    }
}
