import {Component, OnInit, ViewChild} from '@angular/core';
import {Observable} from "rxjs";
import {CampaignResult} from "../../../model/campaign";
import {of} from "rxjs/internal/observable/of";
import {ContentComponent} from "../../../components/content/content.component";
import {ActivatedRoute, Router} from "@angular/router";
import {CampaignService} from "../../../services/campaign.service";

@Component({
    selector: 'app-campaign-submission-details',
    templateUrl: './campaign-submission-details.component.html',
    styleUrls: ['./campaign-submission-details.component.scss'],
    host: {'class': 'item-content'}
})
export class CampaignSubmissionDetailsComponent implements OnInit {

    @ViewChild(ContentComponent)
    content: ContentComponent<CampaignResult>;

    campaignId: string;

    userId: string;

    constructor(private route: ActivatedRoute, private router: Router,
                private campaignService: CampaignService) { }

    ngOnInit() {
        this.route.parent.params.subscribe(params => {
            this.campaignId = params['id'];
        });

        this.route.params.subscribe(params => {
            this.userId = params['userId'];
        });
    }

    getSubmission (): Observable<CampaignResult> {
        return this.campaignService.getResult(this.campaignId, this.userId);
    }

    deleteSubmission (): Observable<any> {
        return of(null);
    }

    onSubmissionDeleted() {
        // TODO: implement
    }
}
