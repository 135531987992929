import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {ClrModal} from "@clr/angular";

@Component({
  selector: 'app-add-slide',
  templateUrl: './add-slide.component.html',
  styleUrls: ['./add-slide.component.scss']
})
export class AddSlideComponent implements OnInit {

    @ViewChild(ClrModal)
    modal: ClrModal;

    @Output()
    onFinish = new EventEmitter<string>();

    slideType: string;

    constructor() { }

    ngOnInit() {
    }

    open() {
        this.slideType = null;
        this.modal.open();
    }

    finish() {
        if(this.slideType == null) {
            return;
        }
        this.onFinish.emit(this.slideType);
        this.modal.close();
    }
}
