import {CreateImage} from "./image";

export class CreateStation {
    name: string;
    displayName: string;
    domainName: string;
    timeZone: string;
    coverImage: CreateImage;
    enabled: boolean;
    locales: StationLocale[] = [];
    defaultLocale: string;
}

export class Station {
    stationId: string;
    name: string;
    displayName: string;
    domainName: string;
    timeZone: string;
    description: string;
    coverImageId: string;
    enabled: boolean;
    locales: StationLocale[];
    defaultLocale: string;
}

export class StationTOS {
    version: string;
    text: Map<String, String>
}

export class StationDocument {
    contents: Map<string, string>;
}

export class StationEmailSettings {
    fromName: string;
    fromAddress: string;
}

export class StationEmailTemplate {
    subject: {};
    plain: {};
    html: {};
}

export class StationLocale {
    locale: string;
    disabled: boolean
}

export class StationApi {
    apiId: string;
    autoCertificate: boolean;
    domain: string;
    created: string;
    certificateValidUntil: string;
}
