import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ClrModal} from "@clr/angular";

interface PostType {
    name: string;
    value: string;
}
@Component({
  selector: 'app-add-post',
  templateUrl: './add-post.component.html',
  styleUrls: ['./add-post.component.scss']
})
export class AddPostComponent implements OnInit {

    @ViewChild(ClrModal)
    modal: ClrModal;

    @Output()
    onFinish = new EventEmitter<string>();

    availableTypes: PostType[] = [
        {name: "Article", value: "article"},
        {name: "Web Link", value: "web-link"},
    ];

    postType: string;

    constructor() { }

    ngOnInit() {
    }

    open() {
        this.postType = null;
        this.modal.open();
    }

    finish() {
        if(this.postType == null) {
            return;
        }
        this.onFinish.emit(this.postType);
        this.modal.close();
    }
}
