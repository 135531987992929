import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {ClrModal, ClrWizard} from "@clr/angular";
import {FormGroup} from "@angular/forms";
import {UserFilter} from "../../../model/user";
import {CampaignResultFilter, MultiChoiceSlide, Slide} from "../../../model/campaign";
import {MultiChoiceEditorComponent} from "../slide-editors/multi-choice-editor/multi-choice-editor.component";
import {StationService} from "../../../services/station/station.service";

interface SlideEntry {

}

@Component({
  selector: 'app-filter-campaign-submission-dialog',
  templateUrl: './filter-campaign-submission-dialog.component.html',
  styleUrls: ['./filter-campaign-submission-dialog.component.scss']
})
export class FilterCampaignSubmissionDialogComponent implements OnInit {

    @ViewChild("wizard") wizard: ClrWizard;

    form: FormGroup;

    _userFilter = new UserFilter();

    slides = [];

    random: boolean;

    filter: CampaignResultFilter = new CampaignResultFilter();

    @Output("onFinish")
    onFinish: EventEmitter<CampaignResultFilter> = new EventEmitter<CampaignResultFilter>();

    constructor(private stationService: StationService) { }

    ngOnInit() {
    }

    public open(slides: Slide[]) {
        // this.slides = slides.filter(slide => {
        //     if(slide.skippable)
        //         return true;
        //
        //     if(slide.type == 'MultiChoice')
        //         return true;
        //
        //     return false;
        // }).map(slide => {
        //     if(slide.type == 'MultiChoice') {
        //         var i = 0;
        //         (<any>slide).choices = (<MultiChoiceSlide>slide).choices.map(c => {
        //             let res = {item_id: String(i), item_text: c.text[this.stationService.getDefaultLocale()]};
        //             i++;
        //             return res;
        //         });
        //
        //         (<any>slide).dropdownSettings = {
        //             singleSelection: !(<MultiChoiceSlide>slide).multi,
        //             idField: 'item_id',
        //             textField: 'item_text',
        //             selectAllText: 'Select All',
        //             unSelectAllText: 'Unselect All',
        //             itemsShowLimit: 10,
        //             allowSearchFilter: false
        //         };
        //
        //         return slide;
        //     } else {
        //         return slide;
        //     }
        // });

        this.wizard.open();
    }

    public close() {
        this.wizard.reset();
        this.wizard.close();
    }

    public clear() {
        this.close();
    }

    public finish() {
        this.onFinish.emit(this.filter);
        this.close();
    }
}
