import {Component, OnInit, ViewChild} from '@angular/core';
import {AdminService} from "../services/admin.service";
import {Router} from "@angular/router";
import {User, UserFilter} from "../model/user";
import {ContentListComponent} from "../components/content-list/content-list.component";
import {AddUserComponent} from "../ui/social/add-user/add-user.component";
import {DeleteDialogComponent} from "../components/delete-dialog/delete-dialog.component";
import {UserFilterDialogComponent} from "../ui/social/user-filter-dialog/user-filter-dialog.component";

@Component({
    selector: 'app-user-list',
    templateUrl: './user-list.component.html',
    styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {

    @ViewChild("deleteDialog") deleteDialog: DeleteDialogComponent;

    @ViewChild("addUserModal") addUserModal: AddUserComponent;

    @ViewChild("userFilterModal") userFilterModal: UserFilterDialogComponent;

    query: string;

    filter: UserFilter = new UserFilter();


    includeDisabled: boolean = false;

    @ViewChild(ContentListComponent)
    content: ContentListComponent<User>;

    constructor(private router: Router, private adminService: AdminService) {
    }

    ngOnInit() {
        this.addUserModal.onCreated.subscribe(value => {
            this.content.refresh();
        });

        this.addUserModal.onError.subscribe(value => {
            this.content.refresh();
        });
    }

    search(query: string) {
        this.query = query;
        this.content.refresh();
    }

    getUsers(limit: number, offset: string) {
        var opts = {};

        if(this.query && this.query.length > 1)
            opts['query'] = this.query;

        if(this.filter) {
            if(this.filter.genders.length > 0) {
                opts['genders'] = this.filter.genders;
            }

            if(this.filter.devices.length > 0) {
                opts['devices'] = this.filter.devices;
            }

            if(this.filter.options.length > 0) {
                opts['options'] = this.filter.options;
            }

            if (this.filter.ageRange) {
                if(this.filter.ageRange.from != null || this.filter.ageRange.to != null)
                    opts['age'] = this.filter.ageRange;
            }
        }

        return this.adminService.searchUsers(limit, offset, opts);
    }

    openUser(user) {
        this.router.navigate([ "/social/users/edit/" + user.userId])
    }

    addUser() {
        this.addUserModal.modalOpen = true;
    }

    openFilter() {
        this.userFilterModal.open(this.filter);
    }

    deleteUser(user: User) {
        this.deleteDialog.open(user.userId, user.userId, () => {
            this.adminService.deleteUser(user.userId)
                .subscribe(value => {
                    this.content.removeItems(e => e.userId == user.userId)
                });
        });
    }

    onUserFilter(filter: UserFilter) {
        console.log(this.filter);
        this.filter = filter;
        this.content.refresh();
    }
}
