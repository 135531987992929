import {Component, Input, OnInit} from '@angular/core';

interface NumberCardItem {
    name: string,
    value: number
}

@Component({
  selector: 'app-number-card',
  templateUrl: './number-card.component.html',
  styleUrls: ['./number-card.component.scss']
})
export class NumberCardComponent implements OnInit {

    @Input()
    data: NumberCardItem[];

    constructor() { }

    ngOnInit() {
    }
}
