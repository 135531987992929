import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ClrModal} from "@clr/angular";
import {DialogComponent} from "../dialog/dialog.component";

@Component({
  selector: 'app-show-text-dialog',
  templateUrl: './show-text-dialog.component.html',
  styleUrls: ['./show-text-dialog.component.scss']
})
export class ShowTextDialogComponent implements OnInit {

    @ViewChild(DialogComponent)
    dialog: DialogComponent;


    slideId: string;

    title:string;

    text: string;

    constructor() { }

    ngOnInit() {
    }

    open(text: string, slideId: string) {
        this.text = text;
        this.slideId = slideId;
        this.dialog.open();
    }
}
