import {Component, OnInit, ViewChild} from '@angular/core';
import {AdminService} from "../../../services/admin.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../../services/auth/auth.service";
import {ContentComponent, ContentHost} from "../../../components/content/content.component";
import {Orga} from "../../../model/orga";
import {Observable} from "rxjs/Observable";

@Component({
    selector: 'app-orga-overview',
    templateUrl: './orga-details.component.html',
    styleUrls: ['./orga-details.component.scss'],
    host: {'class': 'item-content'}
})
export class OrgaDetailsComponent extends ContentHost implements OnInit {

    @ViewChild(ContentComponent)
    content: ContentComponent<Orga>;

    id: string;

    form: FormGroup;

    imageUploadUrl = "/api/uploads/orga-cover";

    imageProcessUrl: string;

    constructor(private fb: FormBuilder, private authService: AuthService,
                private adminService: AdminService) {
        super();

        this.form = this.fb.group({
            name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(32)]],
            displayName: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(32)]],
            email: ['', [Validators.email]]
        });
    }

    ngOnInit() {
        this.id = this.authService.getActiveOrga();
    }

    getOrga(): Observable<Orga> {
        return this.adminService.getOrga(this.id)
            .do((orga) => {
                this.imageProcessUrl = "/api/orgas/" + orga.id + "/coverImage";
            });
    }

    updateOrga(): Observable<any> {
        return this.adminService.updateOrga(this.content.item);
    }
}
